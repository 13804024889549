import React, { Component } from 'react';
import { Menu, Button, Icon, Segment, Select, Header } from 'semantic-ui-react';
import i18next from 'i18next';
import { DatesRangeInput } from 'semantic-ui-calendar-react';

const reportsType = [
  {
    key: 'Rentals By Vehide',
    text: 'Rentals By Vehide',
    value: 'Rentals By Vehide',
  },
  {
    key: 'Rentals By Payment Types',
    text: 'Rentals By Payment Types',
    value: 'Rentals By Payment Types',
  },
  {
    key: 'Rentals By Agent',
    text: 'Rentals By Agent',
    value: 'Rentals By Agent',
  },
  {
    key: 'Payments By Date',
    text: 'Payments By Date',
    value: 'Payments By Date',
  },
  {
    key: 'Payments By Types',
    text: 'Payments By Types',
    value: 'Payments By Types',
  },
];

const reportsDate = [
  { key: 'Inovice date', text: 'Inovice date', value: 'Inovice date' },
  {
    key: 'Agreements start date',
    text: 'Agreements start date',
    value: 'Agreements start date',
  },
  {
    key: 'Agreements end date',
    text: 'Agreements end date',
    value: 'Agreements end date',
  },
];

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Menu className="tool-bar" stackable>
          <Menu.Item className="section-name" header>
            {i18next.t('Reports')}
          </Menu.Item>
          <Menu.Item>
            <Select placeholder="Select report type" options={reportsType} />
          </Menu.Item>
          <Menu.Item>
            <Select placeholder="Select date by" options={reportsDate} />
          </Menu.Item>
          <Menu.Item>
            <DatesRangeInput
              value=""
              onChange={console.log}
              animation=""
              clearable
              clearIcon={<Icon name="remove" color="red" />}
              autoComplete="off"
              closable
              hideMobileKeyboard
              popupPosition="bottom right"
              placeholder={i18next.t('Date From/To')}
            />
          </Menu.Item>
          <Menu.Item>
            <Button icon primary labelPosition="left">
              <Icon name="wordpress forms" />
              {i18next.t('Generate report')}
            </Button>
          </Menu.Item>
        </Menu>
        <Segment>
          <Header>Report data</Header>
        </Segment>
      </React.Fragment>
    );
  }
}

export default Reports;
