import { ApiService } from './api.service';

const agreementsApi = {
  getSigningAgreement: (uid) =>
    ApiService.get(`/agreements/getSigningAgreement?uid=${uid}`).toPromise(),
  signAgreementAction: (uid, action) =>
    ApiService.get(
      `/agreements/signAction?url_hash=${uid}&action=${action}`
    ).toPromise(),
};

export default agreementsApi;
