import i18next from 'i18next';
import {
  HIDE_STATUS_MESSAGE,
  LOCAL_DATA_LOADER,
  SHOW_STATUS_MESSAGE,
} from './types';
import { statusMessages } from '../common/catalogs/constants';

export const SHOW_NEW_AGREEMENT_MODAL_SHOW = 'SHOW_NEW_AGREEMENT_MODAL_SHOW';
export const SET_DATA_LOADING = 'SET_DATA_LOADING';

export function setNewAgreementModalShow(isNewAgreementModalShow) {
  return {
    type: SHOW_NEW_AGREEMENT_MODAL_SHOW,
    payload: isNewAgreementModalShow,
  };
}

export const setDataLoading = (isDataLoading) => async (dispatch) => {
  dispatch({
    type: SET_DATA_LOADING,
    payload: isDataLoading,
  });
};

export const localDataLoader =
  (isDataLoading = true) =>
  async (dispatch) => {
    dispatch({
      type: LOCAL_DATA_LOADER,
      payload: isDataLoading,
    });
  };

export const showStatusMessage = (type, text) => async (dispatch) => {
  const messageObject = {
    messageType: type,
    statusMessageText: text,
    isMessageVisible: true,
  };
  dispatch({
    type: SHOW_STATUS_MESSAGE,
    payload: messageObject,
  });
};

export const hideStatusMessage = () => async (dispatch) => {
  dispatch({
    type: HIDE_STATUS_MESSAGE,
  });
};
