import Compress from 'compress.js';
import heic2any from 'heic2any';

const isHeic = (image) => image?.type === 'image/heic';

export async function resizeImage(
  imageFile,
  maxWidth = 1000,
  maxHeight = 1000
) {
  try {
    let image = imageFile;
    if (isHeic(imageFile)) {
      const blob = new Blob([image]);
      const converted = await heic2any({
        blob,
        toType: 'image/jpeg',
        quality: 0.8,
      });
      if (converted) image = converted;
    }

    const compress = new Compress();
    const resizedImage = await compress.compress([image], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth, // the max width of the output image, defaults to 1920px
      maxHeight, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    return Compress.convertBase64ToFile(base64str, imgExt);
  } catch (e) {
    return imageFile;
  }
}

export function sortExpenses(expenses) {
  const sortObject = { ...expenses };
  const newExpenses = [];
  const updateExpenses = [];
  try {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const expenses in sortObject) {
      // eslint-disable-next-line no-prototype-builtins
      if (sortObject[expenses].hasOwnProperty('newItem'))
        newExpenses.push(sortObject[expenses]);
      // eslint-disable-next-line no-prototype-builtins
      if (sortObject[expenses].hasOwnProperty('updateItem'))
        updateExpenses.push(sortObject[expenses]);
    }
  } catch (e) {
    console.log(e);
  }

  return { newExpenses, updateExpenses };
}

// eslint-disable-next-line consistent-return
export async function convertGalleryImages(images) {
  const imagesConvertedFiles = [];
  try {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < images.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      imagesConvertedFiles.push(await resizeImage(images[i].file));
    }
    return imagesConvertedFiles;
  } catch (e) {
    console.log(e);
  }
}

export async function getBase64Image(url) {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
}

export function ImageDimension(image) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.src = image;
      img.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
      img.onerror = () => reject();
    } catch (e) {
      console.log(e);
      reject();
    }
  });
}

export function getUrlParamsFromArray(filterProps) {
  const parameters = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const property in filterProps) {
    // eslint-disable-next-line no-prototype-builtins
    if (filterProps.hasOwnProperty(property)) {
      parameters.push(encodeURI(`${property}=${filterProps[property]}`));
    }
  }
  return parameters;
}

export function redirectToMain() {
  document.location.href = '/';
}
