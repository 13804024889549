const AppConfig = {
  agreement_start_number: 200000,
  apiUrl:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000/api'
      : 'http://carrent.rs-cw.ru:5000/api',
  serverRoot:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000/'
      : 'http://carrent.rs-cw.ru:5000/',
  minRateTime: 60, // min time in minutes
  agreementSignTimeout: 300,
  serverActionDelay: 2000,
  redirectTimeOut: 5000,

  authRedirectRoute: '/',

  // defaults
  defaultClientImage: 'assets/img/ui/image.png',
  defaultLogo: 'assets/img/ui/default_logo.png',

  navigation: {
    testBtn: false,
  },

  // Vehicle
  maxVehicleImageToUpload: 5,
  maxVehicleImageToUploadSize: 1024 * 1024 * 5,

  // Settings
  carsLibPageCount: 10,
  currency: 'USD',

  validation: {
    newVehicle: ['make', 'model'],
    newClient: ['first_name', 'family_name'],
  },
  searchVehicleFilter: ['Model', 'Color', ['From', 'To']],
  newAgreementForm: {
    5: [
      'pick_location',
      'pick_instruction',
      'drop_location',
      'drop_instruction',
    ],
  },
};
export default AppConfig;
