import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import ReactCrop from 'react-image-crop';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import 'react-image-crop/dist/ReactCrop.css';

const pixelRatio = window.devicePixelRatio || 1;
const initState = { unit: '%', width: 30, aspect: 16 / 14 };

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext('2d');
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

function CropImageModal(props) {
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState(initState);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  useEffect(() => {
    if (props.isCropModalShow) {
      onLoadFile(props.image);
      setOpen(true);
    } else {
      setCrop(initState);
      setOpen(false);
    }
  }, [props.isCropModalShow]);

  const onLoadFile = (files) => {
    if (files) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        setUpImg(fileReader.result);
      });
      fileReader.readAsDataURL(files);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (
      !completedCrop ||
      !previewCanvasRef.current ||
      !imgRef.current ||
      !open
    ) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const generateCropImage = (previewCanvas, crop) => {
    if (!crop || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

    canvas.toBlob(
      (blob) => {
        // const previewUrl = window.URL.createObjectURL(blob);
        // const anchor = document.createElement("a");
        // anchor.download = "cropPreview.png";
        // anchor.href = URL.createObjectURL(blob);
        // anchor.click();
        //
        // window.URL.revokeObjectURL(previewUrl);
        props.onCropped(blob);
      },
      'image/jpeg',
      1
    );
  };

  return (
    <Modal
      size="tiny"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Select a Photo</Modal.Header>
      <Modal.Content>
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
        <canvas
          ref={previewCanvasRef}
          style={{
            width: 0,
            height: 0,
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={props.onCloseModal}>
          {i18next.t('Cancel')}
        </Button>
        <Button
          content={i18next.t('Upload')}
          labelPosition="right"
          icon="checkmark"
          onClick={() =>
            generateCropImage(previewCanvasRef.current, completedCrop)
          }
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

CropImageModal.defaultProps = {
  isCropModalShow: false,
};

CropImageModal.propTypes = {
  isCropModalShow: PropTypes.bool,
  onCropped: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default CropImageModal;
