import Api from '../classes/Api';

export const getAvailableCarsList = (data) => {
  const api = new Api();
  return api.post('agreements/availablecarslist', data);
};

export const getDriverData = (id) => {
  const api = new Api();
  return api.getById('agreements/getdriverdata', id);
};

export const loadVehicleData = (id) => {
  const api = new Api();
  return api.getById('vehicles/getvehicle', id);
};

export const addAddagreement = (data) => {
  const api = new Api();
  return api.post('agreements/addagreement', data);
};

export const deleteClient = (id) => {
  const api = new Api();
  return api.post('clients/deleteclient', { id });
};

export const updateClient = (data) => {
  const api = new Api();
  return api.post('clients/updateclient', data);
};

export const addClient = (data) => {
  const api = new Api();
  return api.post('clients/addclient', data);
};

export const uploadImage = (data) => {
  const api = new Api();
  return api.post('vehicles/uploadimage', data);
};

export const getImageUrl = (id) => {
  const api = new Api();
  return api.getById('vehicles/getimageurl', id);
};

export const deleteImage = (id) => {
  const api = new Api();
  return api.post('vehicles/deleteimage', { id });
};

export const getAgreementList = (data) => {
  const api = new Api();
  return api.post('agreements/getagreementslist', data);
};
