import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

function SearchField(props) {
  const [isSearchLoading, setSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchLoading(false);
  }, [props.searchResults]);

  const onSearchKeyDown = (e) => {
    if (e.key === 'Enter') props.handleOnSearch(searchValue);
    else if (searchValue && e.key === 'Escape') clearSearchResults();
  };

  const handleOnSearchChange = (e, { value }) => {
    if (value) setSearchValue(value);
    else clearSearchResults();
  };

  const clearSearchResults = () => {
    props.onClearSearch();
    setSearchValue('');
  };

  const onSearch = () => {
    setSearchLoading(true);
    props.handleOnSearch(searchValue);
  };

  const getSearchActionButton = () => {
    const { searchResults } = props;
    console.log(searchResults.length);
    if (searchResults.length > 0)
      return {
        icon: 'close',
        onClick: clearSearchResults,
        disabled: isSearchLoading,
      };
    return { icon: 'search', onClick: onSearch, disabled: isSearchLoading };
  };

  return (
    <Input
      disabled={isSearchLoading}
      className="search-input"
      loading={isSearchLoading}
      action={getSearchActionButton()}
      placeholder={i18next.t(props.placeholder)}
      onChange={handleOnSearchChange}
      onKeyDown={onSearchKeyDown}
      value={searchValue}
    />
  );
}

SearchField.defaultProps = {
  searchResults: [],
  placeholder: 'Search vehicles...',
};

SearchField.propTypes = {
  placeholder: PropTypes.string,
  handleOnSearch: PropTypes.func,
  onClearSearch: PropTypes.func,
  searchResults: PropTypes.array,
};

export default SearchField;
