import axios from 'axios';
import _ from 'lodash';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import {
  AGREEMENT_FORM_STEP,
  AGREEMENT_APPLY_SUCCESS,
  AGREEMENT_SIGN_SENT_SUCCESS,
  HIDE_AGREEMENT_MODAL_SHOW,
  LOAD_DRIVER_INFO,
  LOAD_DRIVER_LIST,
  LOAD_FREE_VEHICLES_LIST,
  NEW_AGREEMENT_MODAL_SHOW,
  RESET_FREE_VEHICLES_LIST,
  SHOW_STATUS_MESSAGE,
  AGREEMENT_NEED_UPDATE,
} from './types';
import AppConfig from '../config';
import { tokenConfig } from './user/auth';
import { Driver } from '../common/models/Driver';
import { localDataLoader, setDataLoading } from './UiActions';
import { AddDriver } from '../common/models/AddDriver';
import {
  depositMethod,
  errorMessageStatus,
  firstDriver,
  statusMessages,
  successMessageStatus,
} from '../common/catalogs/constants';
import { Vehicle } from '../common/models/Vehicle';
import { generateAgreement } from '../services/generatePDF';
import { getUrlParamsFromArray } from '../services/utils';

export const showNewAgreementForm = (isShow) => async (dispatch, getState) => {
  dispatch({
    type: NEW_AGREEMENT_MODAL_SHOW,
    payload: { isShow, agreementStep: 0 },
  });
};

export const hideAgreementForm = () => async (dispatch, getState) => {
  dispatch({
    type: HIDE_AGREEMENT_MODAL_SHOW,
    payload: { isShow: false, agreementStep: 0 },
  });
};

export const agreementFormSetStep =
  (agreementStep) => async (dispatch, getState) => {
    dispatch({
      type: AGREEMENT_FORM_STEP,
      payload: { agreementStep },
    });
  };

export const searchFreeVehicles =
  (filterProps) => async (dispatch, getState) => {
    try {
      const urlParams = getUrlParamsFromArray(filterProps)
        .join('&')
        .replaceAll(',', '_');

      const res = await axios.get(
        `${AppConfig.apiUrl}/agreements/availablecarslist?${urlParams}`,
        tokenConfig(getState)
      );
      dispatch({
        type: LOAD_FREE_VEHICLES_LIST,
        payload: res.data.map((item) => new Vehicle(item)),
      });
    } catch (err) {
      console.log(err);
    }
  };

export const resetSearchState = () => async (dispatch, getState) => {
  dispatch({
    type: RESET_FREE_VEHICLES_LIST,
  });
};

export const loadDriversList = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${AppConfig.apiUrl}/agreements/clientslist`,
      tokenConfig(getState)
    );
    dispatch({
      type: LOAD_DRIVER_LIST,
      payload: res.data.map((item) => new Driver(item)),
    });
  } catch (err) {
    console.log(err);
  }
};

export const loadDriverInfo =
  ({ driverType, driverId }) =>
  async (dispatch, getState) => {
    try {
      dispatch(localDataLoader());
      const res = await axios.get(
        `${AppConfig.apiUrl}/agreements/driverinfo/${driverId}?driverType=${driverType}`,
        tokenConfig(getState)
      );
      dispatch({
        type: LOAD_DRIVER_INFO,
        payload: {
          driverType,
          driverInfo:
            driverType === firstDriver
              ? new Driver(res.data)
              : new AddDriver(res.data),
        },
      });
      dispatch(localDataLoader(false));
    } catch (err) {
      dispatch(localDataLoader(false));
      console.log(err);
    }
  };

export const getWAInfo = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${AppConfig.apiUrl}/agreements/getWA`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const applyAgreement = (agreementData) => async (dispatch, getState) => {
  try {
    const agreementDataObject = _.cloneDeep(agreementData);
    dispatch(localDataLoader());
    const res = await axios.put(
      `${AppConfig.apiUrl}/agreements/addagreement`,
      agreementDataObject,
      tokenConfig(getState)
    );
    dispatch({
      type: AGREEMENT_APPLY_SUCCESS,
      payload: res.data,
    });
    dispatch(localDataLoader(false));
    toast.success(i18next.t('Agreement successfully created!'));
  } catch (err) {
    toast.error(i18next.t('Agreement create fail'));
    dispatch(localDataLoader(false));
    throw new Error(err);
    console.log(err);
  }
};

export const agreementSingByEmail =
  ({ email, uid }) =>
  async (dispatch, getState) => {
    try {
      dispatch(localDataLoader());
      const res = await axios.get(
        `${AppConfig.apiUrl}/agreements/signbyemail?email=${email}&uid=${uid}`,
        tokenConfig(getState)
      );
      dispatch({
        type: AGREEMENT_SIGN_SENT_SUCCESS,
        payload: res.data,
      });
      toast.success(i18next.t('Email was send successfully!'));
      dispatch(localDataLoader(false));
      dispatch({
        type: SHOW_STATUS_MESSAGE,
        payload: {
          messageType: successMessageStatus,
          statusMessage: i18next.t(statusMessages.signMessageWasSendSuccess),
        },
      });
    } catch (err) {
      toast.error(i18next.t(err.response.data.message));
      throw new Error(err);
    }
  };

export const checkAgreementStatus = (uid) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${AppConfig.apiUrl}/agreements/chekagreementstatus?uid=${uid}`,
      tokenConfig(getState)
    );
    if (res.data === 1) toast.success('Application was successfully signed!');
    else if (res.data === 3) toast.error('Application was declined');
    const isUserActionFound = res.data === 1 || res.data === 3;
    if (isUserActionFound) dispatch({ type: AGREEMENT_NEED_UPDATE });
    return isUserActionFound;
  } catch (err) {
    console.log(err);
  }
};

export const onSetAgreementStatusToCancel =
  (uid) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${AppConfig.apiUrl}/agreements/cancelagreement?uid=${uid}`,
        tokenConfig(getState)
      );
      if (res) toast.error('Application was canceled');
      dispatch({ type: AGREEMENT_NEED_UPDATE });
    } catch (err) {
      toast.error('Error canceling agreement');
      console.log(err);
    }
  };

export const loadAgreementInfo = (uid) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${AppConfig.apiUrl}/agreements/getagreementinfo/${uid}`,
      tokenConfig(getState)
    );
    if (res) return res.data;
  } catch (err) {
    dispatch({
      type: SHOW_STATUS_MESSAGE,
      payload: {
        messageType: errorMessageStatus,
        statusMessage: i18next.t(statusMessages.agreementLoadError),
      },
    });
    console.log(err);
    throw new Error(err.response.data.message);
  }
};

export const openAgreementDocument = () => async (dispatch, getState) => {
  const agreementId = getState().agreement.newAgreementData.id;
  const agreementLoadedData = await dispatch(loadAgreementInfo(agreementId));
  const { settings } = getState();
  const agreementData = getState().agreement.newAgreementData;
  // const isCashDeposit = (depositMethod[deposit_method].key === 0) ? true : false;

  // const docObject = {
  //     settings: this.props.settings.generalSettings,
  //     client_name: `${first_name} ${family_name}`,
  //     dt_start,
  //     dt_end,
  //     home_address,
  //     mobile_phone,
  //     city,
  //     state,
  //     zip_code,
  //     driver_license,
  //     driver_license_exp_date: moment(driver_license_exp_date).format('YYYY-MM-DD'),
  //     birth_date: moment(birth_date).format('YYYY-MM-DD'),
  //     insurience_police_number,
  //     insurience_exp_date: moment(insurience_exp_date).format('YYYY-MM-DD'),
  //     //Second driver
  //     add_driver_name: add_driver_name || "-",
  //     add_driver_home_address: add_driver_home_address || "-",
  //     add_driver_birthdate: add_driver_birthdate || "-",
  //     add_phone_number: add_phone_number || "-",
  //     add_driver_city: add_driver_city || "-",
  //     add_driver_state: add_driver_state || "-",
  //     add_driver_zip: add_driver_zip || "-",
  //     add_driver_lic_no: add_driver_lic_no || "-",
  //     add_driver_ins: add_driver_ins || "-",
  //     add_driver_ins_date: add_driver_ins_date || "",
  //     //Location
  //     pick_location: pick_location || "-",
  //     drop_location: drop_location || "-",
  //     pick_instruction: pick_instruction || "-",
  //     drop_instruction: drop_instruction || "-",
  //     // Payment info
  //     deposit_method: depositMethod[deposit_method].text || "-",
  //     card_number: !isCashDeposit ? card_number : "-",
  //     name_of_card: !isCashDeposit ? name_of_card : "-",
  //     security_code: !isCashDeposit ? security_code : "-",
  //     deposit: !isCashDeposit ? deposit_amount : "-",
  //     full_payment: !isCashDeposit ? full_payment : "-",
  //     //Vehicle
  //     vin: this.state.formControlsAgreement.vin,
  //     stock_number: this.state.formControlsAgreement.stock_number,
  //     make: this.state.formControlsAgreement.make,
  //     model: this.state.formControlsAgreement.model,
  //     year: this.state.formControlsAgreement.year.toString(),
  //     vehicle_odometer_in: this.state.formControlsAgreement.vehicle_odometer_in,
  //     vehicle_free_miles: this.state.formControlsAgreement.vehicle_free_miles,
  //     signImageDataURL: this.state.signImageDataURL,
  //     fuel_in: fuel_out || '',
  //     fuel_out: '',
  //     rent_days,
  //     rent_month,
  //     rent_ticket,
  //     rent_chargeable_mils,
  //     rent_full_cost
  // };
  //
  generateAgreement({ data: agreementLoadedData, settings });
  console.log(agreementData);
};
