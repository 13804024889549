import { SHOW_NEW_AGREEMENT_MODAL_SHOW } from '../actions/UiActions';
import {
  AGREEMENT_FORM_STEP,
  AGREEMENT_APPLY_SUCCESS,
  HIDE_AGREEMENT_MODAL_SHOW,
  LOAD_DRIVER_INFO,
  LOAD_DRIVER_LIST,
  LOAD_FREE_VEHICLES_LIST,
  RESET_FREE_VEHICLES_LIST,
} from '../actions/types';

const initState = {
  showNewAgreementForm: false,
  agreementStep: 0,
  freeVehicles: [],
  driversList: [],
  agreementDrivers: {
    firstDriver: null,
    additionalDriver: null,
  },
  newAgreementData: [],
};

export default function agreement(state = initState, action) {
  switch (action.type) {
    case SHOW_NEW_AGREEMENT_MODAL_SHOW:
    case HIDE_AGREEMENT_MODAL_SHOW: {
      return {
        ...state,
        showNewAgreementForm: action.payload.isShow,
        agreementStep: action.payload.agreementStep,
      };
    }
    case AGREEMENT_FORM_STEP: {
      return {
        ...state,
        agreementStep: action.payload.agreementStep,
      };
    }
    case LOAD_FREE_VEHICLES_LIST: {
      return {
        ...state,
        freeVehicles: action.payload,
      };
    }
    case RESET_FREE_VEHICLES_LIST: {
      return {
        ...state,
        freeVehicles: [],
      };
    }
    case LOAD_DRIVER_LIST: {
      return {
        ...state,
        driversList: action.payload,
      };
    }
    case LOAD_DRIVER_INFO: {
      return {
        ...state,
        agreementDrivers: {
          ...state.agreementDrivers,
          [action.payload.driverType]: action.payload.driverInfo,
        },
      };
    }
    case AGREEMENT_APPLY_SUCCESS: {
      return {
        ...state,
        newAgreementData: action.payload,
      };
    }
    default:
      return state;
  }
}
