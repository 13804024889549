import moment from 'moment';
import _ from 'lodash';
import { Model } from './Model.js';

export class Agreement extends Model {
  constructor(props) {
    super(props);
    this.images = this.getImages();
    this.dt_start = this.getDateFullWOSec(this.dt_start);
    this.dt_end = this.getDateFullWOSec(this.dt_end);
    this.vehicle = this.getVehicle();
    this.birth_date = this.getDate(this.birth_date);
    this.agreement_date = this.getDateFull(
      this.agreement_date ? new Date(this.agreement_date) : new Date()
    );
  }

  get defaults() {
    return {
      id: undefined,
      agreement_number: '',
      agreement_date: undefined,
      dt_start: undefined,
      dt_end: undefined,
      company_name: '',
      family_name: '',
      first_name: '',
      birth_date: undefined,
      place_of_birth: '',
      home_address: undefined,
      zip_code: '',
      city: '',
      state: '',
      country: '',
      local_address: '',
      passport_id: '',
      mobile_phone: undefined,
      work_phone: '',
      local_phone: '',
      cell_phone: '',
      email: '',
      exp_date: undefined,
      cvv: '',
      insurience_company: '',
      insurience_police_number: '',
      insurience_exp_date: undefined,
      driver_license_exp_date: undefined,
      driver_license: '',
      add_driver_family_name: '',
      add_driver_first_name: '',
      add_driver_home_address: '',
      add_driver_birthdate: undefined,
      add_mobile_number: '',
      add_driver_city: '',
      add_driver_state: '',
      add_driver_zip: '',
      add_driver_license: '',
      add_driver_license_exp_date: undefined,
      vin: '',
      stock_number: '',
      make: '',
      model: '',
      year: undefined,
      vehicle_license: '',
      fuel_in: '',
      fuel_out: '',
      vehicle_odometer_in: '',
      vehicle_odometer_out: '',
      vehicle_miles_driven: '',
      vehicle_free_miles: '',
      vehicle_chargeable_miles: '',
      pick_location: '',
      pick_instruction: '',
      drop_location: '',
      drop_instruction: '',
      deposit_method: 0,
      card_number: '',
      name_of_card: '',
      security_code: '',
      deposit_amount: '',
      full_payment: '',
      contract: '',
      delivered: '',
      rent_days: '',
      rate_d1: '',
      rent_days_total: '',
      rent_month: '',
      rate_monthly: '',
      rent_month_total: '',
      rent_ticket: '',
      rent_ticket_cost: '',
      rent_ticket_total: '',
      rent_chargeable_mils: '',
      rent_chargeable_mils_total: '',
      rent_chargeable_mils_cost: '',
      rent_full_cost: '',
      sign: null,
      status: 0,
      amount: 0,
    };
  }

  getImages() {
    try {
      const images = this.files ? JSON.parse(this.files) : {};
      // eslint-disable-next-line no-prototype-builtins
      if (!images.hasOwnProperty('preview')) images.preview = '';
      // eslint-disable-next-line no-prototype-builtins
      if (!images.hasOwnProperty('full')) images.full = '';

      return images;
    } catch (e) {
      return '';
    }
  }

  getDateFull(date) {
    return date ? moment(date).format('MM/DD/YYYY HH:mm:ss') : undefined;
  }

  getDateFullWOSec(date) {
    if (_.isObject(date)) return moment(date).format('MM/DD/YYYY HH:mm');
    return date ? moment(new Date(date)).format('MM/DD/YYYY HH:mm') : undefined;
  }

  getDate(date) {
    return date ? moment(new Date(date)).format('MM.DD.YYYY') : undefined;
  }

  getVehicle() {
    return `${this.make} ${this.model}`;
  }
}
