import { Model } from './Model';

export class searchVehicleFilter extends Model {
  constructor(props) {
    super(props);
  }

  get defaults() {
    return {
      name: '',
      value: [],
      toggle: false,
      linkName: '',
    };
  }
}
