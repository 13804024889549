import BaseRequestModel from './base-request-model';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const ApiService = {
  get: (route, auth = false) => {
    const newBase = new BaseRequestModel(route, 'GET', headers, null, auth);
    return newBase.request();
  },
  post: (route, body, auth = false) => {
    const newBase = new BaseRequestModel(route, 'POST', headers, body, auth);
    return newBase.request();
  },
};
