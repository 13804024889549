import {
  LOAD_VEHICLE,
  LOAD_VEHICLE_PREV_STATE,
  LOAD_VEHICLES,
  LOAD_VEHICLES_FAIL,
} from '../../actions/types';
import { Vehicle } from '../../common/models/Vehicle';

const initState = {
  vehiclesList: [],
  currentVehicle: undefined,
  vehicleForm: new Vehicle(),
};

export default function vehicles(state = initState, action) {
  switch (action.type) {
    case LOAD_VEHICLES: {
      return { ...state, vehiclesList: action.payload };
    }
    case LOAD_VEHICLES_FAIL: {
      return { ...state, vehiclesList: [] };
    }
    case LOAD_VEHICLE:
    case LOAD_VEHICLE_PREV_STATE: {
      return { ...state, vehicleForm: action.payload };
    }

    default:
      return state;
  }
}
