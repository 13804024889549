import React, { Component } from 'react';
import { Button, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { loadUser, login } from '../../actions/user/auth';
import AppConfig from '../../config';
import { setDataLoading } from '../../actions/UiActions';
import { getSettingsData } from '../../actions/settings';

class Auth extends Component {
  state = {
    email: '',
    password: '',
    loading: false,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submitForm = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    this.props
      .login({ login: this.state.email, password: this.state.password })
      .then(() => {
        this.props.setDataLoading(true);
        this.props.loadUser();
        this.props.getSettingsData();
      })
      .catch((e) => {
        console.log('Error');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { isAuth, loginFailure, isDataLoading } = this.props;
    return (
      <React.Fragment>
        {isAuth && <Redirect to={AppConfig.authRedirectRoute} />}
        <Grid
          textAlign="center"
          style={{ height: '100vh' }}
          verticalAlign="middle"
        >
          {!isDataLoading && (
            <Grid.Column style={{ maxWidth: 450 }}>
              <Header as="h2" textAlign="center">
                <Icon name="car" size="large" /> Log-in to your account
              </Header>
              <Form onSubmit={this.submitForm} size="large">
                <Segment stacked>
                  <div className="auth-error-message">{loginFailure}</div>
                  <Form.Input
                    name="email"
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="E-mail address"
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    name="password"
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    onChange={this.handleChange}
                  />

                  <Button
                    primary
                    fluid
                    size="large"
                    className={classNames({ loading: this.state.loading })}
                    disabled={this.state.loading}
                    type="submit"
                  >
                    Login
                  </Button>
                </Segment>
              </Form>
            </Grid.Column>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuth, userData, loginFailure } = state.auth;
  const { isDataLoading } = state.ui;
  return {
    isAuth,
    loginFailure,
    userData,
    isDataLoading,
  };
};

const mapDispatchToProps = { login, setDataLoading, loadUser, getSettingsData };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
