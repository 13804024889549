import axios from 'axios';
import AppConfig from '../config';
import { store } from '../store/configureStore';
import { setDataLoading } from '../actions/UiActions';

class Api {
  static defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  post(url, body = {}, params = {}) {
    const { headers = {}, timeout = 0 } = params;

    const state = store.getState();
    const authToken = state.auth.token;

    url = `${AppConfig.apiUrl}/${url}`;
    const requestHeaders = {
      ...Api.defaultHeaders,
      ...headers,
      Authorization: authToken,
    };
    const requestData = body;

    return axios.post(url, requestData, {
      headers: requestHeaders,
      timeout,
    });
  }

  get(url, params = {}) {
    const timeout = 0;
    const headers = {};
    const state = store.getState();
    const authToken = state.auth.token;

    url = `${AppConfig.apiUrl}/${url}`;
    const requestHeaders = {
      ...Api.defaultHeaders,
      ...headers,
      Authorization: authToken,
    };
    const requestData = { ...params };
    return axios.get(url, {
      headers: requestHeaders,
      params: requestData,
      timeout,
    });
  }

  getById(url, params) {
    const timeout = 0;
    const headers = {};
    const state = store.getState();
    const authToken = state.auth.token;
    const getParams = () => {
      if (Array.isArray(params)) return params.join('/');
      return params;
    };

    const requestHeaders = {
      ...Api.defaultHeaders,
      ...headers,
      Authorization: authToken,
    };
    const requestData = getParams();
    url = `${AppConfig.apiUrl}/${url}/${requestData}`;
    return axios.get(url, {
      headers: requestHeaders,
      timeout,
    });
  }
}

export function setLoadingStatus(status = false) {
  store.dispatch(setDataLoading(status));
}

export default Api;
