import React from 'react';
import PropTypes from 'prop-types';
import '../styles/image-uploader.scss';
import Dropzone from 'react-dropzone-uploader';
import i18next from 'i18next';
import AppConfig from '../../../config';

import removeImg from '../shared/assets/remove.svg';

const iconByFn = {
  remove: { backgroundImage: `url(${removeImg})` },
};

export function ImageUploader(props) {
  const maxFiles = props.maxImagesToUpload;
  const { maxSizeBytes } = props;

  const Preview = ({ meta, fileWithMeta }) => {
    const { name, status } = meta;
    const imageStatus = status === 'done' ? i18next.t('Added') : status;
    return (
      <div className="filePreviewWrapper">
        <span style={{ alignSelf: 'flex-start', margin: '10px 3%' }}>
          {name}, {imageStatus}
        </span>
        <span
          className="dzu-previewButton"
          style={iconByFn.remove}
          onClick={() => fileWithMeta.remove()}
        />
      </div>
    );
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {};

  const handleValidation = ({ meta, files }) => {
    console.log(files);

    // const { filesArray } = this.state;
    // return filesArray.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    props.onUpload(allFiles);
    allFiles.forEach((f) => f.remove());
  };

  return (
    <Dropzone
      // getUploadParams={getUploadParams}
      PreviewComponent={Preview}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      accept="image/*, image/heic"
      maxFiles={maxFiles}
      maxSizeBytes={maxSizeBytes}
      validate={handleValidation}
      canCancel
      canRemove={false}
      disabled={(files) =>
        files.some((f) =>
          ['preparing', 'getting_upload_params', 'uploading'].includes(
            f.meta.status
          )
        )
      }
    />
  );
}

ImageUploader.defaultProps = {
  maxImagesToUpload: AppConfig.maxVehicleImageToUpload,
};
ImageUploader.propTypes = {
  maxImagesToUpload: PropTypes.number.isRequired,
  maxSizeBytes: PropTypes.number.isRequired,
};
