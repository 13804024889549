import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Message,
  Portal,
  Segment,
  Table,
} from 'semantic-ui-react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { getSigningAgreementData } from '../../../../services/helpers/agreements';
import { depositMethod, signAction } from '../../../catalogs/constants';
import { generateAgreement } from '../../../../services/generatePDF';
import { setDataLoading } from '../../../../actions/UiActions';
import agreementsApi from '../../../../services/api/agreements.api';
import styles from './SignAgreement.module.scss';
import AppConfig from '../../../../config';
import { redirectToMain } from '../../../../services/utils';

function SignAgreement(props) {
  const { uid } = useParams();
  const [isSuccessSigned, setSuccessSignet] = useState(false);
  const [isDeclineSigned, setDeclineSignet] = useState(false);
  const dispatch = useDispatch();
  const redirectToMainPage = debounce(
    redirectToMain,
    AppConfig.redirectTimeOut
  );
  const {
    data: { agreement, settings },
    isAgreementLoading,
    isAgreementError,
  } = getSigningAgreementData(uid);

  useEffect(() => {
    if (isAgreementError || isSuccessSigned || isDeclineSigned)
      redirectToMainPage();
  }, [isAgreementError, isSuccessSigned, isDeclineSigned]);

  const singStatus = (action) => {
    switch (action) {
      case signAction.signe:
        setSuccessSignet(true);
        break;
      case signAction.decline:
        setDeclineSignet(true);
        break;
      default:
        break;
    }
  };

  const haendleSign = (action) => {
    dispatch(setDataLoading(true));
    agreementsApi
      .signAgreementAction(uid, action)
      .then(() => {
        singStatus(action);
      })
      .catch((e) => {
        toast.error(i18next.t('Agreement declining fail'));
      })
      .finally(() => {
        dispatch(setDataLoading(false));
      });
  };

  const handlerOpenAgreementPdf = () => {
    dispatch(setDataLoading(true));
    generateAgreement({ data: agreement, settings })
      .then(() => {})
      .finally(() => {
        dispatch(setDataLoading(false));
      });
  };
  const renderError = () => (
    <Message
      attached
      compact
      content={i18next.t('Sorry, but rental agreement for signe not found')}
      icon="search"
      error
    />
  );

  const renderAgreement = () => (
    <>
      <Header as="h2" className={styles.title}>
        <span>
          {`${i18next.t('Signe rental agreement')} #${
            agreement.agreement_number
          }`}
        </span>
        <span>{`${i18next.t('at')} ${agreement.agreement_date}`}</span>
      </Header>
      <Header as="h4" attached="top" block textAlign="left">
        <Icon name="car" /> {i18next.t('Vehicle')}
      </Header>
      <Segment attached textAlign="left">
        <Table attached="bottom" striped>
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing active>
                {i18next.t('Make/model')}
              </Table.Cell>
              <Table.Cell>{agreement.vehicle}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing active>
                {i18next.t('Date start')}
              </Table.Cell>
              <Table.Cell>{agreement.dt_start}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing active>
                {i18next.t('Date end')}
              </Table.Cell>
              <Table.Cell>{agreement.dt_end}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>

      <Divider section />

      <Header as="h4" attached="top" block textAlign="left">
        <Icon name="user" />
        {i18next.t('Renter')}
      </Header>

      <Segment attached textAlign="left">
        {`${agreement.family_name} ${agreement.first_name}`}
      </Segment>

      <Divider section />
      <Header as="h4" attached="top" block textAlign="left">
        <Icon name="money bill alternate" /> {i18next.t('Payment')}
      </Header>

      <Segment attached textAlign="left">
        <Table attached="bottom" striped>
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing active>
                {i18next.t('Payment type')}
              </Table.Cell>
              <Table.Cell>
                {depositMethod[agreement.deposit_method].text}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing active>
                {i18next.t('Deposit')}
              </Table.Cell>
              <Table.Cell>{agreement.deposit_amount}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing active>
                {i18next.t('Full payment')}
              </Table.Cell>
              <Table.Cell>{agreement.full_payment}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>

      <Divider section />
      <Header as="h4" attached="top" block textAlign="left">
        <Icon name="file pdf outline" />
        {i18next.t('Rental agreement')}
      </Header>

      <Segment attached textAlign="left">
        <span className={styles.download} onClick={handlerOpenAgreementPdf}>
          {i18next.t('Download rental agreement')}
        </span>
      </Segment>

      <Divider section />
      <Button positive onClick={() => haendleSign(signAction.signe)}>
        {i18next.t('Sign')}
      </Button>
      <Button negative onClick={() => haendleSign(signAction.decline)}>
        {i18next.t('Decline')}
      </Button>
    </>
  );

  if (isAgreementLoading) return <></>;

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column>
        <Portal open={isSuccessSigned || isDeclineSigned}>
          <div className="ani-icon-wrapper">
            <div
              className={cn(
                'ani-icon',
                { 'success-icon': isSuccessSigned },
                { 'decline-icon': isDeclineSigned }
              )}
            >
              {isSuccessSigned && <Icon name="checkmark" />}
              {isDeclineSigned && <Icon name="close" />}
            </div>
          </div>
        </Portal>
        <Container text>
          {isAgreementError ? renderError() : renderAgreement()}
        </Container>
      </Grid.Column>
    </Grid>
  );
}

export default React.memo(SignAgreement);
