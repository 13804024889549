import {
  SETTINGS_DEFAULT_AGREEMENT_INFO,
  SETTINGS_GET_SETTINGS,
  SETTINGS_LOGO_CLEAR,
  SETTINGS_UPDATE_SETTINGS,
  SETTINGS_UPLOAD_LOGO,
} from '../actions/types';

const initState = {};

export default function settings(state = initState, action) {
  switch (action.type) {
    case SETTINGS_GET_SETTINGS:
    case SETTINGS_UPDATE_SETTINGS: {
      return { ...state, ...action.payload };
    }
    case SETTINGS_UPLOAD_LOGO: {
      return { ...state, media_url: action.payload };
    }
    case SETTINGS_LOGO_CLEAR: {
      return { ...state, media_url: '' };
    }
    case SETTINGS_DEFAULT_AGREEMENT_INFO: {
      return {
        ...state,
        terms: action.payload.terms,
        warnings: action.payload.warnings,
        running_title: action.payload.running_title,
      };
    }
    default:
      return state;
  }
}
