import {
  SHOW_NEW_AGREEMENT_MODAL_SHOW,
  SET_DATA_LOADING,
} from '../actions/UiActions';
import {
  SHOW_STATUS_MESSAGE,
  HIDE_STATUS_MESSAGE,
  LOCAL_DATA_LOADER,
} from '../actions/types';

const initState = {
  isNewAgreementModalShow: false,
  isDataLoading: false,
  isLocalDataLoader: false,
  statusMessage: {
    messageType: '',
    statusMessageText: '',
    isMessageVisible: false,
  },
};

export function uiReducer(state = initState, action) {
  switch (action.type) {
    case SHOW_NEW_AGREEMENT_MODAL_SHOW: {
      return { ...state, isNewAgreementModalShow: action.payload };
    }
    case SET_DATA_LOADING: {
      return { ...state, isDataLoading: action.payload };
    }
    case LOCAL_DATA_LOADER: {
      return { ...state, isLocalDataLoader: action.payload };
    }
    case SHOW_STATUS_MESSAGE: {
      return { ...state, statusMessage: action.payload };
    }
    case HIDE_STATUS_MESSAGE: {
      return {
        ...state,
        statusMessage: { ...state.statusMessage, isMessageVisible: false },
      };
    }
    default:
      return state;
  }
}
