import React, { Component } from 'react';
import {
  Button,
  Icon,
  Menu,
  Segment,
  Form,
  Table,
  Card,
  Confirm,
  Grid,
  Image,
  Placeholder,
  TransitionablePortal,
  List,
  Dropdown,
} from 'semantic-ui-react';
import i18next from 'i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Modal from 'react-modal';
import _ from 'lodash';
import classNames from 'classnames';
import timezones from 'timezones.json';

import { connect } from 'react-redux/es/alternate-renderers';
import AppConfig from '../../../config';
import * as initstate from '../../catalogs/initstates';
import {
  getSettingsData,
  loadDefaultAgreementInfo,
  logoClear,
  updateSettingsData,
  uploadLogo,
} from '../../../actions/settings';
import CardImage from '../shared/CardImage';
import StatusMessage from '../shared/StatusMessage';
import {
  countryOptions,
  currencyList,
  mileageList,
} from '../../catalogs/constants';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
      activeTabAgreement: 0,
      activeTabUsers: 0,
      activeTab: 0,
      isImageLoaded: true,
      imageLoader: false,
      uploadedImageId: -1,
      openConfirm: false,
      showStatusMessage: false,
      settingsFormFields: initstate.setupInitState,
      settingsFieldsTouched: {},
    };
    this.fileLoad = React.createRef();
  }

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.settings.media_url !== prevProps.settings.media_url)
      this.setState((prevState, props) => {
        return {
          settingsFormFields: {
            ...prevState,
            media_url: this.props.settings.media_url,
          },
        };
      });

    if (
      this.props.settings.terms !== prevProps.settings.terms ||
      this.props.settings.warnings !== prevProps.settings.warnings ||
      this.props.settings.running_title !== prevProps.settings.running_title
    )
      this.setState((prevState, props) => {
        return {
          settingsFormFields: {
            ...prevState,
            terms: this.props.settings.terms,
            warnings: this.props.settings.warnings,
            running_title: this.props.settings.running_title,
          },
        };
      });

    if (this.props.settings?.id !== prevProps.settings?.id) this.initData();
  }

  initData = async (initData = this.props.settings) => {
    this.setState({ settingsFormFields: initData, settingsFieldsTouched: {} });
  };

  onSaveSettings = () => {
    const { settingsFieldsTouched, settingsFormFields } = this.state;
    const savedFields = Object.keys(settingsFieldsTouched);
    const dataToServer = {};

    savedFields.forEach((item) => {
      dataToServer[item] = settingsFormFields[item];
    });

    if (Object.keys(dataToServer).length)
      this.props.updateSettingsData(dataToServer).then(() => {
        this.initData();
      });
  };

  clearCompanyLogo = () => {
    this.props.logoClear();
  };

  uploadLogoImage = async (image) => {
    this.setState({ imageLoader: true });
    try {
      await this.props.uploadLogo(image);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ imageLoader: false });
    }
  };

  handleInputChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState((prevState, props) => {
      return {
        settingsFormFields: { ...prevState.settingsFormFields, [name]: value },
        settingsFieldsTouched: {
          ...prevState.settingsFieldsTouched,
          [name]: true,
        },
      };
    });
  };

  handleSelectChange = (e, result) => {
    const { name } = result;
    const { value } = result;
    this.setState((prevState, props) => {
      return {
        settingsFormFields: { ...prevState.settingsFormFields, [name]: value },
        settingsFieldsTouched: {
          ...prevState.settingsFieldsTouched,
          [name]: true,
        },
      };
    });
  };

  uploadLogoBtnClick = (event) => {
    if (event.target.files.length > 0) {
      this.uploadLogoImage(event.target.files[0]);
    }
  };

  setActivePage = (activePageNumber) => {
    this.setState({ activePage: activePageNumber });
  };

  onLoadDefaultsAgreement = async () => {
    await this.props.loadDefaultAgreementInfo();
    this.setState({ openConfirm: false });
  };

  _renderConfirm = () => {
    const { openConfirm } = this.state;
    const content = `${i18next.t(
      'Do you want load default warnings and terms'
    )}?`;
    const header = i18next.t('Loading...');
    return (
      <Confirm
        open={openConfirm}
        header={header}
        content={content}
        onCancel={() => {
          this.setState({ openConfirm: false });
        }}
        onConfirm={this.onLoadDefaultsAgreement}
        confirmButton={i18next.t('Yes')}
        cancelButton={i18next.t('No')}
      />
    );
  };

  _renderImagePreview() {
    const { showPreview, media_url } = this.state;
    return (
      <TransitionablePortal onClose={this.onPreviewClose} open={showPreview}>
        <Segment
          onClick={this.onPreviewClose}
          style={{ left: '10%', position: 'fixed', top: '10%', zIndex: 1000 }}
        >
          <Image src={AppConfig.serverRoot + media_url} size="big" />
        </Segment>
      </TransitionablePortal>
    );
  }

  _renderNewUserModal = () => {
    const { showNewUser } = this.state;
    return (
      <Modal
        isOpen={showNewUser}
        onRequestClose={() => this.setState({ showNewUser: false })}
        shouldCloseOnOverlayClick
        overlayClassName="modalOverlay"
        className="modalExpenses"
      >
        <Card fluid>
          <Card.Content extra className="card-header">
            <Card.Header>{i18next.t('Add new user')}</Card.Header>
          </Card.Content>
          <Card.Content>
            <Form className="attached fluid segment">
              <Form.Group widths="equal">
                <Form.Input
                  label={i18next.t('User name')}
                  placeholder={i18next.t('User name')}
                />
              </Form.Group>
            </Form>
          </Card.Content>
          <Card.Content extra>
            <div className="ui two buttons">
              <Button
                positive
                onClick={() => this.setState({ showNewUser: false })}
              >
                {i18next.t('Add')}
              </Button>
              <Button
                negative
                onClick={() => this.setState({ showNewUser: false })}
              >
                {i18next.t('Cancel')}
              </Button>
            </div>
          </Card.Content>
        </Card>
      </Modal>
    );
  };

  _renderCompanyDetailsTab = () => {
    const {
      company_name,
      owner_name,
      vat,
      country,
      address1,
      address2,
      city,
      state,
      zip,
      phone,
      fax,
      email,
      webpage,
      media_url,
    } = this.state.settingsFormFields;
    const { isImageLoaded, imageLoader, uploadedImageId, activeTab } =
      this.state;

    return (
      <Grid>
        <Grid.Column width={6}>
          <Grid.Column>
            <div className="load-image-wrapper">
              <div
                className={classNames('ui active centered', {
                  loader: imageLoader,
                })}
              />
              <div className="image-wrapper">
                <CardImage
                  isEditMode
                  imageUrl={media_url}
                  isHasImage
                  onImageLoad={() => this.fileLoad.current.click()}
                  onImageClear={() => {
                    this.clearCompanyLogo();
                  }}
                  onPreview={() => {}}
                  isUploadImageState={false}
                  defaultImage={AppConfig.defaultLogo}
                />
              </div>
              {!isImageLoaded && (
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              )}
              <div ref={this.formUploadRef}>
                <input
                  type="file"
                  name="file-load"
                  ref={this.fileLoad}
                  hidden
                  value={this.state.uploadFile}
                  onChange={this.uploadLogoBtnClick}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Column>
        <Grid.Column width={10}>
          <Tabs
            selectedIndex={activeTab}
            onSelect={(e) => this.setState({ activeTab: e })}
          >
            <TabList>
              <Tab>{i18next.t('General information')}</Tab>
              <Tab>{i18next.t('Address')}</Tab>
            </TabList>
            <Form>
              <TabPanel className={classNames('tab-wrapper')}>
                <div className="tab-wrapper-inner">
                  <Form.Input
                    inline
                    name="company_name"
                    label={i18next.t('Company name')}
                    placeholder={i18next.t('Company name')}
                    value={company_name}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="owner_name"
                    label={i18next.t('Owner name')}
                    placeholder={i18next.t('Owner name')}
                    value={owner_name}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="vat"
                    label={i18next.t('VAT/Tax ID number')}
                    placeholder={i18next.t('VAT/Tax ID number')}
                    value={vat}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="phone"
                    label={i18next.t('Phone number')}
                    placeholder={i18next.t('Phone number')}
                    value={phone}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="fax"
                    label={i18next.t('Fax number')}
                    placeholder={i18next.t('Fax number')}
                    value={fax}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="email"
                    label={i18next.t('Email')}
                    placeholder={i18next.t('Email')}
                    value={email}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="webpage"
                    label={i18next.t('Web page')}
                    placeholder={i18next.t('Web page')}
                    value={webpage}
                    onChange={this.handleInputChange}
                  />
                </div>
              </TabPanel>
              <TabPanel className={classNames('tab-wrapper')}>
                <div className="tab-wrapper-inner">
                  <Form.Input
                    inline
                    name="address1"
                    label={i18next.t('Address')}
                    placeholder={i18next.t('Address')}
                    value={address1}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="address2"
                    label={i18next.t('Address-2')}
                    placeholder={i18next.t('Address-2')}
                    value={address2}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="city"
                    label={i18next.t('City')}
                    placeholder={i18next.t('City')}
                    value={city}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="state"
                    label={i18next.t('State')}
                    placeholder={i18next.t('State')}
                    value={state}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="zip"
                    label={i18next.t('Zip/Post code')}
                    placeholder={i18next.t('Zip/Post code')}
                    value={zip}
                    onChange={this.handleInputChange}
                  />
                  <Form.Input
                    inline
                    name="country"
                    label={i18next.t('Country')}
                    placeholder={i18next.t('Country')}
                    value={country}
                    onChange={this.handleInputChange}
                  />
                </div>
              </TabPanel>
            </Form>
          </Tabs>
        </Grid.Column>
      </Grid>
    );
  };

  _renderAdministratorTab = () => {
    const { activeTabUsers } = this.state;
    return (
      <Tabs
        selectedIndex={activeTabUsers}
        onSelect={(e) => this.setState({ activeTabUsers: e })}
      >
        <TabList>
          <Tab>{i18next.t('Administrator')}</Tab>
        </TabList>
        <TabPanel className={classNames('tab-wrapper')}>
          <Table celled selectable compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>User ID</Table.HeaderCell>
                <Table.HeaderCell>Full name</Table.HeaderCell>
                <Table.HeaderCell>Level</Table.HeaderCell>
                <Table.HeaderCell> </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Administrator</Table.Cell>
                <Table.Cell>Full name</Table.Cell>
                <Table.Cell>Level</Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  <Button compact icon primary size="small">
                    <Icon name="edit" />
                  </Button>
                  <Button compact icon negative size="small">
                    <Icon name="close" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  <Button
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="small"
                    onClick={() => this.setState({ showNewUser: true })}
                  >
                    <Icon name="user" /> {i18next.t('Add user')}
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </TabPanel>
      </Tabs>
    );
  };

  _renderCountryTab = () => {
    const { activeTabUsers } = this.state;
    const { sys_country, sys_tz } = this.state.settingsFormFields;
    return (
      <Tabs
        selectedIndex={activeTabUsers}
        onSelect={(e) => this.setState({ activeTabUsers: e })}
      >
        <TabList>
          <Tab>{i18next.t('Country')}</Tab>
        </TabList>
        <TabPanel className={classNames('tab-wrapper')}>
          <div className="tab-wrapper-inner">
            <Form>
              <Form.Group>
                <Form.Select
                  style={{ minWidth: '200px' }}
                  inline
                  fluid
                  label={i18next.t('Country')}
                  name="sys_country"
                  options={countryOptions}
                  value={sys_country}
                  placeholder={i18next.t('Select country')}
                  onChange={this.handleSelectChange}
                />
                <Form.Select
                  style={{ minWidth: '350px' }}
                  inline
                  fluid
                  label={i18next.t('Time zone')}
                  name="sys_tz"
                  options={timezones}
                  value={sys_tz}
                  placeholder={i18next.t('Select time zone')}
                  onChange={this.handleSelectChange}
                />
              </Form.Group>
            </Form>
          </div>
        </TabPanel>
      </Tabs>
    );
  };

  _renderAgreementTab = () => {
    const { warnings, terms, running_title } = this.state.settingsFormFields;
    const { activeTabAgreement } = this.state;
    return (
      <Tabs
        selectedIndex={activeTabAgreement}
        onSelect={(e) => this.setState({ activeTabAgreement: e })}
      >
        <TabList>
          <Tab>{i18next.t('Agreement')}</Tab>
        </TabList>
        <TabPanel className={classNames('tab-wrapper')}>
          <Form>
            <Form.Group widths="equal">
              <Form.TextArea
                name="warnings"
                label={i18next.t('Warnings')}
                rows="20"
                placeholder={i18next.t('Warnings')}
                value={warnings}
                onChange={this.handleInputChange}
              />
              <Form.TextArea
                name="terms"
                label={i18next.t('Rental Agreement Terms and Conditions')}
                rows="20"
                placeholder={i18next.t('Rental Agreement Terms and Conditions')}
                value={terms}
                onChange={this.handleInputChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="running_title"
                label={i18next.t('Footer running title')}
                rows="12"
                placeholder={i18next.t('Footer running title')}
                value={running_title}
                onChange={this.handleInputChange}
              />
            </Form.Group>
          </Form>
        </TabPanel>
      </Tabs>
    );
  };

  _renderPreferencesTab = () => {
    const { currency, mileage } = this.state.settingsFormFields;
    const { activeTabAgreement } = this.state;
    return (
      <Tabs
        selectedIndex={activeTabAgreement}
        onSelect={(e) => this.setState({ activeTabAgreement: e })}
      >
        <TabList>
          <Tab>{i18next.t('General')}</Tab>
        </TabList>
        <TabPanel className={classNames('tab-wrapper')}>
          <div className="tab-wrapper-inner">
            <Form.Select
              style={{ minWidth: '200px' }}
              inline
              fluid
              label={i18next.t('Currency')}
              name="currency"
              options={currencyList}
              value={currency}
              placeholder={i18next.t('Currency')}
              // onChange={this.handleSelectChange}
            />

            <Form.Select
              style={{ minWidth: '200px' }}
              inline
              fluid
              label={i18next.t('Mileage')}
              name="mileage"
              options={mileageList}
              value={mileage}
              placeholder={i18next.t('Mileage')}
              onChange={this.handleSelectChange}
            />
          </div>
        </TabPanel>
      </Tabs>
    );
  };

  _renderSideBarPanel() {
    const { activePage } = this.state;
    return (
      <Grid.Column width={4}>
        <Segment>
          <List divided relaxed className="user-list old-style">
            <List.Item
              as="a"
              onClick={() => this.setActivePage(0)}
              active={activePage === 0}
            >
              <List.Icon name="building" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>{i18next.t('Company details')}</List.Header>
              </List.Content>
            </List.Item>
            <List.Item
              as="a"
              onClick={() => this.setActivePage(1)}
              active={activePage === 1}
            >
              <List.Icon name="file" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>{i18next.t('Agreement')}</List.Header>
              </List.Content>
            </List.Item>
            <List.Item
              as="a"
              onClick={() => this.setActivePage(2)}
              active={activePage === 2}
            >
              <List.Icon name="settings" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>{i18next.t('Preferences')}</List.Header>
              </List.Content>
            </List.Item>
            <List.Item
              as="a"
              onClick={() => this.setActivePage(3)}
              active={activePage === 3}
            >
              <List.Icon name="key" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>{i18next.t('Administrator')}</List.Header>
              </List.Content>
            </List.Item>
            <List.Item
              as="a"
              onClick={() => this.setActivePage(4)}
              active={activePage === 4}
            >
              <List.Icon name="flag" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>{i18next.t('Country')}</List.Header>
              </List.Content>
            </List.Item>
          </List>
        </Segment>
      </Grid.Column>
    );
  }

  _renderForm() {
    const { activePage } = this.state;
    return (
      <Segment>
        {activePage === 0 && this._renderCompanyDetailsTab()}
        {activePage === 1 && this._renderAgreementTab()}
        {activePage === 2 && this._renderPreferencesTab()}
        {activePage === 3 && this._renderAdministratorTab()}
        {activePage === 4 && this._renderCountryTab()}
      </Segment>
    );
  }

  _renderActionPanel() {
    const {
      showStatusMessage,
      messageType,
      statusMessage,
      settingsFieldsTouched,
      activePage,
    } = this.state;
    return (
      <Menu className="tool-bar" stackable>
        <Menu.Item className="section-name" header>
          {i18next.t('Settings')}
        </Menu.Item>
        <Menu.Item name="features">
          <Button
            icon
            positive
            labelPosition="left"
            disabled={!!_.isEmpty(settingsFieldsTouched)}
            onClick={this.onSaveSettings}
          >
            <Icon name="check" />
            {i18next.t('Save')}
          </Button>
          <Button
            icon
            negative
            labelPosition="left"
            disabled={!!_.isEmpty(settingsFieldsTouched)}
            onClick={this.initData}
          >
            <Icon name="close" />
            {i18next.t('Cancel')}
          </Button>
          {activePage === 1 && (
            <Button
              icon
              labelPosition="left"
              onClick={() => this.setState({ openConfirm: true })}
            >
              <Icon name="clipboard list" />
              {i18next.t('Load defaults')}
            </Button>
          )}
        </Menu.Item>
        <Menu.Item className="section-message" header>
          <StatusMessage />
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    return (
      <div className="Clients catalog-tabs">
        {this._renderImagePreview()}
        {this._renderConfirm()}
        {this._renderActionPanel()}
        {this._renderNewUserModal()}
        <Grid columns="equal">
          <Grid.Row>
            {this._renderSideBarPanel()}
            <Grid.Column>{this._renderForm()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSettingsData,
  uploadLogo,
  logoClear,
  updateSettingsData,
  loadDefaultAgreementInfo,
};

const mapStateToProps = (state) => {
  const { isDataLoading } = state.ui;
  const { settings } = state;

  return {
    isDataLoading,
    settings,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
