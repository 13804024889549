import { combineReducers } from 'redux';
import auth from './user/auth';
import vehicles from './vehicles/vehicles';
import { uiReducer } from './ui';
import settings from './settings';
import agreement from './agreement';
import planner from './planner';
import clients from './clients';
import allAgreements from './allAgreements';

export const rootReducer = combineReducers({
  auth,
  vehicles,
  clients,
  ui: uiReducer,
  settings,
  agreement,
  planner,
  allAgreements,
});
