import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button, Divider, Form, Icon, Transition } from 'semantic-ui-react';
import { useFormik } from 'formik';

import i18next from 'i18next';
import styles from './VehiclesSearch.module.scss';
import { carClass } from '../../../catalogs/constants';
import vehiclesApi from '../../../../services/api/vehicles.api';
import LoaderSpinner from '../../shared/LoaderSpinner/LoaderSpinner';

const duration = { hide: 100, show: 200 };

const VehiclesSearch = (props) => {
  const [visible, setVisible] = useState(false);
  const [isDataSearchRequest, setDataSearchRequest] = useState(false);
  const formik = useFormik({
    initialValues: {
      make: '',
      model: '',
      class: '',
      year: '',
      color: '',
    },
    onSubmit: async (values) => {
      await searchHandle(values);
    },
  });

  const onSearchVisibleClickHandle = () => {
    formik.resetForm();
    setVisible(!visible);
  };

  const onSearchClickHandle = (e) => {
    e.preventDefault();
    formik.submitForm();
  };

  function onCloseHandle(e) {
    e.preventDefault();
    onSearchVisibleClickHandle();
    props.clearSearchResults();
  }

  const searchHandle = (formData) => {
    setDataSearchRequest(true);
    vehiclesApi
      .searchVehicles(formData)
      .then((res) => {
        props.onSearchCallback(res.data);
      })
      .finally(() => {
        setDataSearchRequest(false);
      });
  };

  return (
    <div className={styles.VehicelesSerch}>
      <LoaderSpinner isLoading={isDataSearchRequest} />
      <Transition visible={visible} duration={duration} animation="slide down">
        <Form className={styles.form} onSubmit={formik.handleSubmit}>
          <Form.Input
            name="make"
            placeholder={i18next.t('Make')}
            value={formik.values.make}
            onChange={formik.handleChange}
          />
          <Form.Input
            name="model"
            placeholder={i18next.t('Model')}
            value={formik.values.model}
            onChange={formik.handleChange}
          />
          <Form.Group widths="equal">
            <Form.Select
              fluid
              name="class"
              options={carClass}
              placeholder={i18next.t('Class')}
              onChange={(e, result) =>
                formik.setFieldValue(result.name, result.value)
              }
              value={formik.values.class}
            />
            <Form.Input
              name="year"
              fluid
              placeholder={i18next.t('Year')}
              value={formik.values.year}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="color"
              fluid
              placeholder={i18next.t('color')}
              value={formik.values.color}
              onChange={formik.handleChange}
            />
            <Button as="div" labelPosition="right">
              <Button primary onClick={onSearchClickHandle}>
                {i18next.t('Search')}
              </Button>
              <Button
                negative
                icon
                className={styles.iconButton}
                onClick={onCloseHandle}
              >
                <Icon name="close" />
              </Button>
            </Button>
          </Form.Group>
          <Divider />
        </Form>
      </Transition>
      <Button
        className={cn(styles.searchButton, {
          [styles.isButtonVisible]: visible,
        })}
        animated="fade"
        onClick={onSearchVisibleClickHandle}
      >
        <Button.Content visible>Search</Button.Content>
        <Button.Content hidden>Search by params</Button.Content>
      </Button>
    </div>
  );
};

VehiclesSearch.propTypes = {
  onSearchCallback: PropTypes.func.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
};

export default VehiclesSearch;
