import { useEffect, useState } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function ReleaseDialog(props) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props.is_new_release);
  }, [props.is_new_release]);

  const onClose = () => {
    props.onMakeSeen();
    console.log('fff');
    setOpen(false);
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Modal.Header>New in release</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>Version: {props.version}</Header>
          <div
            dangerouslySetInnerHTML={{ __html: props.new_release_description }}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Yes, good info!"
          labelPosition="right"
          icon="checkmark"
          onClick={onClose}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

ReleaseDialog.propTypes = {
  is_new_release: PropTypes.bool,
  new_release_description: PropTypes.string.isRequired,
  version: PropTypes.string,
};

export default ReleaseDialog;
