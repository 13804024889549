import { Model } from './Model.js';

export class Vehicle extends Model {
  constructor(props) {
    super(props);
    this.images = this.getImages();
  }

  get defaults() {
    return {
      id: undefined,
      stock_number: '',
      reg_number: '',
      vin: '',
      make: '',
      model: '',
      year: '',
      color: '',
      vclass: -1,
      max_weight: '',
      transmission: 0,
      description: '',
      engine: '',
      type: -1,
      date_sold: '',
      rate_d1: '',
      rate_d2: '',
      rate_d3: '',
      rate_d4: '',
      rate_d5: '',
      rate_d6: '',
      rate_weekly: '',
      rate_monthly: '',
      rate_hourly: '',
      rate_week_extra: '',
      rate_month_extra: '',
      discount_day_rate: '',
      odometer_out: '',
      fuel_out: '',
      vehicle_license: '',
      vehicle_state: '',
      createdAt: '',
      updatedAt: '',
      files: [],
      expenses: [],
      gallery: [],
      images: {
        preview: '',
        full: '',
      },
    };
  }

  getImages() {
    try {
      const images = this.files ? JSON.parse(this.files) : {};

      // eslint-disable-next-line no-prototype-builtins
      if (!images.hasOwnProperty('preview')) images.preview = '';
      // eslint-disable-next-line no-prototype-builtins
      if (!images.hasOwnProperty('full')) images.full = '';

      delete this.files;
      return images;
    } catch (e) {
      return '';
    }
  }
}
