import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Header } from 'semantic-ui-react';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          'Network error, check network and server availability':
            'Network error, check network and server availability',
          'Internal server error': 'Internal server error',
          General: 'General',
          Rates: 'Rates',
          Media: 'Media',
          'Vehicle Expenses': 'Vehicle Expenses',
          'New Agreement': 'New Agreement',
          'Quick Lookup': 'Quick Lookup',
          'Rent Planner': 'Rent Planner',
          Vehicles: 'Vehicles',
          Invoices: 'Invoices',
          Clients: 'Clients',
          Agents: 'Agents',
          'Traffic Fines': 'Traffic Fines',
          Reports: 'Reports',
          Settings: 'Settings',
          'Search client...': 'Search client...',
          Logout: 'Logout',
          'Welcome {UserName}, ': 'Welcome {UserName}, ',
          'Add Vehicle': 'Add Vehicle',
          Edit: 'Edit',
          Delete: 'Delete',
          'Search reservation...': 'Search reservation...',
          'Stock Number': 'Stock Number',
          'Reg No': 'Reg No',
          Vin: 'Vin',
          Make: 'Make',
          Model: 'Model',
          Year: 'Year',
          Color: 'Color',
          Class: 'Class',
          'Max weight': 'Max weight',
          Transmission: 'Transmission',
          Description: 'Description',
          Engine: 'Engine',
          Type: 'Type',
          'Date sold/release': 'Date sold/release',
          Load: 'Load',
          Clear: 'Clear',
          Save: 'Save',
          Add: 'Add',
          'Reservation/Agreement': 'Reservation/Agreement',
          'Agreement #': 'Agreement #',
          'Reservation #': 'Reservation #',
          'Customer name': 'Customer name',
          'License Plate #': 'License Plate #',
          Mastercard: 'Mastercard',
          'American Express': 'American Express',
          'Dinners Club': 'Dinners Club',
          'Discovery Club': 'Discovery Club',
          'Kompa Leon': 'Kompa Leon',
          Compact: 'Compact',
          Convertible: 'Convertible',
          Economy: 'Economy',
          Fullsize: 'Fullsize',
          Midsize: 'Midsize',
          Pickup: 'Pickup',
          SUV: 'SUV',
          'Select option': 'Select option',
          'Enter text': 'Enter text',
          'Vehicle search': 'Vehicle search',
          'Enter license Plate #': 'Enter license Plate #',
          'Vehicle class': 'Vehicle class',
          'Select vehicle class': 'Select vehicle class',
          'Select date from to': 'Select date from to',
          'Date From - To': 'Date From - To',
          'Rent occupation': 'Rent occupation',
          'Quick lookup': 'Quick lookup',
          "Today's reservations": "Today's reservations",
          'Vehicles in fleet': 'Vehicles in fleet',
          'On rent': 'On rent',
          'Available in fleet': 'Available in fleet',
          'Pending arrivals': 'Pending arrivals',
          'Upcoming rentals': 'Upcoming rentals',
          Visa: 'Visa',
          'Reservation/Agreement search': 'Reservation/Agreement search',
          Remove: 'Remove',
          Agreement: 'Agreement',
          Invoice: 'Invoice',
          'Find available vehicles': 'Find available vehicles',
          'Select Vehicle': 'Select Vehicle',
          Cancel: 'Cancel',
          License: 'License',
          Rate: 'Rate',
          Create: 'Create',
          'Expense type': 'Expense type',
          'Select expense type': 'Select expense type',
          'Max weigh': 'Max weigh',
          '1 day': '1 day',
          '2 day': '2 day',
          '3 day': '3 day',
          '4 day': '4 day',
          '5 day': '5 day',
          '6 day': '6 day',
          Weekly: 'Weekly',
          Monthly: 'Monthly',
          'Hourly rate': 'Hourly rate',
          'Week extra day': 'Week extra day',
          'Month extra day': 'Month extra day',
          'Discount day rate': 'Discount day rate',
          Date: 'Date',
          Amount: 'Amount',
          Details: 'Details',
          Notes: 'Notes',
          'Add new expenses': 'Add new expenses',
          Users: 'Users',
          'Company details': 'Company details',
          'Owner name': 'Owner name',
          'VAT/Tax ID number': 'VAT/Tax ID number',
          Address: 'Address',
          'Address-2': 'Address-2',
          City: 'City',
          State: 'State',
          County: 'County',
          'Phone number': 'Phone number',
          'Fax number': 'Fax number',
          Email: 'Email',
          'Web page': 'Web page',
          'Add user': 'Add user',
          'Company name': 'Company name',
          'Zip/Post code': 'Zip/Post code',
          'Add new user': 'Add new user',
          'User name': 'User name',
          'New agreement': 'New agreement',
          'Renter details': 'Renter details',
          'Home address': 'Home address',
          'Passport ID': 'Passport ID',
          'Rental details': 'Rental details',
          'Fuel&Milage': 'Fuel&Milage',
          'Driving license #': 'Driving license #',
          'Place od Birth': 'Place od Birth',
          'Agreement date': 'Agreement date',
          'Start date': 'Start date',
          'End date': 'End date',
          'Pick location': 'Pick location',
          'Pick instructions': 'Pick instructions',
          'Drop location': 'Drop location',
          'Deposit method': 'Deposit method',
          'Deposit amount': 'Deposit amount',
          'Contract #': 'Contract #',
          'Delivered by': 'Delivered by',
          'Edit expenses': 'Edit expenses',
          'No results': 'No results found',
          'Search vehicles...': 'Search vehicles...',
          Yes: 'Yes',
          No: 'No',
          'Delete item': 'Delete item',
          'Vehicle success deleted': 'Vehicle success deleted',
          'Error delete vehicle, try again later':
            'Error delete vehicle, try again later',
          'Load vehicle error. Try again later':
            'Load vehicle error. Try again later',
          'Vehicle list loading error': 'Vehicle list loading error',
          'Error add vehicle': 'Error add vehicle',
          'Vehicle successfully added': 'Vehicle successfully added',
          'Vehicle successfully updated': 'Vehicle successfully updated',
          'Error vehicle update': 'Error vehicle update',
          'Error load vehicle expenses': 'Error load vehicle expenses',
          'Expenses successfully updated': 'Expenses successfully updated',
          'Error expenses update': 'Error expenses update',
          'Expenses successfully added': 'Expenses successfully added',
          'Error add expenses': 'Error add expenses',
          'Expenses success deleted': 'Expenses success deleted',
          'Error delete expenses, try again later':
            'Error delete expenses, try again later',
          'Image uploading error': 'Image uploading error',
          'Client details': 'Client details',
          'Contact info': 'Contact info',
          'Payment info': 'Payment info',
          Other: 'Other',
          Insurance: 'Insurance',
          Documents: 'Documents',
          'Family name': 'Family name',
          'First name': 'First name',
          'Birth date': 'Birth date',
          'Place of birth': 'Place of birth',
          'Zip code': 'Zip code',
          Country: 'Country',
          'Local address': 'Local address',
          'Passport ID #': 'Passport ID #',
          'Home phone': 'Home phone',
          'Work phone': 'Work phone',
          'Local phone': 'Local phone',
          'Cell phone': 'Cell phone',
          'Card type': 'Card type',
          'Credit card #': 'Credit card #',
          'Name of card': 'Name of card',
          'Expiration date': 'Expiration date',
          CVV: 'CVV',
          'Driving License': 'Driving License',
          'Dr. license category': 'Dr. license category',
          'Dr. license exp. date': 'Dr. license exp. date',
          'Insurance company': 'Insurance company',
          'Phone #': 'Phone #',
          'Fax #': 'Fax #',
          'Police number': 'Police number',
          'Insurance Exp. date': 'Insurance Exp. date',
          'Document 1': 'Document 1',
          'Clients list loading error': 'Clients list loading error',
          'Date/Time from': 'Date/Time from',
          'Date/Time to': 'Date/Time to',
          Previous: 'Previous',
          Next: 'Next',
          'Fuel In': 'Fuel In',
          'Fuel Out': 'Fuel Out',
          'Vehicle successful rented': 'Vehicle successful rented',
          'Agreement number': 'Agreement number',
          Renter: 'Renter',
          Vehicle: 'Vehicle',
          'The first driver': 'The first driver',
          'Additional driver': 'Additional driver',
          Photo: 'Photo',
          'Rent invoice': 'Rent invoice',
          Warning: 'Warning',
          'Rental agreement': 'Rental agreement',
          Signature: 'Signature',
          'Personal information': 'Personal information',
          'Card No': 'Card No',
          'State of Issuance': 'State of Issuance',
          Validity: 'Validity',
          'Security code': 'Security code',
          'Date/time in': 'Date/time in',
          'Date/time out': 'Date/time out',
          'Odometer out': 'Odometer out',
          'Minimal cost': 'Minimal cost',
          'Do you want load default warnings and terms':
            'Do you want load default warnings and terms',
          Trash: 'Trash',
          Upload: 'Upload',
          'No driver': 'No driver',
          'General information': 'General information',
          'Wait for upload': 'Wait for upload',
          'Field is required': 'Field is required',
        },
      },
      ru: {
        translations: {
          'Network error, check network and server availability':
            'Ошибка сети, проверьте доступность сервера',
          'Internal server error': 'Внутренняя ошика сервера',
          Agreement: 'Соглашение',
          'New Agreement': 'Новое соглашение',
          'Quick Lookup': 'Быстрый поиск',
          'Rent Planner': 'Планировщик',
          Vehicles: 'Транспорт',
          Invoices: 'Счета',
          Clients: 'Клиенты',
          Agents: 'Агенты',
          'Traffic Fines': 'Штрафы',
          Reports: 'Отчеты',
          Settings: 'Настройки',
          'No results': 'Нет результатов',
          Yes: 'Да',
          No: 'Нет',
          'Delete item': 'Удалить элемент',
          'The first driver': 'Основной водитель',
          'Additional driver': 'Дополнительный водитель',
          Vehicle: 'Автомобиль',
          Photo: 'Фото',
          'Rent invoice': 'Инвойс',
          Warning: 'Замечания',
          'Rental agreement': 'Соглашение',
          Signature: 'Подпись',
          Trash: 'Корзина',
          Upload: 'Загрузить',
          'No driver': 'Нет водителя',
          'Field is required': 'Обязательное поле',
        },
      },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export default i18n;
