import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Form, Image, Placeholder } from 'semantic-ui-react';
import i18next from 'i18next';
import { Img } from 'react-image';
import AppConfig from '../../../config';

function CardImage(props) {
  const {
    isEditMode,
    imageUrl,
    onImageLoad,
    onImageClear,
    onPreview,
    isHasImage,
    isUploadImageState,
    defaultImage,
  } = props;
  const [urlImage, setUrlImage] = useState('');

  useEffect(() => {
    if (isUploadImageState) setUrlImage(URL.createObjectURL(imageUrl));
  }, [isUploadImageState, imageUrl]);

  const renderImage = () => {
    const unloaderImage = defaultImage || AppConfig.defaultClientImage;
    let imageUrlSrc = '';

    if (imageUrl === AppConfig.defaultClientImage && defaultImage)
      imageUrlSrc = AppConfig.serverRoot + defaultImage;
    else imageUrlSrc = AppConfig.serverRoot + imageUrl;
    return (
      <Image
        size="medium"
        as={() => (
          <Img
            onClick={onPreview}
            src={[imageUrlSrc]}
            loader={
              <Placeholder>
                <Placeholder.Image square />
              </Placeholder>
            }
            unloader={<img src={unloaderImage} />}
          />
        )}
      />
    );
  };

  const renderUploadImage = () => (
    // const imageUrlLink = URL.createObjectURL(imageUrl);
    <Image
      size="medium"
      as={() => <img src={urlImage} onClick={onPreview} />}
    />
  );

  const renderEditMode = () => (
    <>
      <Form.Button positive onClick={onImageLoad}>
        {i18next.t('Load')}
      </Form.Button>
      <Form.Button
        className={classNames({ 'form-group-disable': !isHasImage })}
        negative
        onClick={onImageClear}
      >
        {i18next.t('Clear')}
      </Form.Button>
    </>
  );

  return (
    <div className="load-image-wrapper">
      <div className={classNames('ui active centered')} />
      <div className="image-wrapper">
        {isUploadImageState ? renderUploadImage() : renderImage()}
        {isEditMode && (
          <div className="image-load-buttons-wrapper">{renderEditMode()}</div>
        )}
      </div>
    </div>
  );
}

CardImage.defaultProps = {
  isEditMode: false,
  isHasImage: false,
  imageUrl: AppConfig.defaultClientImage,
  defaultImage: '',
};

CardImage.propTypes = {
  isEditMode: PropTypes.bool,
  isHasImage: PropTypes.bool,
  isUploadImageState: PropTypes.bool,
  imageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onImageLoad: PropTypes.func.isRequired,
  onImageClear: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  defaultImage: PropTypes.string,
};

export default CardImage;
