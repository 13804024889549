import Axios from 'axios-observable';
import Cookies from 'universal-cookie';
import isEmpty from 'lodash/isEmpty';
import AppConfig from '../../config';

export default class BaseRequestModel {
  constructor(url, method, headers, body, auth) {
    this.url = url;
    this.method = method || 'GET';
    this.auth = auth;
    this.apiUrl = AppConfig.apiUrl;
    this.headers = this.setHeaders(headers);
    this.body = body || {};
  }

  setHeaders = (headers) => {
    let resultHeaders = headers || {};
    if (this.auth) {
      const cookies = new Cookies();
      const token = cookies.get('token');
      resultHeaders = { ...headers, Authorization: token };
    }
    return resultHeaders;
  };

  request() {
    const requestConfig = {
      method: this.method,
      headers: this.headers,
      withCredentials: false,
      url: `${this.apiUrl}${this.url}`,
      ...(isEmpty(this.body) ? {} : { data: this.body }),
    };
    return Axios.request({
      ...requestConfig,
    });
  }
}
