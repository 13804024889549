import {
  AGREEMENT_NEED_UPDATE,
  ALL_AGREEMENTS_SET_TAB_INDEX,
  LOAD_ALL_AGREEMENTS_LIST,
} from '../actions/types';

const initState = {
  allAgreementsList: [],
  isNeedUpdate: false,
  activeTabIndex: 0,
};

export default function allAgreements(state = initState, action) {
  switch (action.type) {
    case LOAD_ALL_AGREEMENTS_LIST:
      return {
        ...state,
        isNeedUpdate: false,
        allAgreementsList: action.payload,
      };
    case AGREEMENT_NEED_UPDATE:
      return {
        ...state,
        isNeedUpdate: true,
      };
    case ALL_AGREEMENTS_SET_TAB_INDEX:
      return {
        ...state,
        activeTabIndex: action.payload,
      };
    default:
      return state;
  }
}
