import axios from 'axios';
import i18next from 'i18next';
import AppConfig from '../config';
import { tokenConfig } from './user/auth';
import { Settings } from '../common/models/Settings';
import { SET_DATA_LOADING, showStatusMessage } from './UiActions';
import { resizeImage } from '../services/utils';
import {
  SETTINGS_DEFAULT_AGREEMENT_INFO,
  SETTINGS_LOGO_CLEAR,
  SETTINGS_GET_SETTINGS,
  SETTINGS_UPDATE_SETTINGS,
  SETTINGS_UPLOAD_LOGO,
  SHOW_STATUS_MESSAGE,
} from './types';
import {
  errorMessageStatus,
  statusMessages,
  successMessageStatus,
} from '../common/catalogs/constants';

export const getSettingsData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_DATA_LOADING,
      payload: true,
    });
    const res = await axios.get(
      `${AppConfig.apiUrl}/setup/getsettings`,
      tokenConfig(getState)
    );
    dispatch({
      type: SETTINGS_GET_SETTINGS,
      payload: new Settings(res.data),
    });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
  }
};

export const updateSettingsData =
  (updateFields) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_DATA_LOADING,
        payload: true,
      });
      const res = await axios.patch(
        `${AppConfig.apiUrl}/setup/updatesettings`,
        updateFields,
        tokenConfig(getState)
      );
      dispatch({
        type: SETTINGS_UPDATE_SETTINGS,
        payload: new Settings(res.data),
      });
      dispatch(
        showStatusMessage(
          successMessageStatus,
          i18next.t(statusMessages.setupSaveSuccess)
        )
      );
    } catch (err) {
      dispatch(
        showStatusMessage(
          successMessageStatus,
          i18next.t(statusMessages.setupSaveError)
        )
      );
      console.log(err);
    } finally {
      dispatch({
        type: SET_DATA_LOADING,
        payload: false,
      });
    }
  };

export const loadDefaultAgreementInfo = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_DATA_LOADING,
      payload: true,
    });
    const res = await axios.get(
      `${AppConfig.apiUrl}/setup/getdefaultagreementsinfo`,
      tokenConfig(getState)
    );

    dispatch({
      type: SETTINGS_DEFAULT_AGREEMENT_INFO,
      payload: res.data,
    });
    dispatch(
      showStatusMessage(
        successMessageStatus,
        i18next.t(statusMessages.setupLoadDefaultAgreementsSuccess)
      )
    );
  } catch (e) {
    dispatch(
      showStatusMessage(
        errorMessageStatus,
        i18next.t(statusMessages.setupLoadDefaultAgreementsInfoError)
      )
    );
    console.log(e);
  } finally {
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
  }
};

export const uploadLogo = (image) => async (dispatch, getState) => {
  try {
    const data = new FormData();
    const resizedImage = await resizeImage(image, 300, 300);
    data.append('file', resizedImage);

    const res = await axios.put(
      `${AppConfig.apiUrl}/setup/uploadlogo`,
      data,
      tokenConfig(getState)
    );

    dispatch({
      type: SETTINGS_UPLOAD_LOGO,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: SHOW_STATUS_MESSAGE,
      payload: {
        messageType: errorMessageStatus,
        statusMessage: i18next.t(statusMessages.setupLogoUploadError),
      },
    });
    console.log(e);
  }
};

export const logoClear = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${AppConfig.apiUrl}/setup/clearlogo`,
      tokenConfig(getState)
    );

    dispatch({
      type: SETTINGS_LOGO_CLEAR,
      payload: res.data,
    });
  } catch (e) {
    dispatch(
      showStatusMessage(
        errorMessageStatus,
        i18next.t(statusMessages.setupClearLogoError)
      )
    );
    console.log(e);
  }
};
