import React, { Component } from 'react';
import { Icon, Menu, Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import moment from 'moment';
import { depositMethod } from '../../catalogs/constants';
import { logoutUser, setReleaseInfo } from '../../../actions/user/auth';
import { addAddagreement } from '../../../services/agreements';
import AppConfig from '../../../config';
import {
  agreementsFormInitState,
  agreementValidStepStatus,
} from '../../catalogs/initstates';
import { generateAgreement, page2Text } from '../../../services/generatePDF';
import {
  openAgreementDocument,
  showNewAgreementForm,
} from '../../../actions/agreement';
import ReleaseDialog from '../ReleaseDialog';

import style from './MainMenu.module.scss';
import UserItem from './UserItem/UserItem';

Modal.setAppElement('#root');

class MainMenu extends Component {
  // handleItemClick = (e, {name}) => {
  // i18next.changeLanguage('en');
  // };
  constructor(props) {
    super(props);
    this.state = {
      stepsValidStatus: agreementValidStepStatus,
      currentTime: '',
      showModal: false,
      vehiclesList: [],
      clientsList: [],
      activeVehicleId: -1,
      isDataLoading: false,
      newAgreementFormCompleted: false,
      client_id: -1,
      activeTab: 0,
      signImageDataURL: '',
      formControls: {
        vehicle_class: 0,
        dt_start: '',
        dt_end: '',
      },
      formControlsAgreement: agreementsFormInitState,
      formControlsAgreementTouched: {},
      agreement_warnings: '',
      agreement_terms: '',
      galleryUrls: [],
      vehicleImages: '',
      media_url: '',
      mainVehicleImage: '',
    };
    this.previewRef = React.createRef();
    this.isDevMode = process.env.NODE_ENV === 'development';
  }

  componentDidMount() {
    this.setState({
      formControlsAgreement: { ...agreementsFormInitState, client_name: '' },
    });
    // this.getVehiclesListData();
  }

  createNewAgreement = () => {
    const { dt_start } = this.state.formControlsAgreement;
    const { dt_end } = this.state.formControlsAgreement;
    const vehicle_id = this.state.vehiclesList[this.state.activeVehicleId].id;
    const { client_id } = this.state;

    addAddagreement({
      dt_start,
      dt_end,
      vehicle_id,
      client_id,
      agreement_start_number: AppConfig.agreement_start_number,
    })
      .then((result) => {
        this.setState({
          agreement_number: result.data.data.agreement_number,
          newAgreementFormCompleted: true,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  generatePDFAgreementDocument = (agreementAction) => {
    const { agreement_number } = this.state;
    const {
      family_name,
      first_name,
      dt_start,
      dt_end,
      home_address,
      mobile_phone,
      city,
      state,
      zip_code,
      driver_license,
      driver_license_exp_date,
      birth_date,
      insurience_police_number,
      insurience_exp_date,
      add_driver_name,
      add_driver_home_address,
      add_driver_birthdate,
      add_phone_number,
      add_driver_city,
      add_driver_state,
      add_driver_zip,
      add_driver_lic_no,
      add_driver_ins,
      add_driver_ins_date,
      pick_location,
      pick_instruction,
      drop_location,
      drop_instruction,
      deposit_method,
      card_number,
      name_of_card,
      security_code,
      deposit_amount,
      full_payment,
      rent_days,
      rent_month,
      rent_ticket,
      rent_chargeable_mils,
      rent_full_cost,
      fuel_in,
      fuel_out,
    } = this.state.formControlsAgreement;
    const isCashDeposit = depositMethod[deposit_method].key === 0;

    const docObject = {
      settings: this.props.settings.generalSettings,
      client_name: `${first_name} ${family_name}`,
      dt_start,
      dt_end,
      home_address,
      mobile_phone,
      city,
      state,
      zip_code,
      driver_license,
      driver_license_exp_date: moment(driver_license_exp_date).format(
        'YYYY-MM-DD'
      ),
      birth_date: moment(birth_date).format('YYYY-MM-DD'),
      insurience_police_number,
      insurience_exp_date: moment(insurience_exp_date).format('YYYY-MM-DD'),
      // Second driver
      add_driver_name: add_driver_name || '-',
      add_driver_home_address: add_driver_home_address || '-',
      add_driver_birthdate: add_driver_birthdate || '-',
      add_phone_number: add_phone_number || '-',
      add_driver_city: add_driver_city || '-',
      add_driver_state: add_driver_state || '-',
      add_driver_zip: add_driver_zip || '-',
      add_driver_lic_no: add_driver_lic_no || '-',
      add_driver_ins: add_driver_ins || '-',
      add_driver_ins_date: add_driver_ins_date || '',
      // Location
      pick_location: pick_location || '-',
      drop_location: drop_location || '-',
      pick_instruction: pick_instruction || '-',
      drop_instruction: drop_instruction || '-',
      // Payment info
      deposit_method: depositMethod[deposit_method].text || '-',
      card_number: !isCashDeposit ? card_number : '-',
      name_of_card: !isCashDeposit ? name_of_card : '-',
      security_code: !isCashDeposit ? security_code : '-',
      deposit: !isCashDeposit ? deposit_amount : '-',
      full_payment: !isCashDeposit ? full_payment : '-',
      // Vehicle
      vin: this.state.formControlsAgreement.vin,
      stock_number: this.state.formControlsAgreement.stock_number,
      make: this.state.formControlsAgreement.make,
      model: this.state.formControlsAgreement.model,
      year: this.state.formControlsAgreement.year.toString(),
      vehicle_odometer_in: this.state.formControlsAgreement.vehicle_odometer_in,
      vehicle_free_miles: this.state.formControlsAgreement.vehicle_free_miles,
      signImageDataURL: this.state.signImageDataURL,
      fuel_in: fuel_out || '',
      fuel_out: '',
      rent_days,
      rent_month,
      rent_ticket,
      rent_chargeable_mils,
      rent_full_cost,
    };
    generateAgreement(docObject, agreement_number, agreementAction);
  };

  //
  handleVehicleClassSelectChange = (e, result) => {
    const { name } = result;
    const { value } = result;
    console.log(name, value, this.state.formControls);
    this.setState((prevState, props) => {
      return { formControls: { ...prevState.formControls, [name]: value } };
    });
  };

  handleInputChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState(
      (prevState, props) => {
        return {
          formControlsAgreement: {
            ...prevState.formControlsAgreement,
            [name]: value,
          },
        };
      },
      () => this.stepValidStatusHandler(this.state.activeTab + 1, false, false)
    );
  };

  handleDaysCountInputChange = (e) => {
    const { rate_d1 } = this.state.formControlsAgreement;
    const { value } = e.target;
    this.setState((prevState, props) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          rent_days: value,
          rent_days_total: value * rate_d1,
          rent_full_cost: this.calculateFullRentCost({
            totalDays: value * rate_d1,
          }),
        },
      };
    });
  };

  handleDropBoxChange = (event, data) => {
    const { name } = data;
    const { value } = data;
    this.setState((prevState, props) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          [name]: value,
        },
      };
    });
  };

  handleSelectChange = (e, result) => {
    const { name } = result;
    const { value } = result;
    this.setState((prevState, props) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          [name]: value,
        },
      };
    });

    if (name === 'client_name' && value !== '') {
      // setTimeout(() => this.onLoadFirstDriverInfo(result.options[value].id), 1000);
    } else if (name === 'client_name' && value === '') {
      this.setState(
        (prevState, props) => {
          return {
            formControlsAgreement: {
              ...prevState.formControlsAgreement,
              client_name: '',
              family_name: '',
              first_name: '',
              birth_date: '',
              home_address: '',
              zip_code: '',
              city: '',
              state: '',
              country: '',
              local_address: '',
              passport_id: '',
              mobile_phone: '',
              driver_license_exp_date: '',
              driver_license: '',
            },
            client_id: -1,
          };
        },
        () => this.stepValidStatusHandler(1, false, false)
      );
    }
    if (name === 'add_driver_name' && value !== '') {
      //   setTimeout(() => this.onLoadAdditionalDriverInfo(result.options[value].id), 1000);
    }
    if (name === 'add_driver_name' && value === '') {
      this.setState(
        (prevState, props) => {
          return {
            formControlsAgreement: {
              add_driver_name: undefined,
              ...prevState.formControlsAgreement,
              add_driver_home_address: '',
              add_driver_birthdate: '',
              add_phone_number: '',
              add_driver_city: '',
              add_driver_state: '',
              add_driver_zip: '',
              add_driver_lic_no: '',
              add_driver_ins: '',
              add_driver_ins_date: '',
            },
          };
        },
        () => this.stepValidStatusHandler(2, false, false)
      );
    }
  };

  handleCal2Change = (event, { name, value }) => {
    this.setState((prevState, props) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          [name]: value,
        },
      };
    });
  };

  handleDateTimeChange = (value, name) => {
    this.setState(
      (prevState, props) => {
        return {
          vehiclesList: [],
          activeVehicleId: -1,
          formControls: { ...prevState.formControls, [name]: value },
        };
      },
      () => {
        if (name === 'dt_start') {
          this.setMinRateTime(value);
        }
      }
    );
  };

  //
  stepValidStatusHandler = (step, validState, invalidState) => {
    this.setState((prevState) => {
      return {
        stepsValidStatus: {
          ...prevState.stepsValidStatus,
          [step]: [validState, invalidState],
        },
      };
    });
  };

  getFuelOutButtonActive = (fuelOut) => {
    return fuelOut === this.state.formControlsAgreement.fuel_out;
  };
  //

  onOpenNewAgreement = () => {
    this.props.showNewAgreementForm(true);
  };

  _renderMainMenu = () => {
    const { history, location } = this.props;
    return (
      <Menu className="MainMenu" icon="labeled" stackable>
        <Menu.Item name="new_agreement" onClick={this.onOpenNewAgreement}>
          <Icon name="file text" />
          {i18next.t('New Agreement')}
        </Menu.Item>
        <Menu.Item
          name="all_agreements"
          active={location.pathname === '/allagreements'}
          onClick={() => {
            history.push('/allagreements');
          }}
        >
          <Icon name="archive" />
          {i18next.t('All Agreements')}
        </Menu.Item>
        <Menu.Item
          name="quick_lookup"
          active={location.pathname === '/lookup'}
          onClick={() => {
            history.push('/lookup');
          }}
        >
          <Icon name="search" />
          {i18next.t('Quick Lookup')}
        </Menu.Item>
        <Menu.Item
          name="rent_planner"
          active={location.pathname === '/planner'}
          onClick={() => {
            history.push('/planner');
          }}
        >
          <Icon name="calendar" />
          {i18next.t('Rent Planner')}
        </Menu.Item>
        <Menu.Item
          name="vehicles"
          active={location.pathname === '/vehicles'}
          onClick={() => {
            history.push('/vehicles');
          }}
        >
          <Icon name="car" />
          {i18next.t('Vehicles')}
        </Menu.Item>
        {/* <Menu.Item */}
        {/*    name='invoices' */}
        {/*    active={location.pathname === '/invoices'} */}
        {/*    onClick={() => { */}
        {/*        history.push('/invoices'); */}
        {/*    }}> */}
        {/*    <Icon name='clipboard'/> */}
        {/*    {i18next.t("Invoices")} */}
        {/* </Menu.Item> */}
        <Menu.Item
          name="clients"
          active={location.pathname === '/clients'}
          onClick={() => {
            history.push('/clients');
          }}
        >
          <Icon name="users" />
          {i18next.t('Clients')}
        </Menu.Item>
        {AppConfig.navigation.testBtn && (
          <Menu.Item
            name="clients"
            active={location.pathname === '/agg'}
            onClick={() => {
              this.props.openAgreementDocument();
            }}
          >
            <Icon name="settings" />
            Test PDF
          </Menu.Item>
        )}
        {/* <Menu.Item */}
        {/*    name='agents' */}
        {/*    active={location.pathname === '/agents'} */}
        {/*    onClick={() => { */}
        {/*        history.push('/agents'); */}
        {/*    }} */}
        {/* > */}
        {/*    <Icon name='spy'/> */}
        {/*    {i18next.t("Agents")} */}
        {/* </Menu.Item> */}
        {/* <Menu.Item */}
        {/*    name='traffic_fines' */}
        {/*    active={location.pathname === '/fines'} */}
        {/*    onClick={() => { */}
        {/*        history.push('/fines'); */}
        {/*    }} */}
        {/* > */}
        {/*    <Icon name='usd'/> */}
        {/*    {i18next.t("Traffic Fines")} */}
        {/* </Menu.Item> */}
        {/* <Menu.Item */}
        {/*    name='reports' */}
        {/*    active={location.pathname === '/reports'} */}
        {/*    onClick={() => { */}
        {/*        history.push('/reports'); */}
        {/*    }}> */}
        {/*    <Icon name='chart pie'/> */}
        {/*    {i18next.t("Reports")} */}
        {/* </Menu.Item> */}
        <Menu.Item
          name="settings"
          active={location.pathname === '/settings'}
          onClick={() => {
            history.push('/settings');
          }}
        >
          <Icon name="settings" />
          {i18next.t('Settings')}
        </Menu.Item>
        <Menu.Item
          name="help"
          active={location.pathname === '/'}
          onClick={() => {
            history.push('/');
          }}
        >
          <Icon name="help" />
          {i18next.t('Help')}
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <UserItem />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  };

  render() {
    if (
      this.props.location.pathname.toLowerCase() === '/auth' ||
      this.props.location.pathname.includes('signagreement')
    )
      return null;

    return (
      <>
        <Grid className="top-nav-bar">
          <Grid.Row columns={2} only="tablet computer">
            <Grid.Column>{this.state.currentTime}</Grid.Column>
          </Grid.Row>
        </Grid>
        {this._renderMainMenu()}
        {this.props.is_new_release && !this.isDevMode && (
          <ReleaseDialog
            is_new_release={this.props.is_new_release}
            new_release_description={this.props.new_release_description}
            version={this.props.version}
            onMakeSeen={this.props.setReleaseInfo}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    name: currentUser,
    new_release_description,
    is_new_release,
    version,
  } = state.auth;
  const { settings } = state;

  return {
    currentUser,
    settings,
    is_new_release,
    new_release_description,
    version,
  };
};

const mapDispatchToProps = {
  logoutUser,
  showNewAgreementForm,
  setReleaseInfo,
  openAgreementDocument,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainMenu)
);
