import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

class MainPage extends Component {
  render() {
    return <Redirect to="/allagreements" />;
  }
}

export default withRouter(MainPage);
