import Api from '../classes/Api';

export const getRentOccupation = (data) => {
  const api = new Api();
  return api.post('quicklookup/getrentoccupation', data);
};

export const getToadyReservation = () => {
  const api = new Api();
  return api.get('quicklookup/gettoadyreservation');
};
