import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import Modal from 'react-modal';
import i18next from 'i18next';
import moment from 'moment';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  List,
  Segment,
} from 'semantic-ui-react';
import SignatureCanvas from 'react-signature-canvas';
import DateTimePicker from 'react-datetime-picker';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Agreement } from '../../models/Agreement';
import AppConfig from '../../../config';
import {
  agreementValidStepStatus,
  vehicleSearchFilter,
} from '../../catalogs/initstates';
import {
  additionalDriver,
  carClass,
  depositMethod,
  firstDriver,
  fuelVolumes,
} from '../../catalogs/constants';
import {
  agreementFormSetStep,
  hideAgreementForm,
  loadDriversList,
  loadDriverInfo,
  resetSearchState,
  searchFreeVehicles,
  getWAInfo,
  applyAgreement,
  checkAgreementStatus,
  agreementSingByEmail,
  onSetAgreementStatusToCancel,
  openAgreementDocument,
} from '../../../actions/agreement';
import SearchResultsTable from './layouts/SearchResultTable';
import { Driver } from '../../models/Driver';

import { AddDriver } from '../../models/AddDriver';
import SignAgreementByEmail from './layouts/SignAgreementByEmail';
import Tags from '../shared/Tags/Tags';
import PopupFilter from '../PopupFilter/PopupFilter';
import { searchVehicleFilter } from '../../models/SearchVehicleFilter';

const dialogTitles = [
  i18next.t('Find available vehicles'),
  i18next.t('New agreement'),
];

// tabs
const RENT_LOCATION = 5;

class NewAgreement extends Component {
  sigPad = {};

  constructor(props) {
    super(props);
    this.state = {
      isVehicleLoading: false,
      isSignMode: false,
      activeVehicleId: -1,
      mainDriverId: '',
      addDriverId: '',
      stepsValidStatus: agreementValidStepStatus,
      newAgreementFormCompleted: false,
      formControls: {
        vehicle_class: 0,
        dt_start: '',
        dt_end: '',
      },
      formControlsAgreement: {},
      formControlsAgreementTouched: {},
      signImageDataURL: '',
      terms: '',
      warnings: '',
      vehicleFilterData: [],
    };
  }

  componentDidMount() {
    const filterData = [];
    AppConfig.searchVehicleFilter.forEach((item) => {
      filterData.push(
        // eslint-disable-next-line new-cap
        new searchVehicleFilter({ ...vehicleSearchFilter, name: item })
      );
    });
    this.setState({ vehicleFilterData: filterData });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.showNewAgreementForm !== this.props.showNewAgreementForm &&
      this.props.showNewAgreementForm
    ) {
      this.props.loadDriversList();
      this.onInitNewAgreement();
    } else if (
      prevProps.showNewAgreementForm !== this.props.showNewAgreementForm &&
      !this.props.showNewAgreementForm
    ) {
      this.props.resetSearchState();
      this.setState({ activeTab: 0 });
    }

    if (
      prevProps.agreementStep !== this.props.agreementStep &&
      prevProps.agreementStep === 9
    )
      this.checkSign(true);

    if (
      prevState.formControlsAgreement.first_name !==
      this.state.formControlsAgreement.first_name
    ) {
      this.setState((prevState) => {
        return {
          formControlsAgreement: {
            ...prevState.formControlsAgreement,
            deposit_method: this.state.formControlsAgreement.card_type,
            name_of_card: `${this.state.formControlsAgreement.first_name} ${this.state.formControlsAgreement.family_name}`,
          },
        };
      });
    }
  }

  onInitNewAgreement = () => {
    this.setState({
      stepsValidStatus: agreementValidStepStatus,
      formControlsAgreement: new Agreement(),
      newAgreementFormCompleted: false,
      signImageDataURL: null,
      activeVehicleId: -1,
      mainDriverId: '',
      addDriverId: '',
      isSignMode: false,
    });
    this.setCurrDate();
    this.setMinRateTime();
    setTimeout(this.onLoadWarningAndAgreement, 3000);
  };

  setCurrDate = () => {
    const next5Minutes = moment().add(5, 'minutes');
    next5Minutes.minutes(Math.floor(next5Minutes.minutes() / 20) * 20);
    this.setState((prevState) => {
      return {
        formControls: {
          ...prevState.formControls,
          dt_start: new Date(next5Minutes.format('YYYY-MM-DDTHH:mm')),
        },
      };
    });
  };

  setMinRateTime = (upDate = undefined) => {
    const { minRateTime } = AppConfig;
    let minRateTimeResults;
    if (upDate) minRateTimeResults = moment(upDate).add(minRateTime, 'minutes');
    else minRateTimeResults = moment().add(minRateTime, 'minutes');
    minRateTimeResults.minutes(
      Math.floor(minRateTimeResults.minutes() / 10) * 10
    );
    this.setState((prevState) => {
      return {
        formControls: {
          ...prevState.formControls,
          dt_end: new Date(minRateTimeResults.format('YYYY-MM-DDTHH:mm')),
        },
      };
    });
  };

  onLoadWarningAndAgreement = async () => {
    const termsAgreement = await this.props.getWAInfo();
    this.setState({ ...termsAgreement });
  };

  onFuelOutClick = (e, data) => {
    const { name } = data;
    this.setState((prevState) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          fuel_out: name,
        },
      };
    });
  };

  handleSelectChange = (e, result) => {
    const { name } = result;
    const { value } = result;
    this.setState((prevState, props) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          [name]: value,
        },
      };
    });

    if (name === 'client_name' && value !== '') {
      setTimeout(
        () =>
          this.props
            .loadDriverInfo({ driverType: firstDriver, driverId: value })
            .then(() => {
              this.setState(
                (prevState, props) => {
                  return {
                    formControlsAgreement: {
                      ...prevState.formControlsAgreement,
                      ...this.props.agreementDrivers.firstDriver,
                    },
                    mainDriverId: value,
                  };
                },
                () => this.isNextStepPossible(this.props.agreementStep)
              );
            }),
        1000
      );
    } else if (name === 'client_name' && value === '') {
      this.setState(
        (prevState, props) => {
          return {
            mainDriverId: '',
            formControlsAgreement: {
              ...prevState.formControlsAgreement,
              ...new Driver(),
            },
          };
        },
        () => this.isNextStepPossible(this.props.agreementStep)
      );
    }

    if (name === 'add_driver_name' && value !== '') {
      setTimeout(
        () =>
          this.props
            .loadDriverInfo({ driverType: additionalDriver, driverId: value })
            .then(() => {
              this.setState(
                (prevState) => {
                  return {
                    formControlsAgreement: {
                      ...prevState.formControlsAgreement,
                      ...this.props.agreementDrivers.additionalDriver,
                    },
                    addDriverId: value,
                  };
                },
                () => this.isNextStepPossible(this.props.agreementStep)
              );
            }),
        1000
      );
    } else if (name === 'add_driver_name' && value === '') {
      this.setState(
        (prevState, props) => {
          return {
            addDriverId: '',
            formControlsAgreement: {
              ...prevState.formControlsAgreement,
              ...new AddDriver(),
            },
          };
        },
        () => this.isNextStepPossible(this.props.agreementStep)
      );
    }
  };

  handleVehicleClassSelectChange = (e, result) => {
    const { name } = result;
    const { value } = result;
    this.setState((prevState, props) => {
      return { formControls: { ...prevState.formControls, [name]: value } };
    });
  };

  handleDateTimeChange = (value, name) => {
    this.setState(
      (prevState, props) => {
        return {
          activeVehicleId: -1,
          formControls: { ...prevState.formControls, [name]: value },
        };
      },
      () => {
        if (name === 'dt_start') {
          this.setMinRateTime(value);
        }
      }
    );
  };

  handleDaysCountInputChange = (e) => {
    const { rate_d1 } = this.state.formControlsAgreement;
    const { value } = e.target;
    this.setState((prevState, props) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          rent_days: value,
          rent_days_total: value * rate_d1,
          rent_full_cost: this.calculateFullRentCost({
            totalDays: value * rate_d1,
          }),
        },
      };
    });
  };

  handleMonthCountInputChange = (e) => {
    const { rate_monthly } = this.state.formControlsAgreement;
    const value = Number(e.target.value);
    const rent_month_total = Number(value) * Number(rate_monthly);

    this.setState((prevState, props) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          rent_month: value,
          rent_month_total,
          rent_full_cost: this.calculateFullRentCost({
            totalMonth: value * rate_monthly,
          }),
        },
      };
    });
  };

  handleMilesInputChange = (e, { name }) => {
    const value = Number(e.target.value);

    this.setState(
      (prevState, props) => {
        return {
          formControlsAgreement: {
            ...prevState.formControlsAgreement,
            [name]: value,
          },
        };
      },
      () => this.handleTotalMilesUpdate()
    );
  };

  handleTotalMilesUpdate = () => {
    const { rent_chargeable_mils, rent_chargeable_mils_cost } =
      this.state.formControlsAgreement;
    const totalMils =
      Number(rent_chargeable_mils_cost) * Number(rent_chargeable_mils);

    this.setState((prevState, props) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          rent_chargeable_mils_total: totalMils,
          rent_full_cost: this.calculateFullRentCost({ totalMils }),
        },
      };
    });
  };

  handleInputChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState(
      (prevState) => {
        return {
          formControlsAgreement: {
            ...prevState.formControlsAgreement,
            [name]: value,
          },
        };
      },
      () => this.isNextStepPossible(this.props.agreementStep)
    );
  };

  handleDropBoxChange = (event, data) => {
    const { name } = data;
    const { value } = data;
    this.setState((prevState) => {
      return {
        formControlsAgreement: {
          ...prevState.formControlsAgreement,
          [name]: value,
        },
      };
    });
  };

  onEditSign = () => {
    this.setState({ signImageDataURL: '' });
    this.stepValidStatusHandler(this.props.agreementStep, false, false);
  };

  calculateFullRentCost = ({
    totalDays = this.state.formControlsAgreement.rent_days_total,
    totalMonth = this.state.formControlsAgreement.rent_month_total,
    totalMils = this.state.formControlsAgreement.rent_chargeable_mils_total,
  }) => {
    let month = parseFloat(totalMonth);
    let day = parseFloat(totalDays);
    let mils = parseFloat(totalMils);
    month = isNaN(month) ? 0 : month;
    day = isNaN(day) ? 0 : day;
    mils = isNaN(mils) ? 0 : totalMils;
    return day + month + mils;
  };

  onNextStep = async () => {
    let currentStep = this.props.agreementStep;
    const isStepPossible = await this.isNextStepPossible(currentStep);
    if (currentStep >= 1 && currentStep <= 9 && isStepPossible) {
      if (currentStep === 9) this.checkAgreement();
      else {
        currentStep++;
        this.props.agreementFormSetStep(currentStep);
      }
    }
  };

  isNextStepPossible = async (currentStep) => {
    switch (currentStep) {
      case 1:
        return this.checkDriversStep(currentStep);
      case 2:
        return this.checkDriversStep(currentStep);
      case 3:
        this.stepValidStatusHandler(currentStep, true, false);
        return true;
      case 4:
        this.stepValidStatusHandler(currentStep, true, false);
        return true;
      case 5:
        return this.checkCurrentTabFields(currentStep);
      case 6:
        this.stepValidStatusHandler(currentStep, true, false);
        return true;
      case 7:
      case 8:
        this.stepValidStatusHandler(currentStep, true, false);
        return true;
      case 9:
        return await this.checkSign();
      default:
        break;
    }
  };

  stepValidStatusHandler = (step, validState, invalidState) => {
    this.setState((prevState) => {
      return {
        stepsValidStatus: {
          ...prevState.stepsValidStatus,
          [step]: [validState, invalidState],
        },
      };
    });
  };

  checkDriversStep = (stepNumber) => {
    let stepValidStatus = false;
    let stepInvalidStatus = true;

    if (stepNumber === 1) {
      if (this.state.mainDriverId) {
        stepValidStatus = true;
        stepInvalidStatus = false;
      }
    } else if (stepNumber !== 1) {
      stepValidStatus = true;
      stepInvalidStatus = false;
    }
    this.stepValidStatusHandler(stepNumber, stepValidStatus, stepInvalidStatus);
    return !stepInvalidStatus;
  };

  checkSign = async (saveData) => {
    if (this.state.signImageDataURL) return true;

    if (!this.sigPad) return;

    const isEmpty = this.sigPad.isEmpty();

    if (saveData && !isEmpty) {
      this.setState({ signImageDataURL: this.sigPad.toDataURL('PNG') });
      return;
    }

    if (!isEmpty) {
      this.setState({ signImageDataURL: this.sigPad.toDataURL('PNG') }, () =>
        this.stepValidStatusHandler(this.props.agreementStep, true, false)
      );
    } else this.stepValidStatusHandler(this.props.agreementStep, false, true);

    return !isEmpty;
  };

  checkReqField = (fieldName, tabNum) => {
    const { newAgreementForm } = AppConfig;
    if (newAgreementForm[tabNum])
      return !(
        newAgreementForm[tabNum].indexOf(fieldName) > -1 &&
        this.state.formControlsAgreement[fieldName] === ''
      );
  };

  checkCurrentTabFields = (tabNum) => {
    const { newAgreementForm } = AppConfig;
    const touched = {};
    const isValidFields = [];
    for (let i = 0; i < newAgreementForm[tabNum].length; i++) {
      touched[newAgreementForm[tabNum][i]] = true;
      isValidFields.push(
        this.checkReqField(newAgreementForm[tabNum][i], tabNum)
      );
    }

    const isValid = isValidFields.every((item) => item === true);
    this.setState({ formControlsAgreementTouched: touched }, () =>
      this.stepValidStatusHandler(tabNum, isValid, !isValid)
    );
    return isValid;
  };

  checkAgreement = () => {
    const invalidTabIndex = Object.keys(this.state.stepsValidStatus).find(
      (key, index) => {
        return this.state.stepsValidStatus[key][0] === false && index !== 1;
      }
    );

    if (invalidTabIndex > -1) {
      return this.props.agreementFormSetStep(invalidTabIndex);
    }
    this.onApplyAgreement();
  };

  onApplyAgreement = () => {
    this.props
      .applyAgreement(
        new Agreement({
          ...this.state.formControls,
          ...this.state.formControlsAgreement,
        })
      )
      .then(() => {
        this.setState({ newAgreementFormCompleted: true });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleBlur = (name) => {
    this.setState({
      formControlsAgreementTouched: {
        ...this.state.formControlsAgreementTouched,
        [name]: true,
      },
    });
  };

  getStepValidStatus = (stepNumber) => {
    return this.state.stepsValidStatus[stepNumber];
  };

  getFuelOutButtonActive = (fuelOut) => {
    return fuelOut === this.state.formControlsAgreement.fuel_out;
  };

  onErrorDisplay = (fieldName, tabNum) => {
    // eslint-disable-next-line no-prototype-builtins
    return (
      !this.checkReqField(fieldName, tabNum) &&
      this.state.formControlsAgreementTouched.hasOwnProperty(fieldName)
    );
  };

  onVehicleSearch = (fromFilter = false) => {
    const { vehicle_class, dt_start, dt_end } = this.state.formControls;
    const searchParams = Object.assign({ vehicle_class, dt_start, dt_end });
    if (fromFilter) {
      const { vehicleFilterData } = this.state;
      vehicleFilterData.forEach((item) => {
        if (item.toggle)
          searchParams[String(item.name).toLowerCase()] = item.value;
      });
    }
    this.setState({ isVehicleLoading: true });
    console.log(searchParams);
    this.props
      .searchFreeVehicles({ ...searchParams })
      .then(() => {
        this.setState((prevState) => {
          return {
            isVehicleLoading: false,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.setState({ isVehicleLoading: false });
      });
  };

  onCancelCreatedAgreement = () => {
    this.props.onSetAgreementStatusToCancel(this.props.newAgreementData.id);
    this.props.hideAgreementForm();
  };

  onSearchVehicleSelected = (index) => {
    if (index < 0) return;
    const vehicle = cloneDeep(this.props.freeVehicles[index]);
    delete vehicle.id;
    const newAgreement = assign(
      {},
      vehicle,
      this.state.formControls,
      { sign: this.state.signImageDataURL },
      { vehicle_odometer_in: this.props.freeVehicles[index]?.odometer_out },
      { vehicleId: this.props.freeVehicles[index].id }
    );
    this.setState((prevState) => {
      return {
        ...prevState,
        activeVehicleId: index,
        formControlsAgreement: new Agreement(newAgreement),
      };
    });
  };

  searchFilterApply = (filterData) => {
    this.setState({ vehicleFilterData: cloneDeep(filterData) }, () => {
      setTimeout(() => this.onVehicleSearch(true), AppConfig.serverActionDelay);
    });
  };

  removeVehicleFilterParam = (paramIndex) => {
    const filterData = cloneDeep(this.state.vehicleFilterData);

    filterData.forEach((item, index) => {
      if (index === paramIndex) {
        item.toggle = false;
        item.value = [];
      }
    });

    this.setState({ vehicleFilterData: filterData }, () => {
      setTimeout(() => this.onVehicleSearch(true), AppConfig.serverActionDelay);
    });
  };

  renderMainDriver() {
    const { driversList } = this.props;
    const {
      formControlsAgreement: {
        home_address,
        city,
        state,
        zip_code,
        mobile_phone,
        birth_date,
        driver_license,
        driver_license_exp_date,
        add_driver_name,
      },
      mainDriverId,
      addDriverId,
    } = this.state;
    return (
      <TabPanel>
        <Form>
          <Form.Group widths="equal">
            <div className="field">
              <label>{i18next.t('Name')}</label>
              <Dropdown
                options={driversList.map((item) => {
                  return {
                    ...item,
                    disabled: addDriverId === item.value,
                  };
                })}
                placeholder="Name"
                name="client_name"
                search
                selection
                fluid
                clearable
                value={mainDriverId}
                searchInput={{ autoComplete: 'new-password' }}
                onChange={this.handleSelectChange}
                error={this.getStepValidStatus(1)[1]}
              />
            </div>
            <Form.Input
              label={i18next.t('Home address')}
              name="home_address"
              placeholder={i18next.t('Home address')}
              value={home_address}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label={i18next.t('Birthdate')}
              name="birth_date"
              placeholder={i18next.t('Birthdate')}
              value={birth_date}
              onChange={this.handleInputChange}
            />
            <Form.Input
              label={i18next.t('Mobile phone')}
              name="mobile_phone"
              placeholder={i18next.t('Mobile phone')}
              value={mobile_phone}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label={i18next.t('City')}
              name="city"
              placeholder={i18next.t('City')}
              value={city}
              onChange={this.handleInputChange}
            />
            <Form.Input
              label={i18next.t('State')}
              name="state"
              placeholder={i18next.t('State')}
              value={state}
              onChange={this.handleInputChange}
            />
            <Form.Input
              label={i18next.t('Zip code')}
              name="zip_code"
              placeholder={i18next.t('Zip code')}
              value={zip_code}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="driver_license"
              label={i18next.t('License No')}
              placeholder={i18next.t('License No')}
              value={driver_license}
            />
            <Form.Input
              name="driver_license_exp_date"
              label={i18next.t('State of issuance')}
              placeholder={i18next.t('State of issuance')}
              value={driver_license_exp_date}
            />
          </Form.Group>
        </Form>
      </TabPanel>
    );
  }

  renderAdditionalDriver() {
    const { driversList } = this.props;
    const {
      formControlsAgreement: {
        add_driver_name,
        add_driver_home_address,
        add_driver_birthdate,
        add_phone_number,
        add_driver_city,
        add_driver_state,
        add_driver_zip,
        add_driver_license,
        add_driver_license_exp_date,
      },
      mainDriverId,
      addDriverId,
    } = this.state;
    const isFieldDisable = add_driver_name ? 0 : 1;

    return (
      <TabPanel>
        <Form>
          <Form.Group widths="equal">
            <div className="field">
              <label>{i18next.t('Name')}</label>
              <Dropdown
                name="add_driver_name"
                className={classNames({ 'no-driver': !add_driver_name })}
                options={driversList.map((item) => {
                  return {
                    ...item,
                    disabled: mainDriverId === item.value,
                  };
                })}
                placeholder={i18next.t('No driver')}
                search
                selection
                fluid
                clearable
                searchInput={{ autoComplete: 'new-password' }}
                value={addDriverId}
                onChange={this.handleSelectChange}
              />
            </div>
            <Form.Input
              className={classNames({ 'input-disable': isFieldDisable })}
              name="add_driver_home_address"
              label={i18next.t('Home address')}
              placeholder={i18next.t('Home address')}
              value={add_driver_home_address}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              className={classNames({ 'input-disable': isFieldDisable })}
              name="add_driver_birthdate"
              label={i18next.t('Birthdate')}
              placeholder={i18next.t('Birthdate')}
              value={add_driver_birthdate}
            />
            <Form.Input
              className={classNames({ 'input-disable': isFieldDisable })}
              label={i18next.t('Mobile phone')}
              placeholder={i18next.t('Mobile phone')}
              value={add_phone_number}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              className={classNames({ 'input-disable': isFieldDisable })}
              name="add_driver_city"
              label={i18next.t('City')}
              placeholder={i18next.t('City')}
              value={add_driver_city}
            />
            <Form.Input
              className={classNames({ 'input-disable': isFieldDisable })}
              label={i18next.t('State')}
              placeholder={i18next.t('State')}
              value={add_driver_state}
            />
            <Form.Input
              className={classNames({ 'input-disable': isFieldDisable })}
              label={i18next.t('Zip code')}
              placeholder={i18next.t('Zip code')}
              value={add_driver_zip}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              className={classNames({ 'input-disable': isFieldDisable })}
              label={i18next.t('License No')}
              placeholder={i18next.t('License No')}
              value={add_driver_license}
            />
            <Form.Input
              className={classNames({ 'input-disable': isFieldDisable })}
              label={i18next.t('State of issuance')}
              placeholder={i18next.t('State of issuance')}
              value={add_driver_license_exp_date}
            />
          </Form.Group>
        </Form>
      </TabPanel>
    );
  }

  renderVehicle() {
    const {
      vin,
      stock_number,
      make,
      model,
      year,
      vehicle_free_miles,
      vehicle_odometer_in,
      vehicle,
      dt_start,
      dt_end,
      vehicle_license,
      vehicle_state,
    } = this.state.formControlsAgreement;
    return (
      <TabPanel>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              name="vehicle_name"
              label={i18next.t('Vehicle')}
              placeholder={i18next.t('Vehicle')}
              value={vehicle}
            />
            <Form.Input
              name="dt_start"
              label={i18next.t('Date/time in')}
              placeholder={i18next.t('Date/time in')}
              value={dt_start}
            />
            <Form.Input
              name="dt_end"
              label={i18next.t('Date/time out')}
              placeholder={i18next.t('Date/time out')}
              value={dt_end}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="vin"
              label={i18next.t('Vin')}
              placeholder={i18next.t('Vin')}
              value={vin}
            />
            <Form.Input
              name="vehicle_state"
              label={i18next.t('State')}
              placeholder={i18next.t('State')}
              value={vehicle_state}
            />
            <Form.Input
              name="vehicle_license"
              label={i18next.t('License')}
              placeholder={i18next.t('License')}
              value={vehicle_license}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="make"
              label={i18next.t('Make')}
              placeholder={i18next.t('Make')}
              value={make}
            />
            <Form.Input
              name="model"
              label={i18next.t('Model')}
              placeholder={i18next.t('Model')}
              value={model}
            />
            <Form.Input
              name="year"
              label={i18next.t('Year')}
              placeholder={i18next.t('Year')}
              value={year}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="vehicle_odometer_in"
              label={i18next.t('Odometer in')}
              placeholder={i18next.t('Odometer in')}
              value={vehicle_odometer_in}
              onChange={this.handleInputChange}
            />
            <Form.Input
              name="vehicle_free_miles"
              label={i18next.t('Free miles')}
              placeholder={i18next.t('Free miles')}
              value={vehicle_free_miles}
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <label className="form-label">Fuel In</label>
          <Form.Group>
            <Button.Group className="agreement_fuel_in">
              <Button
                name={fuelVolumes.out_E}
                onClick={this.onFuelOutClick}
                active={this.getFuelOutButtonActive(fuelVolumes.out_E)}
              >
                {fuelVolumes.out_E}
              </Button>
              <Button
                name={fuelVolumes.out_1_8}
                onClick={this.onFuelOutClick}
                active={this.getFuelOutButtonActive(fuelVolumes.out_1_8)}
              >
                {fuelVolumes.out_1_8}
              </Button>
              <Button
                name={fuelVolumes.out_1_4}
                onClick={this.onFuelOutClick}
                active={this.getFuelOutButtonActive(fuelVolumes.out_1_4)}
              >
                {fuelVolumes.out_1_4}
              </Button>
              <Button
                name={fuelVolumes.out_3_8}
                onClick={this.onFuelOutClick}
                active={this.getFuelOutButtonActive(fuelVolumes.out_3_8)}
              >
                {fuelVolumes.out_3_8}
              </Button>
              <Button
                name={fuelVolumes.out_5_8}
                onClick={this.onFuelOutClick}
                active={this.getFuelOutButtonActive(fuelVolumes.out_5_8)}
              >
                {fuelVolumes.out_5_8}
              </Button>
              <Button
                name={fuelVolumes.out_3_4}
                onClick={this.onFuelOutClick}
                active={this.getFuelOutButtonActive(fuelVolumes.out_3_4)}
              >
                {fuelVolumes.out_3_4}
              </Button>
              <Button
                name={fuelVolumes.out_7_8}
                onClick={this.onFuelOutClick}
                active={this.getFuelOutButtonActive(fuelVolumes.out_7_8)}
              >
                {fuelVolumes.out_7_8}
              </Button>
              <Button
                name={fuelVolumes.out_F}
                onClick={this.onFuelOutClick}
                active={this.getFuelOutButtonActive(fuelVolumes.out_F)}
              >
                {fuelVolumes.out_F}
              </Button>
            </Button.Group>
          </Form.Group>
        </Form>
      </TabPanel>
    );
  }

  renderRentInvoice() {
    const {
      rent_days,
      rate_d1,
      rent_days_total,
      rent_month,
      rate_monthly,
      rent_month_total,
      rent_ticket,
      rent_ticket_cost,
      rent_ticket_total,
      rent_chargeable_mils,
      rent_chargeable_mils_total,
      rent_chargeable_mils_cost,
      rent_full_cost,
    } = this.state.formControlsAgreement;
    const { currency } = this.props;

    return (
      <TabPanel>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              className="inline inline-field-with-column"
              label={i18next.t('Days')}
              name="rent_days"
              placeholder={i18next.t('Days')}
              value={rent_days}
              type="number"
              min={0}
              onChange={this.handleDaysCountInputChange}
            />
            <Form.Input
              name="rate_d1"
              label={i18next.t('Cost')}
              placeholder={i18next.t('Days cost')}
              value={rate_d1}
              min={0}
              width={10}
            />
            <Form.Input
              name="rent_days_total"
              label={i18next.t('Total')}
              placeholder={i18next.t('Days total')}
              value={rent_days_total}
              min={0}
              width={10}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              className="inline inline-field-with-column no-padding"
              label={i18next.t('Month')}
              name="rent_month"
              placeholder={i18next.t('Month')}
              type="number"
              onChange={this.handleMonthCountInputChange}
              min={0}
              value={rent_month}
            />
            <Form.Input
              name="rate_monthly"
              placeholder={i18next.t('Month cost')}
              value={rate_monthly}
              width={10}
            />
            <Form.Input
              name="rent_month_total"
              placeholder={i18next.t('Month total')}
              value={rent_month_total}
              width={10}
            />
          </Form.Group>
          <Form.Group widths="equal" className="form-group-disable">
            <Form.Input
              name="rent_ticket"
              className="inline inline-field-with-column no-padding"
              label={i18next.t('Ticket')}
              placeholder={i18next.t('Ticket')}
              value={rent_ticket}
              min={0}
              onChange={this.handleInputChange}
            />
            <Form.Input
              name="rent_ticket_cost"
              placeholder={i18next.t('Ticket cost')}
              value={rent_ticket_cost}
              onChange={this.handleInputChange}
              min={0}
              width={10}
            />
            <Form.Input
              name="rent_ticket_total"
              placeholder={i18next.t('Ticket total')}
              value={rent_ticket_total}
              width={10}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="rent_chargeable_mils"
              className="inline inline-field-with-column  no-padding"
              label={i18next.t('Chargeable mils')}
              placeholder={i18next.t('Chargeable mils')}
              value={rent_chargeable_mils}
              onChange={this.handleMilesInputChange}
            />
            <Form.Input
              name="rent_chargeable_mils_cost"
              placeholder={i18next.t('Chargeable mils cost')}
              value={rent_chargeable_mils_cost}
              onChange={this.handleMilesInputChange}
              width={10}
            />
            <Form.Input
              name="rent_chargeable_mils_total"
              placeholder={i18next.t('Chargeable mils total')}
              value={rent_chargeable_mils_total}
              width={10}
            />
          </Form.Group>
          <Divider />
          <div className="full-cost-wrapper form-group-disable">
            <h4 className="form-header">{i18next.t('Full cost')}</h4>
            <div className="full-cost">{`${rent_full_cost}${currency}`}</div>
          </div>
        </Form>
      </TabPanel>
    );
  }

  renderRentLocation() {
    const { pick_location, drop_location, pick_instruction, drop_instruction } =
      this.state.formControlsAgreement;
    return (
      <TabPanel>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              className={classNames({
                error: this.onErrorDisplay('pick_location', RENT_LOCATION),
              })}
              label={i18next.t('Pick location')}
              name="pick_location"
              placeholder={i18next.t('Pick location')}
              value={pick_location}
              onChange={this.handleInputChange}
              onBlur={() => this.handleBlur('pick_location')}
            />
            <Form.Input
              className={classNames({
                error: this.onErrorDisplay('pick_instruction', RENT_LOCATION),
              })}
              label={i18next.t('Pick instruction')}
              name="pick_instruction"
              placeholder={i18next.t('Pick instruction')}
              value={pick_instruction}
              onChange={this.handleInputChange}
              onBlur={() => this.handleBlur('pick_instruction')}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              className={classNames({
                error: this.onErrorDisplay('drop_location', RENT_LOCATION),
              })}
              label={i18next.t('Drop location')}
              placeholder={i18next.t('Drop location')}
              name="drop_location"
              value={drop_location}
              onChange={this.handleInputChange}
              onBlur={() => this.handleBlur('drop_location')}
            />
            <Form.Input
              className={classNames({
                error: this.onErrorDisplay('drop_instruction', RENT_LOCATION),
              })}
              label={i18next.t('Drop instruction')}
              placeholder={i18next.t('Drop instruction')}
              name="drop_instruction"
              value={drop_instruction}
              onChange={this.handleInputChange}
              onBlur={() => this.handleBlur('drop_instructions')}
            />
          </Form.Group>
        </Form>
      </TabPanel>
    );
  }

  renderRentPaymentInfo() {
    const {
      deposit_method,
      card_number,
      deposit_amount,
      name_of_card,
      security_code,
      full_payment,
    } = this.state.formControlsAgreement;
    return (
      <TabPanel>
        <Form>
          <Form.Group widths="equal">
            <Form.Select
              fluid
              label={i18next.t('Payment type')}
              options={depositMethod}
              placeholder={i18next.t('Payment type')}
              name="deposit_method"
              value={deposit_method}
              onChange={this.handleDropBoxChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label={i18next.t('Card number')}
              placeholder={i18next.t('Card number')}
              name="card_number"
              value={card_number}
              // onChange={this.handleInputChange}
              // children={
              //     <InputMask
              //         mask="9999-9999-9999-9999"
              //     />}
              disabled={deposit_method === 0}
            />
            <Form.Input
              label={i18next.t('Name of card')}
              placeholder={i18next.t('Name of card')}
              name="name_of_card"
              value={name_of_card}
              onChange={this.handleInputChange}
            />
            <Form.Input
              label={i18next.t('Security code')}
              placeholder={i18next.t('Security code')}
              name="security_code"
              value={security_code}
              onChange={this.handleInputChange}
              children={
                <InputMask mask="999" maskChar="_" alwaysShowMask="false" />
              }
              disabled={deposit_method === 0}
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label={i18next.t('Deposit amount')}
              placeholder={i18next.t('Deposit amount')}
              name="deposit_amount"
              value={deposit_amount}
              onChange={this.handleInputChange}
            />
          </Form.Group>
        </Form>
      </TabPanel>
    );
  }

  renderWarning() {
    return (
      <TabPanel>
        <Form>
          <Form.Group widths="equal">
            <Form.TextArea
              name="rental_agreement_warnings"
              className="rental_agreement_text"
              label={i18next.t('Warnings')}
              value={this.state.warnings}
            />
          </Form.Group>
        </Form>
      </TabPanel>
    );
  }

  renderRentalAgreementTerms() {
    return (
      <TabPanel>
        <Form>
          <Form.Group widths="equal">
            <Form.TextArea
              name="rental_agreement_terms"
              className="rental_agreement_text"
              label={i18next.t('Rental agreement')}
              value={this.state.terms}
            />
          </Form.Group>
        </Form>
      </TabPanel>
    );
  }

  renderSignAgreement = () => {
    const { signImageDataURL } = this.state;

    return (
      <TabPanel>
        {!signImageDataURL ? (
          <SignatureCanvas
            penColor="blue"
            canvasProps={{ width: 500, height: 500, className: 'sigCanvas' }}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        ) : (
          <>
            <Button icon="edit" onClick={this.onEditSign} />
            <img src={signImageDataURL} />
          </>
        )}
      </TabPanel>
    );
  };

  renderNewAgreementForm = () => {
    return (
      <React.Fragment>
        <Segment className="segment-form">
          <Tabs
            selectedIndex={this.props.agreementStep - 1}
            onSelect={(e) => this.props.agreementFormSetStep(e + 1)}
          >
            <TabList>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(1)[0] },
                  { isTabInvalid: this.getStepValidStatus(1)[1] }
                )}
              >
                {i18next.t('First driver')}
              </Tab>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(2)[0] },
                  { isTabInvalid: this.getStepValidStatus(2)[1] }
                )}
              >
                {i18next.t('Add. driver')}
              </Tab>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(3)[0] },
                  { isTabInvalid: this.getStepValidStatus(3)[1] }
                )}
              >
                {i18next.t('Vehicle')}
              </Tab>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(4)[0] },
                  { isTabInvalid: this.getStepValidStatus(4)[1] }
                )}
              >
                {i18next.t('Rent inv.')}
              </Tab>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(5)[0] },
                  { isTabInvalid: this.getStepValidStatus(5)[1] }
                )}
              >
                {i18next.t('Location')}
              </Tab>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(6)[0] },
                  { isTabInvalid: this.getStepValidStatus(6)[1] }
                )}
              >
                {i18next.t('Payment info')}
              </Tab>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(7)[0] },
                  { isTabInvalid: this.getStepValidStatus(7)[1] }
                )}
              >
                {i18next.t('Warning')}
              </Tab>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(8)[0] },
                  { isTabInvalid: this.getStepValidStatus(8)[1] }
                )}
              >
                {i18next.t('Agreement')}
              </Tab>
              <Tab
                className={classNames(
                  'react-tabs__tab',
                  { isTabValid: this.getStepValidStatus(9)[0] },
                  { isTabInvalid: this.getStepValidStatus(9)[1] }
                )}
              >
                {i18next.t('Signature')}
              </Tab>
            </TabList>
            <Segment>
              {this.renderMainDriver()}
              {this.renderAdditionalDriver()}
              {this.renderVehicle()}
              {this.renderRentInvoice()}
              {this.renderRentLocation()}
              {this.renderRentPaymentInfo()}
              {this.renderWarning()}
              {this.renderRentalAgreementTerms()}
              {this.renderSignAgreement()}
            </Segment>
          </Tabs>
        </Segment>
      </React.Fragment>
    );
  };

  renderCompleted = () => {
    const { agreement_number, dt_start, dt_end } = this.state.formControls;
    const { family_name, first_name, make, model, year, email } =
      this.state.formControlsAgreement;
    const { isSignMode } = this.state;
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>{i18next.t('Vehicle successful rented')}</Card.Header>
        </Card.Content>
        <Card.Content>
          <Card.Description>
            <Header size="small">{i18next.t('Agreement number')}</Header>
            <strong>{agreement_number}</strong>
            <Header size="small">{i18next.t('Renter')}</Header>
            <List>
              <List.Item>
                <List.Icon name="user" />
                <List.Content>{`${family_name} ${first_name}`}</List.Content>
              </List.Item>
            </List>
            <Header size="small">{i18next.t('Vehicle')}</Header>
            <List>
              <List.Item>
                <List.Icon name="car" />
                <List.Content>{`${make} ${model} / ${year}`}</List.Content>
              </List.Item>
            </List>
            <Header size="small">{i18next.t('Details')}</Header>
            <List>
              <List.Item>
                <List.Icon name="calendar" />
                <List.Content>
                  <strong>From: </strong>
                  {moment(dt_start).format('YYYY-MM-DD HH:mm') || ''}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="calendar" />
                <List.Content>
                  <strong>To: </strong>
                  {moment(dt_end).format('YYYY-MM-DD HH:mm') || ''}
                </List.Content>
              </List.Item>
            </List>
            <SignAgreementByEmail
              uid={this.props.newAgreementData.id}
              email={email}
              onSendSingEmail={this.props.agreementSingByEmail}
              checkAgreementStatus={this.props.checkAgreementStatus}
              closeForm={this.props.hideAgreementForm}
              onSetAgreementStatusToCancel={
                this.props.onSetAgreementStatusToCancel
              }
            />
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <div className="ui four buttons">
            <Button primary onClick={() => this.props.openAgreementDocument()}>
              <Icon name="eye" />
              {i18next.t('Open')}
            </Button>
            <Button color="red" onClick={this.onCancelCreatedAgreement}>
              <Icon name="close" />
              Cancel
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  };

  renderFindVehicle = () => {
    const { freeVehicles } = this.props;
    const {
      activeVehicleId,
      formControls: { vehicle_class, dt_start, dt_end },
      vehicleFilterData,
    } = this.state;

    return (
      <React.Fragment>
        <Form className="attached fluid segment">
          <Form.Group widths="equal">
            <Form.Select
              fluid
              name="vehicle_class"
              label={i18next.t('Vehicle class')}
              options={carClass}
              placeholder={i18next.t('Select vehicle class')}
              onChange={this.handleVehicleClassSelectChange}
              value={vehicle_class}
            />
            <div className="cal-wrapper">
              <label className="form-label">Date/Time from</label>
              <DateTimePicker
                format="MM/dd/yyyy HH:mm"
                label={i18next.t('Date/Time from')}
                onChange={(value) =>
                  this.handleDateTimeChange(value, 'dt_start')
                }
                minDate={new Date()}
                value={dt_start}
                className="agreement-calendar"
                calendarIcon={<Icon name="calendar alternate outline" />}
              />
            </div>
            <div className="to_label">{i18next.t('to')}</div>
            <div className="cal-wrapper">
              <label className="form-label">Date/Time to</label>
              <DateTimePicker
                format="MM/dd/yyyy HH:mm"
                label={i18next.t('Date/Time from')}
                onChange={(value) => this.handleDateTimeChange(value, 'dt_end')}
                minDate={dt_start}
                value={dt_end}
                className="agreement-calendar"
                calendarIcon={<Icon name="calendar alternate outline" />}
              />
            </div>
          </Form.Group>
          <Button
            icon="search"
            labelPosition="left"
            content="Search"
            onClick={this.onVehicleSearch}
            primary
          />
          <PopupFilter
            filterData={vehicleFilterData}
            onFilterApply={this.searchFilterApply}
          />
          <Tags
            activeFilters={vehicleFilterData}
            removeFilter={this.removeVehicleFilterParam}
          />
        </Form>
        {freeVehicles.length > 0 && (
          <SearchResultsTable
            vehicles={freeVehicles}
            onSetActiveVehicle={(index) => this.onSearchVehicleSelected(index)}
            activeVehicleId={activeVehicleId}
          />
        )}
      </React.Fragment>
    );
  };

  renderSubmitButton = (btnType) => {
    const buttonProps = {
      onClick: () => {},
      disabled: false,
      label: '',
    };
    switch (btnType) {
      case 'create':
        buttonProps.label = 'Create';
        break;
      case 'next':
        buttonProps.label = 'Next';
        buttonProps.onClick = () => this.onNextStep();
        break;
      case 'select':
        buttonProps.disabled = this.state.activeVehicleId === -1;
        buttonProps.onClick = () => this.props.agreementFormSetStep(1);
        buttonProps.label = 'Select Vehicle';
        break;
      default:
        break;
    }
    return (
      <Button
        positive
        disabled={buttonProps.disabled}
        onClick={buttonProps.onClick}
      >
        {i18next.t(buttonProps.label)}
      </Button>
    );
  };

  renderDialogButtons = () => {
    const { agreementStep } = this.props;
    const buttonType =
      agreementStep > 0 && agreementStep < 10
        ? 'next'
        : agreementStep === 0
        ? 'select'
        : 'create';

    return (
      <Card.Content extra>
        <div className="ui two buttons">
          <Button negative onClick={this.props.hideAgreementForm}>
            {i18next.t('Cancel')}
          </Button>
          {this.renderSubmitButton(buttonType)}
        </div>
      </Card.Content>
    );
  };

  renderNewAgreementModal = () => {
    const { showNewAgreementForm, agreementStep, isLocalDataLoader } =
      this.props;
    const { newAgreementFormCompleted, agreement_number, isVehicleLoading } =
      this.state;
    const dialogTitle = agreementStep < 1 ? dialogTitles[0] : dialogTitles[1];

    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => this.props.hideAgreementForm()}
        isOpen={this.props.showNewAgreementForm}
        overlayClassName="modalOverlay"
        className={classNames('modalAgreement', {
          completedmodal: newAgreementFormCompleted,
        })}
      >
        {!newAgreementFormCompleted && (
          <Card fluid>
            <div
              className={classNames('ui active centered', {
                loader: isVehicleLoading,
              })}
            />
            {(isVehicleLoading || isLocalDataLoader) && (
              <div className="loader-overlay" />
            )}
            <Card.Content extra className="card-header">
              <Card.Header>{dialogTitle}</Card.Header>
            </Card.Content>
            <Card.Content>
              {agreementStep === 0 && this.renderFindVehicle()}
              {agreementStep > 0 &&
                agreementStep <= 9 &&
                this.renderNewAgreementForm()}
            </Card.Content>
            {showNewAgreementForm && this.renderDialogButtons()}
          </Card>
        )}
        {newAgreementFormCompleted && this.renderCompleted()}
      </Modal>
    );
  };

  render() {
    return (
      <>
        {this.renderNewAgreementModal()}
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    showNewAgreementForm,
    agreementStep,
    freeVehicles,
    driversList,
    agreementDrivers,
    agreementVehicle,
    newAgreementData,
  } = state.agreement;
  const { currency } = state.settings;
  const { isDataLoading, isLocalDataLoader } = state.ui;

  return {
    newAgreementData,
    showNewAgreementForm,
    agreementStep,
    freeVehicles,
    isDataLoading,
    driversList,
    agreementDrivers,
    isLocalDataLoader,
    agreementVehicle,
    currency,
  };
};

const mapDispatchToProps = {
  hideAgreementForm,
  searchFreeVehicles,
  resetSearchState,
  agreementFormSetStep,
  loadDriversList,
  loadDriverInfo,
  getWAInfo,
  applyAgreement,
  agreementSingByEmail,
  checkAgreementStatus,
  onSetAgreementStatusToCancel,
  openAgreementDocument,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewAgreement)
);
