import React, { PureComponent } from 'react';
import {
  Button,
  Card,
  Confirm,
  Dimmer,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Loader,
  Menu,
  Popup,
  Segment,
  Transition,
} from 'semantic-ui-react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import classNames from 'classnames';
import moment from 'moment';
import i18next from 'i18next';
import currencyFormatter from 'currency-formatter';

import { connect } from 'react-redux/es/alternate-renderers';
import _ from 'lodash';
import {
  carClass,
  engineType,
  fuelVolumes,
  transmissionList,
} from '../../../catalogs/constants';
import {
  getVehicleMake,
  loadVehicle,
  loadVehicleList,
  getVehicleModel,
  deleteVehicle,
  getVehicleSearch,
  saveVehicle,
} from '../../../../actions/vehicles/vehicles';
import Expenses from '../../Expenses';
import AppConfig from '../../../../config';
import { convertGalleryImages, resizeImage } from '../../../../services/utils';
import { ImageUploader } from '../../ImageUploader/ImageUploader';
import CropImageModal from '../../shared/CropImageModal';
import ImageViewer from '../../shared/ImageViewer';
import StatusMessage from '../../shared/StatusMessage';
import CardImage from '../../shared/CardImage';
import { createExpenses } from '../../../../actions/vehicles/expenses';
import { Vehicle } from '../../../models/Vehicle';
import SideBarList from '../../shared/SideBarList/SideBarList';

import styles from './Vehicles.module.scss';
import VehiclesSearch from '../VehoclesSearch/VehiclesSearch';
import { VehicleListItem } from '../../../models/VehiclesListItem';
import FuelSelector from '../../shared/FuelSelector/FuelSelector';

const MAKE_FIELD = 'make';
const MODEL_FIELD = 'model';

const defaultState = {
  activeTab: 0,
  isPreparingFiles: false,
  isSaving: false,
  isCropModalShow: false,
  addMode: false,
  isEditMode: false,
  isGalleryAddMode: false,
  isImageChange: false,
  isGalleryChange: false,
  isExpensesChange: false,
  isImageViewerVisible: false,
  uploadedImageId: -1,
  listKey: 0,
  galleryToUpload: [],
  deletedExpenses: [],
  editExpensesData: [],
  errors: [],
  makeList: [],
  modelList: [],
  expenses: [],
};

class Vehicles extends PureComponent {
  typingTimeout = null;

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      activeItemIndex: 0,
      openConfirm: false,
      isSearchLoading: false,
      expensesLoading: false,
      expensesSaveData: false,
      completeOperation: false,
      searchValue: '',
      searchResults: [],
      vehicleForm: _.cloneDeep(this.props.vehicleForm),
      formPrevState: {},
    };
    this.fileLoad = React.createRef();
    this.formUploadRef = React.createRef();
    this.previewRef = React.createRef();
  }

  componentDidMount() {
    this.props.loadVehicleList().then(() => {
      const vehicleId = this.props.vehiclesList.length
        ? this.props.vehiclesList[0].id
        : 0;
      if (vehicleId) this.getSingleVehicle(vehicleId);
    });
  }

  onOffAddEditMode = () => {
    this.setState({
      addMode: false,
      isEditMode: false,
      isImageChange: false,
    });
  };

  // Form save/edit/delete
  getFieldError = (fieldName) => {
    const { errors } = this.state;
    // eslint-disable-next-line no-prototype-builtins
    return errors.hasOwnProperty(fieldName) && errors[fieldName];
  };

  validateFormState = (isInputChange = false) => {
    const { vehicleForm } = this.state;
    const formValidationField = AppConfig.validation.newVehicle;
    const errors = [];

    for (let i = 0; i < formValidationField.length; i++) {
      if (!vehicleForm[formValidationField[i]]) {
        errors[formValidationField[i]] = true;
      }
    }

    this.setState({ errors });

    return Object.keys(errors).length;
  };

  resetFormState = (resetFormState = false) => {
    const formState = resetFormState ? this.state.formPrevState : {};
    this.setState({
      ...defaultState,
      ...formState,
    });
  };

  saveBtnHandle = async () => {
    const {
      addMode,
      activeItemIndex,
      isImageChange,
      vehicleForm,
      isExpensesChange,
      isGalleryChange,
      galleryToUpload,
      deletedExpenses,
    } = this.state;

    if (this.validateFormState(vehicleForm) > 0) return;

    this.setState({ isSaving: true });
    await this.props.saveVehicle({
      vehicle: vehicleForm,
      isImageChange,
      isNewVehicle: addMode,
      isExpensesChange,
      isGalleryChange,
      galleryToUpload,
      deletedExpenses,
    });
    const itemIndex = addMode
      ? this.props.vehiclesList.findIndex(
          (item) => item.id === this.props.vehicleForm.id
        )
      : activeItemIndex;
    this.setState({
      vehicleForm: _.cloneDeep(this.props.vehicleForm),
      activeItemIndex: itemIndex,
      isSaving: false,
    });
    this.resetFormState();
    this.onOffAddEditMode();
  };

  onEditClick = () => {
    const prevState = _.cloneDeep(this.props.vehicleForm);
    this.setState({
      addMode: false,
      isEditMode: true,
      formPrevState: prevState,
    });
  };

  cancelBtnHandle = () => {
    this.resetFormState(true);
  };

  getSingleVehicle = (index) => {
    this.props.loadVehicle(index).then(() => {
      this.setState({
        ...defaultState,
        vehicleForm: _.cloneDeep(this.props.vehicleForm),
      });
    });
  };

  getMakeList = (make) => {
    if (!make || make.length < 3) return;

    const getVehicleMake = () => {
      this.props
        .getVehicleMake(make)
        .then((result) => {
          this.setState({ makeList: result.data });
        })
        .catch((e) => {
          console.log(e);
        });
    };
    setTimeout(getVehicleMake, 1000);
  };

  getModelList = (model) => {
    if (!model || model.length < 2) return;

    const { make } = this.props.vehicleForm;

    const getVehicleModel = () => {
      this.props
        .getVehicleModel({ make, model })
        .then((result) => {
          if (this.state.modelList !== result.data)
            this.setState({ modelList: result.data });
        })
        .catch((e) => {
          console.log(e);
        });
    };
    setTimeout(getVehicleModel, 1000);
  };

  loadExpensesItem = (index) => {
    this.setState({
      editExpensesData: this.state.vehicleForm.expenses[index],
      editExpensesDataIndex: index,
    });
  };

  saveExpensesItem = async (data, updateMode = false) => {
    const { expenses } = this.state.vehicleForm;
    // eslint-disable-next-line no-param-reassign
    data.amount = currencyFormatter.unformat(data.amount, {
      code: AppConfig.currency,
    });
    if (updateMode) {
      expenses[this.state.editExpensesDataIndex] = data;
      // eslint-disable-next-line no-prototype-builtins
      if (
        expenses[this.state.editExpensesDataIndex].hasOwnProperty('newItem')
      ) {
        expenses[this.state.editExpensesDataIndex].newItem = true;
        expenses[this.state.editExpensesDataIndex].updateItem = false;
      } else expenses[this.state.editExpensesDataIndex].updateItem = true;
    } else {
      expenses.push({ ...data, newItem: true });
    }

    this.setState((prevState, props) => {
      return {
        vehicleForm: { ...prevState.vehicleForm, expenses },
        isExpensesChange: true,
      };
    });
  };

  deleteExpensesItem = (index) => {
    const { expenses } = this.state.vehicleForm;
    const arrayIndex = expenses.findIndex((item) => item.id === index);
    const { deletedExpenses } = this.state;
    if (!deletedExpenses.indexOf(index)) deletedExpenses.push(index);

    expenses.splice(arrayIndex, 1);

    this.setState((prevState, props) => {
      return {
        vehicleForm: { ...prevState.vehicleForm, expenses },
        isExpensesChange: true,
        deletedExpenses,
      };
    });
  };

  deleteActiveItem = (index) => {
    const { activeItemIndex } = this.state;
    const { vehiclesList } = this.props;
    let loadItemIndex = 0;

    this.props.deleteVehicle(this.props.vehiclesList[index].id).then(() => {
      if (activeItemIndex > 0) loadItemIndex = activeItemIndex - 1;
      if (vehiclesList[loadItemIndex]?.id) {
        this.getSingleVehicle(vehiclesList[loadItemIndex]?.id);
        this.setState({ activeItemIndex: loadItemIndex });
      }
    });
  };
  //

  handleOnSearch = (results) => {
    if (results) {
      let searchResults = [];
      searchResults = results.map((item) => new VehicleListItem(item));
      if (searchResults.length) this.getSingleVehicle(searchResults[0]?.id);

      this.setState({
        searchResults,
      });
    }
  };

  //
  handleInputChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    clearTimeout(this.typingTimeout);

    if (name === MAKE_FIELD) setTimeout(this.getMakeList(value), 1000);

    if (name === MODEL_FIELD) setTimeout(this.getModelList(value), 1000);

    this.setState((prevState, props) => {
      return { vehicleForm: { ...prevState.vehicleForm, [name]: value } };
    });

    this.validateFormState(true);
  };

  handleInputChangeRate = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState((prevState, props) => {
      return {
        vehicleForm: {
          ...prevState.vehicleForm,
          [name]: value,
          rate_d1: value,
          rate_monthly: +value * 30,
        },
      };
    });
  };

  handleSelectChange = (e, result) => {
    const { name } = result;
    const { value } = result;
    this.setState((prevState, props) => {
      return { vehicleForm: { ...prevState.vehicleForm, [name]: value } };
    });
  };

  handleCalendarChange = (event, { name, value }) => {
    this.setState((prevState, props) => {
      return {
        vehicleForm: {
          ...prevState.vehicleForm,
          [name]: moment(value, 'DD-MM-YYYY'),
        },
      };
    });
  };

  //
  openConfirm = () => this.setState({ openConfirm: true });

  closeConfirm = () => this.setState({ openConfirm: false });

  onPreviewOpen = (url = '') => {
    const galleryUrlsArray = [];
    if (this.state.vehicleForm.gallery.length) {
      for (const galImage of this.state.vehicleForm.gallery) {
        if (galImage)
          galleryUrlsArray.push(`${AppConfig.serverRoot}${galImage?.file}`);
      }
    }
    this.setState({
      isImageViewerVisible: true,
      galleryUrls: galleryUrlsArray,
    });
  };

  onMainImageClear = () => {
    this.setState((prevState) => {
      return {
        vehicleForm: {
          ...prevState.vehicleForm,
          images: {
            preview: '',
            full: '',
          },
        },
        isImageChange: true,
      };
    });
  };

  loadUploadImage = async (event) => {
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      event.target.value = null;
      const preview = await resizeImage(files, 500, 500);
      const full = await resizeImage(files);

      this.setState((prevState) => {
        return {
          isCropModalShow: true,
          isImageChange: true,
          vehicleForm: { ...prevState.vehicleForm, images: { preview, full } },
        };
      });
    }
  };

  onUpdatePreviewImage = (image) => {
    this.setState((prevState) => {
      return {
        isCropModalShow: false,
        vehicleForm: {
          ...prevState.vehicleForm,
          images: { ...prevState.vehicleForm.images, preview: image },
        },
      };
    });
  };

  onFuelOutChange = (fuelValue) => {
    this.setState((prevState) => {
      return {
        vehicleForm: { ...prevState.vehicleForm, fuel_out: fuelValue },
      };
    });
  };

  getFuelOutButtonActive = (fuelOut) => {
    return fuelOut === this.state.vehicleForm.fuel_out;
  };

  clearSearchResults = () => {
    this.setState({ searchResults: [] });
  };

  onAddVehicle = () => {
    const prevState = new Vehicle();
    this.setState({
      addMode: true,
      isEditMode: true,
      activeItemIndex: -1,
      activeTab: 0,
      formPrevState: prevState,
      vehicleForm: prevState,
    });
  };

  onVehicleClick = (id, index) => {
    if (this.state.activeItemIndex !== index) {
      this.getSingleVehicle(id);
      this.setState({ activeItemIndex: index });
    }
  };

  onPreviewClose = () => {
    this.setState({ isImageViewerVisible: false });
  };

  uploadImageToState = async (images) => {
    this.setState({ isPreparingFiles: true });
    const imagesObject = await convertGalleryImages(images);
    console.log(imagesObject);
    this.setState({
      isGalleryChange: true,
      galleryToUpload: imagesObject,
      isGalleryAddMode: false,
      isPreparingFiles: false,
    });
  };

  _renderGallery() {
    const {
      vehicleForm: { gallery },
      isGalleryChange,
      galleryToUpload,
    } = this.state;

    // if (_.isNull(gallery) || gallery.length === 0 || !isGalleryChange)
    //     return;

    return (
      <div className="gallery-preview-container">
        <Card.Group itemsPerRow={6}>
          {(!_.isNull(gallery) || gallery.length !== 0) &&
            gallery.map((item, index) => {
              const imgUrl = AppConfig.serverRoot + item.file;
              return (
                <Popup
                  key={`gallery-item-${index}`}
                  trigger={<Card raised centered image={imgUrl} />}
                >
                  <Image src={imgUrl} size="medium" />
                </Popup>
              );
            })}
          {isGalleryChange &&
            galleryToUpload.map((item, index) => {
              const imageUrl = URL.createObjectURL(item);
              return (
                <Popup
                  key={`gallery-item-to-upload-${index}`}
                  trigger={<Card raised centered image={imageUrl} />}
                >
                  <Image src={imageUrl} size="medium" />
                </Popup>
              );
            })}
        </Card.Group>
      </div>
    );
  }

  _renderMakeList() {
    const { makeList } = this.state;

    if (!makeList.length) return <></>;

    return makeList.map((item, index) => (
      <option value={item.make} key={index}>
        {item.make}
      </option>
    ));
  }

  _renderModelList() {
    const { modelList } = this.state;

    if (!modelList.length) return <></>;

    return modelList.map((item, index) => (
      <option value={item.model} key={index}>
        {item.model}
      </option>
    ));
  }

  _renderImagePreview() {
    const {
      isImageChange,
      galleryUrls,
      isImageViewerVisible,
      vehicleForm: { images },
    } = this.state;
    const full = isImageChange
      ? AppConfig.serverRoot + images?.full
      : AppConfig.serverRoot + images?.full;

    if (!isImageViewerVisible) return;

    return (
      <ImageViewer
        ref={this.previewRef}
        image={full}
        mainImage={full}
        gallery={galleryUrls}
        onPreviewClose={this.onPreviewClose}
      />
    );
  }

  _renderConfirm() {
    const { activeItemIndex, openConfirm } = this.state;
    const { vehiclesList } = this.props;
    const content = `${
      vehiclesList[activeItemIndex]
        ? `${vehiclesList[activeItemIndex].make} ${vehiclesList[activeItemIndex].model}`
        : ''
    } ?`;
    const header = i18next.t('Delete item');

    return (
      <Confirm
        open={openConfirm}
        header={header}
        content={content}
        onCancel={this.closeConfirm}
        onConfirm={() => {
          this.deleteActiveItem(activeItemIndex);
          this.closeConfirm();
        }}
        confirmButton={i18next.t('Yes')}
        cancelButton={i18next.t('No')}
      />
    );
  }

  _renderActionPanel() {
    const { activeItemIndex, isEditMode, isSaving } = this.state;
    return (
      <Menu className="tool-bar" stackable>
        <Menu.Item className="section-name" header>
          {i18next.t('Vehicles')}
        </Menu.Item>
        {!isEditMode && (
          <Menu.Item
            className={classNames({ 'form-group-disable': isEditMode })}
          >
            <Button
              icon
              primary
              labelPosition="left"
              onClick={this.onAddVehicle}
            >
              <Icon name="plus" />
              {i18next.t('Add Vehicle')}
            </Button>
            {this.props.vehiclesList[activeItemIndex] && (
              <Button
                positive
                disabled={!(activeItemIndex >= 0)}
                onClick={this.onEditClick}
              >
                {i18next.t('Edit')}
              </Button>
            )}
            {this.props.vehiclesList[activeItemIndex] && (
              <Button
                color="red"
                disabled={!(activeItemIndex >= 0)}
                onClick={this.openConfirm}
              >
                {i18next.t('Delete')}
              </Button>
            )}
          </Menu.Item>
        )}
        {this.state.isEditMode && (
          <Menu.Item
            className={classNames({ 'form-group-disable': isSaving })}
            disabled={isSaving}
          >
            <Button positive onClick={this.saveBtnHandle}>
              {i18next.t('Save')}
            </Button>
            <Button onClick={this.cancelBtnHandle}>
              {i18next.t('Cancel')}
            </Button>
          </Menu.Item>
        )}
        <Menu.Item className="section-message" header>
          <StatusMessage />
        </Menu.Item>
      </Menu>
    );
  }

  _renderLeftSidePanel() {
    const { searchResults, isEditMode } = this.state;
    const activeVehiclesList =
      searchResults.length > 0 ? searchResults : this.props.vehiclesList;
    const listCount = 0;

    if (listCount > 0) console.log('1');

    return (
      <div
        className={classNames(styles.sideBar, 'form-group', {
          'form-group-disable': isEditMode,
        })}
      >
        <VehiclesSearch
          onSearchCallback={this.handleOnSearch}
          clearSearchResults={this.clearSearchResults}
        />
        <SideBarList
          dataList={activeVehiclesList}
          listClick={(id, index) => this.onVehicleClick(id, index)}
          activeIndex={this.state.activeItemIndex}
          iconName="car"
          itemHeader="vehicle"
          itemSubheader="year"
        />
      </div>
    );
  }

  _renderForm() {
    const {
      isEditMode,
      isCropModalShow,
      isImageChange,
      uploadedImageId,
      vehicleForm: { images },
    } = this.state;

    const { preview, full } =
      isImageChange && images?.preview ? images : images;
    const isStartUpload = !!(
      isImageChange && typeof images.preview === 'object'
    );

    return (
      <Segment>
        <CropImageModal
          onCropped={(image) => this.onUpdatePreviewImage(image)}
          onCloseModal={() =>
            this.setState({ isCropModalShow: false, isImageChange: false })
          }
          isCropModalShow={isCropModalShow}
          image={full}
        />
        <Form autoComplete="off">
          <Grid>
            <Grid.Column width={6}>
              <Grid.Row>
                <Grid.Column>
                  <CardImage
                    isEditMode={isEditMode}
                    imageUrl={preview}
                    isHasImage={uploadedImageId <= 0}
                    onImageLoad={() => this.fileLoad.current.click()}
                    onImageClear={() => {
                      this.onMainImageClear();
                    }}
                    onPreview={() => this.onPreviewOpen()}
                    isUploadImageState={isStartUpload}
                  />
                  <div ref={this.formUploadRef}>
                    <input
                      type="file"
                      name="file-load"
                      ref={this.fileLoad}
                      hidden
                      onChange={this.loadUploadImage}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={10} className="client-main-info-wrapper">
              <Tabs
                selectedIndex={this.state.activeTab}
                onSelect={(e) => this.setState({ activeTab: e })}
              >
                <TabList>
                  <Tab>{i18next.t('General')}</Tab>
                  <Tab>{i18next.t('Rates')}</Tab>
                  <Tab>{i18next.t('Media')}</Tab>
                  <Tab>{i18next.t('Vehicle Expenses')}</Tab>
                </TabList>
                <TabPanel
                  className={classNames('tab-wrapper', {
                    'form-group-disable': !this.state.isEditMode,
                  })}
                >
                  <div className="tab-wrapper-inner">{this._renderTab1()}</div>
                </TabPanel>
                <TabPanel
                  className={classNames('tab-wrapper', {
                    'form-group-disable': !this.state.isEditMode,
                  })}
                >
                  <div className="tab-wrapper-inner">{this._renderTab2()}</div>
                </TabPanel>
                <TabPanel className={classNames('tab-wrapper')}>
                  <div className="tab-wrapper-inner equal-padding">
                    {this._renderTab3()}
                  </div>
                </TabPanel>
                <TabPanel
                  className={classNames('tab-wrapper', {
                    'form-group-disable': !this.state.isEditMode,
                  })}
                >
                  <Grid columns="equal" className="exp-tab">
                    <Grid.Row>{this._renderExpenses()}</Grid.Row>
                  </Grid>
                </TabPanel>
              </Tabs>
            </Grid.Column>
          </Grid>
        </Form>
      </Segment>
    );
  }

  _renderTab1() {
    const {
      make,
      model,
      year,
      odometer_out,
      vehicle_license,
      vehicle_state,
      vclass,
      type,
      stock_number,
      transmission,
      color,
      vin,
      fuel_out,
    } = this.state.vehicleForm;

    return (
      <>
        <Form.Input
          inline
          label={i18next.t('Make')}
          name="make"
          placeholder={i18next.t('Make')}
          value={make}
          onChange={this.handleInputChange}
          list="makes-list"
          error={this.getFieldError('make')}
        />
        <datalist id="makes-list">{this._renderMakeList()}</datalist>
        <Form.Input
          inline
          label={i18next.t('Model')}
          name="model"
          placeholder={i18next.t('Model')}
          onChange={this.handleInputChange}
          value={model}
          error={this.getFieldError('model')}
          list="model-list"
        />
        <datalist id="model-list">{this._renderModelList()}</datalist>
        <Form.Input
          inline
          label={i18next.t('Year')}
          name="year"
          placeholder={i18next.t('Year')}
          onChange={this.handleInputChange}
          value={year}
        />
        <Form.Input
          inline
          label={i18next.t('Vin')}
          name="vin"
          placeholder={i18next.t('Vin')}
          value={vin}
          onChange={this.handleInputChange}
        />
        <Form.Select
          inline
          fluid
          label={i18next.t('Class')}
          name="vclass"
          options={carClass}
          placeholder={i18next.t('Class')}
          onChange={this.handleSelectChange}
          value={vclass}
        />
        <Form.Input
          inline
          label={i18next.t('Color')}
          name="color"
          placeholder={i18next.t('Color')}
          onChange={this.handleInputChange}
          value={color}
        />
        <Form.Input
          inline
          label={i18next.t('Stock Number')}
          name="stock_number"
          placeholder={i18next.t('Stock Number')}
          value={stock_number}
          onChange={this.handleInputChange}
        />
        <Form.Select
          inline
          fluid
          label={i18next.t('Transmission')}
          name="transmission"
          options={transmissionList}
          placeholder={i18next.t('Transmission')}
          onChange={this.handleSelectChange}
          value={transmission}
        />
        <Form.Select
          inline
          fluid
          label={i18next.t('Fuel')}
          name="type"
          options={engineType}
          placeholder={i18next.t('Fuel')}
          onChange={this.handleSelectChange}
          value={type}
        />
        <Form.Input
          inline
          label={i18next.t('License')}
          name="vehicle_license"
          placeholder={i18next.t('License')}
          value={vehicle_license}
          onChange={this.handleInputChange}
        />
        <Form.Input
          inline
          label={i18next.t('State')}
          name="vehicle_state"
          placeholder={i18next.t('State')}
          value={vehicle_state}
          onChange={this.handleInputChange}
        />
        <label
          className={classNames('form-label', {
            'form-group-disable': !this.state.isEditMode,
          })}
        >
          Fuel Out
        </label>
        <Form.Group className="fuel-buttons">
          <FuelSelector
            defaultValue={fuel_out}
            onChange={this.onFuelOutChange}
          />
        </Form.Group>
        <Form.Input
          inline
          label={i18next.t('Odometer out')}
          name="odometer_out"
          placeholder={i18next.t('Odometer out')}
          value={odometer_out}
          onChange={this.handleInputChange}
        />
      </>
    );
  }

  _renderTab2() {
    const { rate_min, rate_d1, rate_monthly } = this.state.vehicleForm;
    return (
      <>
        <Form.Input
          inline
          label={i18next.t('Minimal cost')}
          placeholder={i18next.t('Minimal cost')}
          name="rate_min"
          onChange={this.handleInputChangeRate}
          value={rate_min}
        />
        <Form.Input
          inline
          label={i18next.t('1 day')}
          placeholder={i18next.t('1 day')}
          name="rate_d1"
          value={rate_d1}
        />
        <Form.Input
          inline
          label={i18next.t('Monthly')}
          placeholder={i18next.t('Monthly')}
          name="rate_monthly"
          value={rate_monthly}
        />
      </>
    );
  }

  _renderTab3() {
    const {
      isEditMode,
      isGalleryChange,
      isGalleryAddMode,
      galleryToUpload,
      vehicleForm: { gallery },
      isPreparingFiles,
    } = this.state;
    const isDisableUpload =
      !_.isNull(gallery) &&
      gallery.length === AppConfig.maxVehicleImageToUpload;
    const maxImageAvailable = !_.isNull(gallery)
      ? Number(AppConfig.maxVehicleImageToUpload) - Number(gallery.length)
      : AppConfig.maxVehicleImageToUpload;

    return (
      <div className={classNames({ 'form-group-disable': !isEditMode })}>
        <Dimmer.Dimmable blurring dimmed={isPreparingFiles}>
          <Dimmer active={isPreparingFiles} inverted>
            <Loader indeterminate>Preparing Files</Loader>
          </Dimmer>
          <Button
            primary
            disabled={isDisableUpload}
            onClick={() =>
              this.setState({ isGalleryAddMode: !this.state.isGalleryAddMode })
            }
          >
            {i18next.t('Add images')}
          </Button>
          {isGalleryChange && isEditMode && (
            <div>
              {i18next.t('Wait for upload')}:{galleryToUpload.length}
            </div>
          )}
          <Transition
            visible={isGalleryAddMode}
            animation="fade"
            duration={500}
          >
            <div className="image-upload-container">
              <ImageUploader
                className={classNames({
                  'form-group-disable': !this.state.isEditMode,
                })}
                maxImagesToUpload={maxImageAvailable}
                maxSizeBytes={AppConfig.maxVehicleImageToUploadSize}
                onUpload={this.uploadImageToState}
              />
            </div>
          </Transition>
        </Dimmer.Dimmable>
        {this._renderGallery()}
      </div>
    );
  }

  _renderExpenses() {
    return (
      <Expenses
        loadExpensesItem={this.loadExpensesItem}
        saveExpensesItem={this.saveExpensesItem}
        deleteExpensesItem={this.deleteExpensesItem}
        expenses={this.state.vehicleForm.expenses}
        editExpensesData={this.state.editExpensesData}
        expensesLoading={this.state.expensesLoading}
        expensesSaveData={this.state.expensesSaveData}
        completeOperation={this.state.completeOperation}
        isEditMode={this.state.isEditMode}
      />
    );
  }

  render() {
    return (
      <div className="Vehicles catalog-tabs">
        {this._renderConfirm()}
        {this._renderActionPanel()}
        {this._renderImagePreview()}
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={4}>{this._renderLeftSidePanel()}</Grid.Column>
            <Grid.Column>{this._renderForm()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    vehiclesList,
    vehicleForm,
    vehicleForm: { expenses },
  } = state.vehicles;
  const { isDataLoading } = state.ui;

  return {
    isDataLoading,
    vehiclesList,
    vehicleForm,
    expenses,
  };
};

const mapDispatchToProps = {
  loadVehicleList,
  loadVehicle,
  getVehicleMake,
  getVehicleModel,
  getVehicleSearch,
  saveVehicle,
  createExpenses,
  deleteVehicle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
