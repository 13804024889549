import axios from 'axios';
import moment from 'moment';
import randomColor from 'randomcolor';
import uniqBy from 'lodash/uniqBy';
import AppConfig from '../config';
import { tokenConfig } from './user/auth';
import { localDataLoader } from './UiActions';
import { LOAD_AGREEMENTS_LIST } from './types';

export const loadPlannerAgreementsList = () => async (dispatch, getState) => {
  const randomSeed = Math.floor(Math.random() * 10);
  const agreementsList = [];
  let vehiclesList = [];
  const groupColors = {};
  try {
    dispatch(localDataLoader());
    const res = await axios.get(
      `${AppConfig.apiUrl}/agreements/getagreementslist?status=active`,
      tokenConfig(getState)
    );

    if (res.data) {
      const vehiclesRawList = [];
      res.data.forEach((item, index) => {
        const colors = {
          bg: randomColor({
            luminosity: 'dark',
            seed: randomSeed * index,
            format: 'rgba',
            alpha: 0.4,
          }),
          sc: randomColor({
            luminosity: 'dark',
            seed: randomSeed * index,
            format: 'rgba',
            alpha: 1,
          }),
          fg: randomColor({ luminosity: 'dark', seed: randomSeed * index }),
        };
        if (
          index === 0 ||
          vehiclesList[index - 1]?.vehicleId !== item.vehicleId
        ) {
          vehiclesRawList.push({
            id: `vehicle-${item.vehicleId}`,
            name: `${item.make} ${item.model}`,
            bgColor: colors.bg,
            vehicleId: item.vehicleId,
          });
          groupColors[item.vehicleId] = colors;
        }
      });

      vehiclesList = uniqBy(vehiclesRawList, 'vehicleId');

      res.data.forEach((item, index) => {
        const vehicle = `${item.make} ${item.model}`;
        const startDate = moment(new Date(item.dt_start));
        const endDate = moment(new Date(item.dt_end));
        const name = `${item.family_name} ${item.first_name}`;

        agreementsList.push({
          id: item.agreement_number,
          start: startDate,
          end: endDate,
          resourceId: `vehicle-${item.vehicleId}`,
          title: vehicle,
          resizable: false,
          movable: false,
          bgColor: groupColors[item.vehicleId].bg,
          borderColor: groupColors[item.vehicleId].sc,
          color: groupColors[item.vehicleId].fg,
          agreementNumber: item.agreement_number,
          clientName: name,
        });
      });

      dispatch({
        type: LOAD_AGREEMENTS_LIST,
        payload: { agreementsList, vehiclesList },
      });
    }
    dispatch(localDataLoader(false));
  } catch (err) {
    console.log(err);
  }
};
