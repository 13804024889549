import React, { useState, useEffect } from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { hideStatusMessage } from '../../../actions/UiActions';
import { colors } from '../../catalogs/constants';

function StatusMessage(props) {
  const {
    messageType,
    statusMessageText,
    timeOutDelay,
    hideStatusMessage,
    isMessageVisible,
  } = props;

  useEffect(() => {
    const id = setTimeout(onTimeout, timeOutDelay);
    return () => clearTimeout(id);
  }, [isMessageVisible]);

  const onTimeout = () => {
    hideStatusMessage();
  };

  return (
    <CSSTransition
      in={isMessageVisible}
      timeout={300}
      classNames="status-message"
    >
      <Message
        className={classNames({ 'hidden-message': !isMessageVisible })}
        color={colors[messageType]}
      >
        {statusMessageText}
      </Message>
    </CSSTransition>
  );
}

const mapStateToProps = (state) => {
  const { messageType, statusMessageText, isMessageVisible } =
    state.ui.statusMessage;
  return {
    messageType,
    statusMessageText,
    isMessageVisible,
  };
};
const mapDispatchToProps = { hideStatusMessage };

StatusMessage.defaultProps = {
  timeOutDelay: 5000,
  statusMessageText: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusMessage);
