import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { fuelVolumes } from '../../../catalogs/constants';

const FuelSelector = (props) => {
  const [activeFuelButton, setActiveFuelButton] = useState(props.defaultValue);

  useEffect(() => {
    setActiveFuelButton(props.defaultValue);
  }, [props.defaultValue]);

  const onFuelOutClick = (e, data) => {
    const { name } = data;
    setActiveFuelButton(name);
    props.onChange(name);
  };

  const getFuelOutButtonActive = (fuelOut) => {
    return fuelOut === activeFuelButton;
  };

  return (
    <Button.Group>
      {Object.keys(fuelVolumes).map((item) => (
        <Button
          name={fuelVolumes[item]}
          onClick={onFuelOutClick}
          active={getFuelOutButtonActive(fuelVolumes[item])}
        >
          {fuelVolumes[item]}
        </Button>
      ))}
    </Button.Group>
  );
};

FuelSelector.defaultProps = {
  defaultValue: 'E',
};

FuelSelector.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default FuelSelector;
