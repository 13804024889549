import axios from 'axios';
import i18next from 'i18next';
import _ from 'lodash';
import AppConfig from '../config';
import { tokenConfig } from './user/auth';
import {
  LOAD_CLIENT,
  LOAD_CLIENT_FAIL,
  LOAD_CLIENTS_LIST,
  LOAD_CLIENTS_LIST_FAIL,
  SHOW_STATUS_MESSAGE,
} from './types';
import { SET_DATA_LOADING, showStatusMessage } from './UiActions';
import {
  errorMessageStatus,
  statusMessages,
  successMessageStatus,
} from '../common/catalogs/constants';

import { ClientListItem } from '../common/models/ClientListItem';
import { Client } from '../common/models/Client';

export const loadClientList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_DATA_LOADING,
      payload: true,
    });
    const res = await axios.get(
      `${AppConfig.apiUrl}/clients/getclientslist`,
      tokenConfig(getState)
    );
    dispatch({
      type: LOAD_CLIENTS_LIST,
      payload: res.data.map((item) => new ClientListItem(item)),
    });
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: LOAD_CLIENTS_LIST_FAIL,
    });
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
    dispatch({
      type: SHOW_STATUS_MESSAGE,
      payload: {
        messageType: errorMessageStatus,
        statusMessage: i18next.t(statusMessages.clientLoadError),
      },
    });
  }
};

export const getSingleClient = (id) => async (dispatch, getState) => {
  const config = tokenConfig(getState);
  try {
    dispatch({
      type: SET_DATA_LOADING,
      payload: true,
    });
    const client = await axios.get(
      `${AppConfig.apiUrl}/clients/client/${id}`,
      config
    );

    dispatch({
      type: LOAD_CLIENT,
      payload: new Client(client.data),
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: LOAD_CLIENT_FAIL,
    });
    dispatch({
      type: SHOW_STATUS_MESSAGE,
      payload: {
        messageType: errorMessageStatus,
        statusMessage: i18next.t(statusMessages.clientLoadError),
      },
    });
  }
  dispatch({
    type: SET_DATA_LOADING,
    payload: false,
  });
};

export const saveClient =
  ({ newClient, client, isImageChange }) =>
  async (dispatch, getState) => {
    let isNeedReloadList = false;
    const clientData = _.cloneDeep(client);
    const files = _.cloneDeep(clientData.images);
    let { id } = clientData;
    delete clientData.files;

    try {
      if (newClient) {
        const res = await axios.put(
          `${AppConfig.apiUrl}/clients/addclient`,
          clientData,
          tokenConfig(getState)
        );
        if (res && isImageChange && files.preview !== '') {
          const imageData = new FormData();
          imageData.append('id', res.data.id);
          imageData.append('preview', files.preview);
          imageData.append('full', files.full);
          await axios.post(
            `${AppConfig.apiUrl}/clients/uploadimage`,
            imageData,
            tokenConfig(getState)
          );
        }
        id = res.data.id;
        isNeedReloadList = true;
        dispatch(
          showStatusMessage(
            successMessageStatus,
            i18next.t(statusMessages.clientAddSuccess)
          )
        );
      } else {
        delete clientData.Uploads;
        delete clientData.Agreements;
        delete clientData.agreements;
        delete clientData.images;
        delete clientData.createdAt;
        delete clientData.updatedAt;

        const res = await axios.patch(
          `${AppConfig.apiUrl}/clients/updateclient`,
          clientData,
          tokenConfig(getState)
        );
        isNeedReloadList = true;

        if (res && isImageChange && files.preview !== '') {
          const imageData = new FormData();
          imageData.append('id', res.data.id);
          imageData.append('preview', files.preview);
          imageData.append('full', files.full);
          const uploadImg = await axios.post(
            `${AppConfig.apiUrl}/clients/uploadimage`,
            imageData,
            tokenConfig(getState)
          );
        } else if (res && isImageChange && files.preview === '') {
          await axios.get(
            `${AppConfig.apiUrl}/clients/deleteimage/${res.data.id}`,
            tokenConfig(getState)
          );
          isNeedReloadList = true;
        }
        id = res.data.id;
        dispatch(
          showStatusMessage(
            successMessageStatus,
            i18next.t(statusMessages.clientUpdatedSuccess)
          )
        );
      }

      if (isNeedReloadList) {
        await dispatch(loadClientList()).then(
          async () => await dispatch(getSingleClient(id))
        );
      }
    } catch (e) {
      dispatch(
        showStatusMessage(
          errorMessageStatus,
          i18next.t(statusMessages.clientUpdatedError)
        )
      );
    }
  };

export const deleteClient = (index) => async (dispatch, getState) => {
  try {
    await axios.delete(`${AppConfig.apiUrl}/clients/deleteclient`, {
      ...tokenConfig(getState),
      data: { removeId: index },
    });
    dispatch(loadClientList());
    dispatch(
      showStatusMessage(
        successMessageStatus,
        i18next.t(statusMessages.clientDeleted)
      )
    );
  } catch (e) {
    console.log(e);
    dispatch(
      showStatusMessage(
        errorMessageStatus,
        i18next.t(statusMessages.clientNotDeleted)
      )
    );
  }
};

export const getClientSearch = (searchData) => async (dispatch, getState) => {
  const config = tokenConfig(getState);
  try {
    const client = await axios.post(
      `${AppConfig.apiUrl}/clients/searchclient`,
      { data: searchData },
      config
    );
    return client;
  } catch (err) {
    console.log(err);
  }
};
