import React, { Component } from 'react';
import {
  Grid,
  Menu,
  Button,
  Icon,
  Segment,
  List,
  Form,
  Confirm,
  Image,
  Input,
  Popup,
} from 'semantic-ui-react';
import i18next from 'i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { DateInput } from 'semantic-ui-calendar-react';
import { connect } from 'react-redux/es/alternate-renderers';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import { cardType } from '../catalogs/constants';
import RentalHistory from './RentelHistory';
import { resizeImage } from '../../services/utils';
import CropImageModal from './shared/CropImageModal';
import { Client } from '../models/Client';
import {
  deleteClient,
  getClientSearch,
  getSingleClient,
  loadClientList,
  saveClient,
} from '../../actions/clients';
import StatusMessage from './shared/StatusMessage';
import CardImage from './shared/CardImage';
import AppConfig from '../../config';
import { ClientListItem } from '../models/ClientListItem';
import ImageViewer from './shared/ImageViewer';

const defaultState = {
  isSaving: false,
  isImageViewerVisible: false,
  activeTab: 0,
  showPreview: false,
  addMode: false,
  isEditMode: false,
  openConfirm: false,
  rentalHistory: [],
  uploadedImageId: -1,
  errors: [],
};

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      uploadingImage: null,
      isCropModalShow: false,
      isImageLoaded: true,
      activeItemIndex: 0,
      isSearchLoading: false,
      searchValue: '',
      searchResults: [],
      clientsList: [],
      clientForm: new Client(),
      formPrevState: {},
    };
    this.fileLoad = React.createRef();
    this.previewRef = React.createRef();
  }

  componentDidMount() {
    this.onLoadClientList();
  }

  resetFormState = (resetFormState = false) => {
    const formState = resetFormState ? this.state.formPrevState : new Client();
    this.setState({
      ...defaultState,
      ...formState,
    });
  };

  onOffAddEditMode = () => {
    this.setState({
      addMode: false,
      isEditMode: false,
      isImageChange: false,
    });
  };

  onLoadClientList = () => {
    this.props.loadClientList().then(() => {
      const clientId = this.props.clientsList.length
        ? this.props.clientsList[0].id
        : 0;
      if (clientId) this.getSingleClient(clientId);
    });
  };

  getSingleClient = (id) => {
    this.props.getSingleClient(id).then(() => {
      this.setState({ clientForm: _.cloneDeep(this.props.clientForm) });
    });
  };

  //
  handleSearchResultSelect = (e, { result }) => {
    const searchIndex = _.findIndex(
      this.state.clientsList,
      (item) => item.id === result.id
    );
    if (searchIndex !== this.state.activeItemIndex)
      this.getSingleClient(searchIndex);
  };

  handleOnSearchChange = (e, { value }) => {
    if (value) this.setState({ searchValue: value });
    else this.clearSearchResults();
  };

  handleOnSearch = () => {
    this.setState({ isSearchLoading: true });
    this.props
      .getClientSearch(this.state.searchValue)
      .then((result) => {
        let searchResults = [];
        if (result.data)
          searchResults = result.data.map((item) => new ClientListItem(item));

        if (searchResults.length)
          this.props.getSingleClient(searchResults[0].id);

        this.setState({
          isSearchLoading: false,
          searchResults,
        });
      })
      .catch((e) => {
        this.setState({
          isSearchLoading: false,
          searchResults: [],
        });
      });
  };

  //
  handleInputChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState((prevState, props) => {
      return { clientForm: { ...prevState.clientForm, [name]: value } };
    });

    this.validateFormState(true);
  };

  handleSelectChange = (e, result) => {
    const { name } = result;
    const { value } = result;
    this.setState((prevState, props) => {
      return { clientForm: { ...prevState.clientForm, [name]: value } };
    });
  };

  handleCalendarChange = (event, { name, value }) => {
    this.setState((prevState, props) => {
      return { clientForm: { ...prevState.clientForm, [name]: value } };
    });
  };

  deleteActiveItem = (index) => {
    const { activeItemIndex } = this.state;
    const { clientsList } = this.props;
    let loadItemIndex = 0;

    this.props.deleteClient(clientsList[index].id).then(() => {
      if (activeItemIndex > 0) loadItemIndex = activeItemIndex - 1;
      if (clientsList[loadItemIndex]?.id) {
        this.getSingleClient(clientsList[loadItemIndex]?.id);
        this.setState({ activeItemIndex: loadItemIndex });
      }
    });
  };

  //
  uploadImageBtnClick = async (event) => {
    if (event.target.files.length > 0) {
      const resizedImage = await resizeImage(event.target.files[0], 500, 500);
      this.setState({ isCropModalShow: true, uploadingImage: resizedImage });
    }
  };

  loadUploadImage = async (event) => {
    if (event.target.files.length > 0) {
      const files = event.target.files[0];
      event.target.value = null;
      const preview = await resizeImage(files, 500, 500);
      const full = await resizeImage(files);

      this.setState((prevState) => {
        return {
          isCropModalShow: true,
          isImageChange: true,
          clientForm: { ...prevState.clientForm, images: { preview, full } },
        };
      });
    }
  };

  // Form save/edit/delete
  getFieldError = (fieldName) => {
    const { errors } = this.state;
    // eslint-disable-next-line no-prototype-builtins
    return errors.hasOwnProperty(fieldName) && errors[fieldName];
  };

  validateFormState = (isInputChange = false) => {
    const { clientForm } = this.state;
    const formValidationField = AppConfig.validation.newClient;
    const errors = [];
    for (let i = 0; i < formValidationField.length; i++) {
      if (!clientForm[formValidationField[i]]) {
        errors[formValidationField[i]] = true;
      }
    }

    this.setState({ errors });

    return Object.keys(errors).length;
  };

  saveBtnHandle = async () => {
    const { addMode, clientForm, isImageChange, activeItemIndex } = this.state;

    if (this.validateFormState(clientForm) > 0) return;

    this.setState({ isSaving: true });
    await this.props.saveClient({
      newClient: addMode,
      client: clientForm,
      isImageChange,
    });
    const itemIndex = addMode
      ? this.props.clientsList.findIndex(
          (item) => item.id === this.props.clientForm.id
        )
      : activeItemIndex;
    this.setState({
      clientForm: _.cloneDeep(this.props.clientForm),
      activeItemIndex: itemIndex,
      isSaving: false,
    });
    this.resetFormState();
    this.onOffAddEditMode();
  };

  cancelBtnHandle = () => {
    this.setState({ isEditMode: false });
  };

  //
  hideStatusMessage = () => {
    setTimeout(() => this.setState({ showStatusMessage: false }), 5000);
  };

  openConfirm = () => this.setState({ openConfirm: true });

  closeConfirm = () => this.setState({ openConfirm: false });

  clearSearchResults = () => {
    this.setState({ searchResults: [], searchValue: '' });
  };

  getSearchActionButton = () => {
    const { searchResults } = this.state;

    if (searchResults.length > 0)
      return { icon: 'close', onClick: this.clearSearchResults };
    return { icon: 'search', onClick: this.handleOnSearch };
  };

  onClientClick = ({ id, index }) => {
    if (this.state.activeItemIndex !== index) {
      this.getSingleClient(id);
      this.setState({ activeItemIndex: index, activeTab: 0 });
    }
  };

  onSearchKeyDown = (e) => {
    if (e.key === 'Enter') this.handleOnSearch();
    else if (this.state.searchValue && e.key === 'Escape')
      this.clearSearchResults();
  };

  onAddClientClick = () => {
    const prevState = new Client();
    this.setState({
      addMode: true,
      isEditMode: true,
      activeItemIndex: -1,
      activeTab: 0,
      formPrevState: prevState,
      clientForm: prevState,
    });
  };

  onEditClick = () => {
    const prevState = _.cloneDeep(this.props.clientForm);
    this.setState({
      addMode: false,
      isEditMode: true,
      formPrevState: prevState,
    });
  };

  onPreviewOpen = (url = '') => {
    this.setState({ isImageViewerVisible: true });
  };

  onPreviewClose = () => {
    this.setState({ isImageViewerVisible: false });
  };

  onMainImageClear = () => {
    this.setState((prevState) => {
      return {
        clientForm: {
          ...prevState.clientForm,
          images: {
            preview: '',
            full: '',
          },
        },
        isImageChange: true,
      };
    });
  };

  onUpdatePreviewImage = (image) => {
    this.setState((prevState) => {
      return {
        isCropModalShow: false,
        clientForm: {
          ...prevState.clientForm,
          images: { ...prevState.clientForm.images, preview: image },
        },
      };
    });
  };

  //
  _renderConfirm() {
    const { activeItemIndex, openConfirm } = this.state;
    const { clientsList } = this.props;
    const content = `${
      clientsList[activeItemIndex] ? clientsList[activeItemIndex].title : ''
    } ?`;
    const header = i18next.t('Delete item');

    return (
      <Confirm
        open={openConfirm}
        header={header}
        content={content}
        onCancel={this.closeConfirm}
        onConfirm={() => {
          this.deleteActiveItem(activeItemIndex);
          this.closeConfirm();
        }}
        confirmButton={i18next.t('Yes')}
        cancelButton={i18next.t('No')}
      />
    );
  }

  _renderActionPanel() {
    const { clientsList } = this.props;
    const { activeItemIndex, isEditMode, isSaving } = this.state;
    return (
      <Menu className="tool-bar" stackable>
        <Menu.Item className="section-name" header>
          {i18next.t('Clients')}
        </Menu.Item>
        {!isEditMode && (
          <Menu.Item
            className={classNames({ 'form-group-disable': isEditMode })}
          >
            <Button
              icon
              primary
              labelPosition="left"
              onClick={this.onAddClientClick}
            >
              <Icon name="plus" />
              {i18next.t('Add Client')}
            </Button>
            {clientsList[activeItemIndex] !== undefined && (
              <Button
                positive
                disabled={!(activeItemIndex >= 0)}
                onClick={this.onEditClick}
              >
                {i18next.t('Edit')}
              </Button>
            )}
            {clientsList[activeItemIndex] !== undefined && (
              <Button
                color="red"
                disabled={!(activeItemIndex >= 0)}
                onClick={this.openConfirm}
              >
                {i18next.t('Delete')}
              </Button>
            )}
          </Menu.Item>
        )}
        {isEditMode && (
          <Menu.Item
            className={classNames({ 'form-group-disable': isSaving })}
            disabled={isSaving}
          >
            <Button positive onClick={this.saveBtnHandle}>
              {i18next.t('Save')}
            </Button>
            <Button onClick={this.cancelBtnHandle}>
              {i18next.t('Cancel')}
            </Button>
          </Menu.Item>
        )}
        <Menu.Item className="section-message" header>
          <StatusMessage />
        </Menu.Item>
      </Menu>
    );
  }

  _renderSideBarPanel() {
    const { clientsList } = this.props;
    const {
      isSearchLoading,
      searchResults,
      searchValue,
      activeItemIndex,
      isEditMode,
    } = this.state;
    const activeClientsList =
      searchResults.length > 0 ? searchResults : clientsList;
    return (
      <Segment
        className={classNames('form-group', {
          'form-group-disable': isEditMode,
        })}
      >
        <Input
          disabled={isSearchLoading}
          className="search-input"
          loading={isSearchLoading}
          action={this.getSearchActionButton()}
          placeholder={i18next.t('Search client...')}
          onChange={this.handleOnSearchChange}
          onKeyDown={this.onSearchKeyDown}
          value={searchValue}
        />
        <List divided relaxed className="user-list">
          {activeClientsList.map((item, index) => {
            return (
              <List.Item
                as="a"
                key={`client-list-item-${index}`}
                active={activeItemIndex === index}
                onClick={() => {
                  this.onClientClick({ id: item.id, index });
                }}
              >
                {item.image ? (
                  <Image src={item.image} size="mini" />
                ) : (
                  <List.Icon name="user" size="large" verticalAlign="middle" />
                )}
                <List.Content>
                  <List.Header>{item.title}</List.Header>
                  <List.Description>{item.driver_license}</List.Description>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </Segment>
    );
  }

  _renderForm() {
    const {
      isEditMode,
      isCropModalShow,
      isImageChange,
      clientForm: { images },
    } = this.state;

    const { preview, full } =
      isImageChange && images?.preview ? images : images;
    const isStartUpload = !!(
      isImageChange && typeof images.preview === 'object'
    );

    return (
      <Segment>
        <CropImageModal
          onCropped={(image) => this.onUpdatePreviewImage(image)}
          onCloseModal={() =>
            this.setState({ isCropModalShow: false, uploadingImage: null })
          }
          isCropModalShow={isCropModalShow}
          image={full}
        />
        <Form>
          <Grid>
            <Grid.Column width={6}>
              <Grid.Row>
                <Grid.Column>
                  <CardImage
                    isEditMode={isEditMode}
                    imageUrl={preview}
                    isHasImage={this.state.uploadedImageId <= 0}
                    onImageLoad={() => this.fileLoad.current.click()}
                    onImageClear={() => {
                      this.onMainImageClear();
                    }}
                    onPreview={() => this.onPreviewOpen()}
                    isUploadImageState={isStartUpload}
                  />
                  <div ref={this.formUploadRef}>
                    <input
                      type="file"
                      name="file-load"
                      ref={this.fileLoad}
                      hidden
                      onChange={this.loadUploadImage}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={10} className="client-main-info-wrapper">
              <Tabs
                selectedIndex={this.state.activeTab}
                onSelect={(e) => this.setState({ activeTab: e })}
              >
                <TabList>
                  <Tab>{i18next.t('Personal information')}</Tab>
                  <Tab>{i18next.t('Address')}</Tab>
                  <Tab>{i18next.t('Payment info ')}</Tab>
                  <Tab>{i18next.t('Rental history')}</Tab>
                </TabList>
                <TabPanel
                  className={classNames('form-group', 'tab-wrapper', {
                    'form-group-disable': !this.state.isEditMode,
                  })}
                >
                  <div className="tab-wrapper-inner">
                    {this._renderPersonalInformation()}
                  </div>
                </TabPanel>
                <TabPanel
                  className={classNames('form-group', 'tab-wrapper', {
                    'form-group-disable': !this.state.isEditMode,
                  })}
                >
                  <div className="tab-wrapper-inner">
                    {this._renderAddressInformation()}
                  </div>
                </TabPanel>
                <TabPanel
                  className={classNames('form-group', 'tab-wrapper', {
                    'form-group-disable': !this.state.isEditMode,
                  })}
                >
                  <div className="tab-wrapper-inner">
                    {this._renderPaymentInfo()}
                  </div>
                </TabPanel>
                <TabPanel
                  className={classNames('form-group', 'tab-wrapper', {
                    'form-group-disable': !this.state.isEditMode,
                  })}
                >
                  <div className="tab-wrapper-inner equal-padding">
                    <RentalHistory
                      rentalHistory={this.props.clientForm.agreements}
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </Grid.Column>
          </Grid>
        </Form>
      </Segment>
    );
  }

  _renderPersonalInformation() {
    const {
      birth_date,
      driver_license,
      driver_license_category,
      driver_license_exp_date,
      email,
      family_name,
      first_name,
      mobile_phone,
    } = this.state.clientForm;
    return (
      <>
        <Form.Input
          inline
          label={i18next.t('First name')}
          placeholder={i18next.t('First name')}
          value={first_name}
          name="first_name"
          onChange={this.handleInputChange}
          error={this.getFieldError('first_name')}
        />
        <Form.Input
          inline
          label={i18next.t('Last name')}
          name="family_name"
          placeholder={i18next.t('Last name')}
          value={family_name}
          onChange={this.handleInputChange}
          error={this.getFieldError('family_name')}
        />
        <DateInput
          className="inline"
          onChange={this.handleCalendarChange}
          value={birth_date}
          name="birth_date"
          animation=""
          dateFormat="DD.MM.YYYY"
          clearable
          clearIcon={<Icon name="remove" color="red" />}
          autoComplete="off"
          closable
          hideMobileKeyboard
          popupPosition="bottom right"
          placeholder={i18next.t('Birth date')}
          label={i18next.t('Birth date')}
        />
        <Form.Input
          inline
          label={i18next.t('Mobile phone')}
          placeholder={i18next.t('Mobile phone')}
          value={mobile_phone}
          name="cell_phone"
          onChange={this.handleInputChange}
        />
        <Form.Input
          inline
          label={i18next.t('Email')}
          placeholder={i18next.t('Email')}
          value={email}
          name="email"
          onChange={this.handleInputChange}
        />
        <Form.Input
          inline
          label={i18next.t('License No')}
          placeholder={i18next.t('Driving License No')}
          value={driver_license}
          name="driver_license"
          onChange={this.handleInputChange}
        />
        <Form.Input
          inline
          label={i18next.t('State of Issuance ')}
          placeholder={i18next.t('State of Issuance')}
          value={driver_license_category}
          name="driver_license_category"
          onChange={this.handleInputChange}
        />
        <DateInput
          className="inline"
          onChange={this.handleCalendarChange}
          animation=""
          dateFormat="DD.MM.YYYY"
          clearable
          clearIcon={<Icon name="remove" color="red" />}
          autoComplete="off"
          closable
          hideMobileKeyboard
          popupPosition="bottom right"
          placeholder={i18next.t('Validity')}
          label={i18next.t('Validity')}
          value={driver_license_exp_date}
          name="driver_license_exp_date"
        />
      </>
    );
  }

  _renderAddressInformation() {
    const { home_address, zip_code, state, city } = this.state.clientForm;

    return (
      <>
        <Form.Input
          className="inline"
          label={i18next.t('Local home address')}
          placeholder={i18next.t('Home address')}
          value={home_address}
          name="home_address"
          onChange={this.handleInputChange}
        />
        <Form.Input
          inline
          label={i18next.t('City')}
          placeholder={i18next.t('City')}
          value={city}
          name="city"
          onChange={this.handleInputChange}
        />
        <Form.Input
          inline
          label={i18next.t('State')}
          placeholder={i18next.t('State')}
          value={state}
          name="state"
          onChange={this.handleInputChange}
        />
        <Form.Input
          inline
          label={i18next.t('Zip code')}
          placeholder={i18next.t('Zip code')}
          value={zip_code}
          name="zip_code"
          onChange={this.handleInputChange}
        />
      </>
    );
  }

  _renderPaymentInfo() {
    const { card_type, card_number, exp_date, name_of_card, cvv } =
      this.state.clientForm;
    return (
      <>
        <Form.Select
          inline
          placeholder="Select card type"
          options={cardType}
          label={i18next.t('Card type')}
          value={card_type}
          name="card_type"
          onChange={this.handleSelectChange}
        />
        <Form.Input
          inline
          label={i18next.t('Card No')}
          placeholder={i18next.t('Card No')}
          children={
            <InputMask
              name="card_number"
              mask="9999-9999-9999-9999"
              onChange={this.handleInputChange}
              value={card_number}
            />
          }
        />
        <Form.Input
          inline
          label={i18next.t('Name of card')}
          placeholder={i18next.t('Name of card')}
          value={name_of_card}
          name="name_of_card"
          onChange={this.handleInputChange}
        />
        <DateInput
          className="inline"
          onChange={this.handleCalendarChange}
          animation=""
          dateFormat="DD.MM.YYYY"
          minDate={moment().add(5, 'days')}
          clearable
          clearIcon={<Icon name="remove" color="red" />}
          autoComplete="off"
          closable
          hideMobileKeyboard
          popupPosition="bottom right"
          label={i18next.t('Expiration date')}
          placeholder={i18next.t('Expiration date')}
          value={exp_date}
          name="exp_date"
        />
        <Form.Input
          inline
          label={i18next.t('Security code')}
          placeholder={i18next.t('Security code')}
          value={cvv}
          name="cvv"
          onChange={this.handleInputChange}
        />
      </>
    );
  }

  _renderImagePreview() {
    const {
      isImageChange,
      isImageViewerVisible,
      clientForm: { images },
    } = this.state;
    const full = isImageChange
      ? AppConfig.serverRoot + images?.full
      : AppConfig.serverRoot + images?.full;

    if (!isImageViewerVisible) return;

    return (
      <ImageViewer
        ref={this.previewRef}
        image={full}
        mainImage={full}
        gallery={[]}
        onPreviewClose={this.onPreviewClose}
      />
    );
  }

  render() {
    return (
      <div className="Clients catalog-tabs">
        {this._renderConfirm()}
        {this._renderActionPanel()}
        {this._renderImagePreview()}
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={4}>{this._renderSideBarPanel()}</Grid.Column>
            <Grid.Column>{this._renderForm()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = {
  deleteClient,
  getClientSearch,
  getSingleClient,
  loadClientList,
  saveClient,
};

const mapStateToProps = (state) => {
  const { isDataLoading } = state.ui;
  const { clientsList, clientForm } = state.clients;
  return {
    isDataLoading,
    clientsList,
    clientForm,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
