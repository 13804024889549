import { Model } from './Model.js';

export class Client extends Model {
  constructor(props) {
    super(props);
    this.images = this.getImages();
  }

  get defaults() {
    return {
      id: undefined,
      agreements: [],
      birth_date: undefined,
      card_number: '',
      card_type: 0,
      cell_phone: '',
      city: '',
      company_name: '',
      country: '',
      cvv: '',
      driver_license: '',
      driver_license_category: '',
      driver_license_exp_date: undefined,
      email: '',
      exp_date: undefined,
      family_name: '',
      files: null,
      first_name: '',
      home_address: '',
      insurience_exp_date: '',
      insurience_fax: '',
      insurience_police_number: '',
      insuriense_company: '',
      insuriense_phone: '',
      local_address: '',
      local_phone: '',
      mobile_phone: '',
      name_of_card: '',
      passport_id: '',
      place_of_birth: '',
      state: '',
      work_phone: '',
      zip_code: '',
    };
  }

  getImages() {
    const images = this.files ? JSON.parse(this.files) : {};
    // eslint-disable-next-line no-prototype-builtins
    if (!images.hasOwnProperty('preview')) images.preview = '';
    // eslint-disable-next-line no-prototype-builtins
    if (!images.hasOwnProperty('full')) images.full = '';

    delete this.files;
    return images;
  }
}
