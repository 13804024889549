import useSWR from 'swr';
import { agreementsApi } from '../api';
import { Agreement } from '../../common/models/Agreement';

const fetcherSigningAgreement = (uid) =>
  agreementsApi.getSigningAgreement(uid).then((res) => res.data);

export function getSigningAgreementData(uid) {
  const { data, error } = useSWR(uid, fetcherSigningAgreement, {
    revalidateOnFocus: false,
  });

  return {
    data: {
      agreement: new Agreement(data?.agreement),
      settings: data?.settings,
    },
    isAgreementLoading: !error && !data,
    isAgreementError: error,
  };
}
