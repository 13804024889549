import React, { Component } from 'react';
import {
  Grid,
  Menu,
  Segment,
  Input,
  List,
  Table,
  Header,
  Divider,
} from 'semantic-ui-react';
import i18next from 'i18next';

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Menu className="tool-bar" stackable>
          <Menu.Item className="section-name" header>
            {i18next.t('Invoices')}
          </Menu.Item>
          <Menu.Item name="features">
            <Input icon="search" placeholder="Search..." />
          </Menu.Item>
        </Menu>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <Table compact selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        {i18next.t('Invoice Nr.')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {i18next.t('Invoice Date')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {i18next.t('Vehicle')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {i18next.t('Stock Nr.')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {i18next.t('Start Date')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {i18next.t('End Date')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {i18next.t('First Name')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {i18next.t('Last Name')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>{i18next.t('Amount')}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>1</Table.Cell>
                      <Table.Cell>18/10/2019</Table.Cell>
                      <Table.Cell>Kia Rio</Table.Cell>
                      <Table.Cell>123</Table.Cell>
                      <Table.Cell>18/10/2019</Table.Cell>
                      <Table.Cell>17/10/2019</Table.Cell>
                      <Table.Cell>Ivan</Table.Cell>
                      <Table.Cell>Varava</Table.Cell>
                      <Table.Cell>100$</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>2</Table.Cell>
                      <Table.Cell>18/10/2019</Table.Cell>
                      <Table.Cell>Ford</Table.Cell>
                      <Table.Cell>321</Table.Cell>
                      <Table.Cell>18/10/2019</Table.Cell>
                      <Table.Cell>17/10/2019</Table.Cell>
                      <Table.Cell>Ivan</Table.Cell>
                      <Table.Cell>Varava</Table.Cell>
                      <Table.Cell>200$</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>3</Table.Cell>
                      <Table.Cell>18/10/2019</Table.Cell>
                      <Table.Cell>Cadillac</Table.Cell>
                      <Table.Cell>234</Table.Cell>
                      <Table.Cell>18/10/2019</Table.Cell>
                      <Table.Cell>17/10/2019</Table.Cell>
                      <Table.Cell>Ivan</Table.Cell>
                      <Table.Cell>Varava</Table.Cell>
                      <Table.Cell>400$</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
            <Grid.Column width={4}>
              <Segment>
                <Header sub>{i18next.t('Driver details')}</Header>
                <Divider />
                <List size="small">
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Invoice Nr.')}</List.Header>
                      <List.Description>1</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Last name')}</List.Header>
                      <List.Description>Varava</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('First name')}</List.Header>
                      <List.Description>Ivan</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('First name')}</List.Header>
                      <List.Description>Ivan</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Birth date')}</List.Header>
                      <List.Description>-</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Id')}</List.Header>
                      <List.Description>-</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Address')}</List.Header>
                      <List.Description>-</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Phone')}</List.Header>
                      <List.Description>-</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Work phone')}</List.Header>
                      <List.Description>-</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Email')}</List.Header>
                      <List.Description>-</List.Description>
                    </List.Content>
                  </List.Item>
                </List>
                <Header sub>{i18next.t('Rental details')}</Header>
                <Divider />
                <List size="small">
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Vehicle')}</List.Header>
                      <List.Description>Kia Rio</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Start date')}</List.Header>
                      <List.Description>18/10/2019 20:00</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('End date')}</List.Header>
                      <List.Description>19/10/2019 20:00</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Total amount')}</List.Header>
                      <List.Description>100</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Amount paid')}</List.Header>
                      <List.Description>0</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Header>{i18next.t('Amount due')}</List.Header>
                      <List.Description>0</List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Invoices;
