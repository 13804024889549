import axios from 'axios';
import i18next from 'i18next';
import AppConfig from '../../config';
import { tokenConfig } from '../user/auth';
import {
  CREATE_EXPENSES,
  CREATE_EXPENSES_FAIL,
  SAVE_VEHICLE_FAIL,
  SHOW_STATUS_MESSAGE,
} from '../types';
import { SET_DATA_LOADING, showStatusMessage } from '../UiActions';
import { statusMessages } from '../../common/catalogs/constants';

export const createExpenses = (expenses) => async (dispatch, getState) => {
  dispatch({
    type: SET_DATA_LOADING,
    payload: true,
  });
  try {
    const res = await axios.put(
      `${AppConfig.apiUrl}/expenses/addexpenses`,
      expenses,
      tokenConfig(getState)
    );
    dispatch({
      type: CREATE_EXPENSES,
    });
    dispatch({
      type: SHOW_STATUS_MESSAGE,
      payload: {
        messageType: 'success',
        statusMessage: i18next.t(statusMessages.expensesAddSuccess),
      },
    });
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: CREATE_EXPENSES_FAIL,
    });
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
    dispatch(
      showStatusMessage('error', i18next.t(statusMessages.expensesAddError))
    );
  }
};

export const deleteExpenses = (index) => async (dispatch, getState) => {
  dispatch({
    type: SET_DATA_LOADING,
    payload: true,
  });
  try {
    const res = await axios.delete(
      `${AppConfig.apiUrl}/expenses/deleteexpenses`,
      index,
      tokenConfig(getState)
    );
    dispatch({
      type: CREATE_EXPENSES,
    });
    dispatch({
      type: SHOW_STATUS_MESSAGE,
      payload: {
        messageType: 'success',
        statusMessage: i18next.t(statusMessages.expensesAddSuccess),
      },
    });
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: CREATE_EXPENSES_FAIL,
    });
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
    dispatch(
      showStatusMessage('error', i18next.t(statusMessages.expensesAddError))
    );
  }
};
