import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from '../reducers';
import Interceptor from '../classes/axios_interceptor';

const listMiddleware = [thunk];
const middleware = applyMiddleware(...listMiddleware);
export const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(middleware)
);
Interceptor.interceptor(store);
