import { Model } from './Model.js';
import AppConfig from '../../config';

export class VehicleListItem extends Model {
  constructor(props) {
    super(props);
    this.image = this.getImage();
  }

  get defaults() {
    return {
      id: undefined,
      make: '',
      model: '',
      year: undefined,
    };
  }

  getImage() {
    return this.files
      ? `${AppConfig.serverRoot}${JSON.parse(this.files).preview}`
      : '';
  }

  get vehicle() {
    return `${this.make} ${this.model}`;
  }
}
