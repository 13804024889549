// Auth
import { SET_DATA_LOADING } from './UiActions';

export const IS_USER_AUTH = 'IS_USER_AUTH';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const RELEASE_LOADED = 'RELEASE_LOADED';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';

export const USER_LOADED = 'USER_LOADED';
export const USER_DATA = 'USER_DATA';

export const LOAD_CLIENTS_LIST = 'LOAD_CLIENTS_LIST';
export const LOAD_CLIENTS_LIST_FAIL = 'LOAD_CLIENTS_LIST';
export const LOAD_CLIENT = 'LOAD_CLIENT';
export const LOAD_CLIENT_FAIL = 'LOAD_CLIENT_FAIL';
export const LOAD_CLIENT_PREV_STATE = 'LOAD_CLIENT_PREV_STATE';

export const LOAD_VEHICLES = 'LOAD_VEHICLES';
export const LOAD_VEHICLES_FAIL = 'LOAD_VEHICLES_FAIL';
export const LOAD_VEHICLE = 'LOAD_VEHICLE';
export const LOAD_VEHICLE_FAIL = 'LOAD_VEHICLE_FAIL';
export const LOAD_VEHICLE_PREV_STATE = 'LOAD_VEHICLE_PREV_STATE';
export const SAVE_VEHICLE = 'SAVE_VEHICLE';
export const SAVE_VEHICLE_FAIL = 'SAVE_VEHICLE_FAIL';

export const LOAD_EXPENSES = 'LOAD_EXPENSES';
export const LOAD_EXPENSES_FAIL = 'LOAD_VEHICLE_FAIL';
export const CREATE_EXPENSES = 'CREATE_EXPENSES';
export const CREATE_EXPENSES_FAIL = 'CREATE_EXPENSES_FAIL';
export const UPDATE_EXPENSES = 'UPDATE_EXPENSES';
export const UPDATE_EXPENSES_FAIL = 'UPDATE_EXPENSES_FAIL';
export const DELETE_EXPENSES = 'DELETE_EXPENSES';
export const DELETE_EXPENSES_FAIL = 'DELETE_EXPENSES_FAIL';

export const SHOW_STATUS_MESSAGE = 'SHOW_STATUS_MESSAGE';
export const HIDE_STATUS_MESSAGE = 'HIDE_STATUS_MESSAGE';

export const NEW_AGREEMENT_MODAL_SHOW = 'SHOW_NEW_AGREEMENT_MODAL_SHOW';
export const AGREEMENT_FORM_STEP = 'AGREEMENT_FORM_STEP';
export const HIDE_AGREEMENT_MODAL_SHOW = 'HIDE_AGREEMENT_MODAL_SHOW';
export const LOAD_FREE_VEHICLES_LIST = 'LOAD_FREE_VEHICLES_LIST';
export const RESET_FREE_VEHICLES_LIST = 'RESET_FREE_VEHICLES_LIST';
export const LOAD_DRIVER_LIST = 'LOAD_DRIVER_LIST';
export const LOAD_DRIVER_INFO = 'LOAD_DRIVER_INFO';
export const LOAD_AGREEMENTS_LIST = 'LOAD_AGREEMENTS_LIST';
export const AGREEMENT_APPLY_SUCCESS = 'AGREEMENT_APPLY_SUCCESS';
export const AGREEMENT_SIGN_SENT_SUCCESS = 'AGREEMENT_SIGN_SENT_SUCCESS';
export const AGREEMENT_NEED_UPDATE = 'AGREEMENT_NEED_UPDATE';
export const LOAD_ALL_AGREEMENTS_LIST = 'LOAD_ALL_AGREEMENTS_LIST';
export const ALL_AGREEMENTS_SET_TAB_INDEX = 'ALL_AGREEMENTS_SET_TAB_INDEX';
export const ALL_AGREEMENTS_SET_FINISH = 'ALL_AGREEMENTS_SET_FINISH';

export const SETTINGS_GET_SETTINGS = 'SETTINGS_GET_SETTINGS';
export const SETTINGS_UPDATE_SETTINGS = 'SETTINGS_UPDATE_SETTINGS';
export const SETTINGS_UPLOAD_LOGO = 'SETTINGS_UPLOAD_LOGO';
export const SETTINGS_LOGO_CLEAR = 'SETTINGS_LOGO_CLEAR';
export const SETTINGS_DEFAULT_AGREEMENT_INFO =
  'SETTINGS_DEFAULT_AGREEMENT_INFO';

export const LOCAL_DATA_LOADER = 'LOCAL_DATA_LOADER';
