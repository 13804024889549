import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isAuthLoading = useSelector((state) => state.auth.isAuthLoading);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuth && isAuthLoading)
          return (
            <Dimmer active inverted page>
              <Loader>Loading</Loader>
            </Dimmer>
          );
        if (isAuth) return <Component {...props} />;

        return (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default AuthRoute;
