import Cookies from 'universal-cookie';
import {
  IS_USER_AUTH,
  LOGIN_USER,
  LOGIN_FAIL,
  LOGOUT_USER,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  USER_LOADED,
  RELEASE_LOADED,
  SET_AUTH_LOADING,
} from '../../actions/types';

const cookies = new Cookies();

const initState = {
  isAuth: false,
  isAuthLoading: false,
  name: '',
  email: '',
  company: [],
  loginFailure: '',
  token: cookies.get('token'),
  is_new_release: '',
  new_release_description: '',
  version: '',
};

export default function auth(state = initState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const maxAge = action.isRemember ? 60 * 60 * 24 * 14 : null;
      cookies.remove('token');
      cookies.set('token', action.payload.token, { path: '/', maxAge });
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        company: action.payload.company,
        token: action.payload.token,
        errorMessage: '',
        isAuth: true,
      };
    }
    case LOGIN_FAIL: {
      return { ...state, token: undefined, loginFailure: action.payload };
    }
    case LOGOUT_USER: {
      cookies.remove('token');
      return {
        state: initState,
      };
    }
    case USER_LOADED: {
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        company: action.payload.company,
        is_new_release: action.payload.is_new_release,
        version: action.payload.version,
        isAuth: true,
      };
    }
    case RELEASE_LOADED: {
      return {
        ...state,
        new_release_description: action.payload,
      };
    }
    case AUTH_ERROR: {
      cookies.remove('token');
      return {
        ...state,
        token: null,
        isAuth: false,
      };
    }
    case SET_AUTH_LOADING: {
      return {
        ...state,
        isAuthLoading: action.payload,
      };
    }
    default:
      return state;
  }
}
