import moment from 'moment';
import { Model } from './Model.js';

export class AddDriver extends Model {
  constructor(props) {
    super(props);
  }

  get defaults() {
    return {
      addDriverId: '',
      add_driver_name: '',
      add_driver_home_address: '',
      add_driver_birthdate: '',
      add_phone_number: '',
      add_driver_city: '',
      add_driver_state: '',
      add_driver_zip: '',
      add_driver_license: '',
      add_driver_license_exp_date: '',
    };
  }
}
