import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import styles from './Tags.module.scss';

function Tags(props) {
  const filters = cloneDeep(props.activeFilters);
  return (
    <div className={styles.Tags}>
      <Label.Group color="blue">
        {filters.map((item, index) => {
          if (!item.toggle) return;

          if (Array.isArray(item.name))
            return (
              <Label as="a" key={`tag-${index}`}>
                {`${item.name[0]}/${item.name[1]}:${item.value[0]}-${item.value[1]}`}
                <Icon name="close" onClick={() => props.removeFilter(index)} />
              </Label>
            );
          return (
            <Label as="a" key={`tag-${index}`}>
              {`${item.name}:${item.value[0]}`}
              <Icon name="close" onClick={() => props.removeFilter(index)} />
            </Label>
          );
        })}
      </Label.Group>
    </div>
  );
}

Tags.defaultProps = {
  activeFilters: [],
  removeFilter: () => {},
};

Tags.propTypes = {
  activeFilters: PropTypes.array,
  removeFilter: PropTypes.func,
};
export default React.memo(Tags);
