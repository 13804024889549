import { SHOW_NEW_AGREEMENT_MODAL_SHOW } from '../actions/UiActions';
import {
  AGREEMENT_FORM_STEP,
  HIDE_AGREEMENT_MODAL_SHOW,
  LOAD_AGREEMENTS_LIST,
  LOAD_DRIVER_INFO,
  LOAD_DRIVER_LIST,
  LOAD_FREE_VEHICLES_LIST,
  RESET_FREE_VEHICLES_LIST,
} from '../actions/types';

const initState = {
  agreementsList: [],
  vehiclesList: [],
};

export default function planner(state = initState, action) {
  switch (action.type) {
    case LOAD_AGREEMENTS_LIST:
      return {
        ...state,
        agreementsList: action.payload.agreementsList,
        vehiclesList: action.payload.vehiclesList,
      };
    default:
      return state;
  }
}
