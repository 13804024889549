import { Form } from 'semantic-ui-react';
import React from 'react';
import { mileage } from './constants';

export const vehicleFormInitState = {
  id: '',
  stock_number: '',
  reg_number: '',
  odometer_out: '',
  fuel_out: '',
  vin: '',
  make: '',
  model: '',
  year: '',
  color: '',
  class: 0,
  max_weight: '',
  transmission: 0,
  description: '',
  engine: '',
  type: 0,
  date_sold: new Date(),
  rate_min: '',
  rate_d1: '',
  rate_d2: '',
  rate_d3: '',
  rate_d4: '',
  rate_d5: '',
  rate_d6: '',
  rate_weekly: '',
  rate_monthly: '',
  rate_hourly: '',
  rate_week_extra: '',
  rate_month_extra: '',
  discount_day_rate: '',
  license: '',
  state: '',
  media_id: 0,
};

export const clientsFormInitState = {
  id: '',
  company_name: '',
  family_name: '',
  first_name: '',
  birth_date: '',
  place_of_birth: '',
  home_address: '',
  zip_code: '',
  city: '',
  state: '',
  country: '',
  local_address: '',
  passport_id: '',
  mobile_phone: '',
  work_phone: '',
  local_phone: '',
  cell_phone: '',
  email: '',
  card_type: 0,
  card_number: '',
  name_of_card: '',
  exp_date: '',
  cvv: '',
  driver_license: '',
  driver_license_category: '',
  driver_license_exp_date: '',
  insurience_company: '',
  insurience_phone: '',
  insurience_fax: '',
  insurience_police_number: '',
  insurience_exp_date: '',
  doc_link_1: '',
  doc_link_2: '',
  doc_link_3: '',
};

export const expensesFormInitState = {
  type: 0,
  details: '',
  date: '',
  amount: '',
  notes: '',
};

export const agreementsFormInitState = {
  client_name: '',
  id: '',
  client_id: '',
  driver_id: '',
  company_name: '',
  family_name: '',
  first_name: '',
  birth_date: '',
  place_of_birth: '',
  home_address: '',
  zip_code: '',
  city: '',
  state: '',
  country: '',
  local_address: '',
  passport_id: '',
  mobile_phone: '',
  work_phone: '',
  local_phone: '',
  cell_phone: '',
  email: '',
  exp_date: '',
  cvv: '',
  insurience_company: '',
  insurience_police_number: '',
  insurience_exp_date: '',
  driver_license_exp_date: '',
  driver_license: '',
  doc_link_1: '',
  doc_link_2: '',
  doc_link_3: '',
  add_driver_home_address: '',
  add_driver_birthdate: '',
  add_phone_number: '',
  add_driver_city: '',
  add_driver_state: '',
  add_driver_zip: '',
  add_driver_lic_no: '',
  add_driver_ins: '',
  add_driver_ins_date: '',
  // Vehicle
  vin: '',
  stock_number: '',
  make: '',
  model: '',
  year: '',
  // Fuel
  vehicle_license: '',
  fuel_in: '',
  fuel_out: '',
  vehicle_odometer_in: '',
  vehicle_odometer_out: '',
  vehicle_miles_driven: '',
  vehicle_free_miles: '',
  vehicle_chargeable_miles: '',
  // Rental details
  agreement_date: new Date(),
  dt_start: '',
  dt_end: '',
  // Location
  pick_location: '',
  pick_instruction: '',
  drop_location: '',
  drop_instruction: '',
  // Payment info
  deposit_method: 0,
  card_number: '',
  name_of_card: '',
  security_code: '',
  deposit_amount: '',
  full_payment: '',
  //
  contract: '',
  delivered: '',
  // Invoice
  rent_days: '',
  rate_d1: '',
  rent_days_total: '',
  rent_month: '',
  rate_monthly: '',
  rent_month_total: '',
  rent_ticket: '',
  rent_ticket_cost: '',
  rent_ticket_total: '',
  rent_chargeable_mils: '',
  rent_chargeable_mils_total: '',
  rent_chargeable_mils_cost: '',
  rent_full_cost: '',
  vclass: 0,
};

export const agreementValidStepStatus = {
  1: [false, false],
  2: [false, false],
  3: [false, false],
  4: [false, false],
  5: [false, false],
  6: [false, false],
  7: [false, false],
  8: [false, false],
  9: [false, false],
};

export const setupInitState = {
  company_name: '',
  owner_name: '',
  vat: '',
  country: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  fax: '',
  email: '',
  webpage: '',
  warnings: '',
  terms: '',
  currency: '$',
  mileage: 'km',
};

export const vehicleSearchFilter = [
  {
    name: '',
    value: [],
    toggle: false,
    linkName: '',
  },
];
