import React, { Component } from 'react';
import { Button, Card, Confirm, Form, Icon, Table } from 'semantic-ui-react';
import i18next from 'i18next';
import MaskedInput from 'react-text-mask/dist/reactTextMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import Modal from 'react-modal';
import { DateInput } from 'semantic-ui-calendar-react';
import currencyFormatter from 'currency-formatter';
import { connect } from 'react-redux';
import { expensesFormInitState } from '../catalogs/initstates';
import { currencyList, expenseType } from '../catalogs/constants';

class Expenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editExpenses: false,
      openConfirm: false,
      delIndex: -1,
      delTitle: '',
      showNewExpenses: false,
      formControls: {
        type: 0,
        details: '',
        date: '',
        amount: '',
        notes: '',
      },
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.editExpensesData !== this.props.editExpensesData) {
      this.mapData();
    }

    if (prevProps.completeOperation !== this.props.completeOperation)
      this.setState({ showNewExpenses: !this.props.completeOperation });
  }

  componentDidMount() {
    this.mapData();
  }

  handleInputChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    this.setState((prevState, props) => {
      return { formControls: { ...prevState.formControls, [name]: value } };
    });
  };

  handleSelectChange = (e, result) => {
    const { name } = result;
    const { value } = result;
    this.setState((prevState, props) => {
      return { formControls: { ...prevState.formControls, [name]: value } };
    });
  };

  handleCalendarChange = (event, { name, value }) => {
    this.setState((prevState, props) => {
      return {
        formControls: {
          ...prevState.formControls,
          [name]: moment(value, 'DD-MM-YYYY'),
        },
      };
    });
  };

  mapData = () => {
    const { editExpensesData } = this.props;
    this.setState({ formControls: editExpensesData });
  };

  render() {
    const { expenses, isEditMode, selCurrency } = this.props;
    return (
      <React.Fragment>
        {this._renderNewExpenses()}
        {this._renderConfirm()}
        <Table celled selectable compact className="expenses-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell> {i18next.t('Date')}</Table.HeaderCell>
              <Table.HeaderCell> {i18next.t('Type')}</Table.HeaderCell>
              <Table.HeaderCell> {i18next.t('Amount')}</Table.HeaderCell>
              <Table.HeaderCell> {i18next.t('Details')}</Table.HeaderCell>
              <Table.HeaderCell> {i18next.t('Notes')}</Table.HeaderCell>
              <Table.HeaderCell> </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {expenses.map((item, index) => (
              <Table.Row key={`exp-element-${index}`}>
                <Table.Cell>
                  {moment(item.date).format('DD.MM.YYYY')}
                </Table.Cell>
                <Table.Cell>{expenseType[item.type].text}</Table.Cell>
                <Table.Cell>
                  {currencyFormatter.format(item.amount, { code: selCurrency })}
                </Table.Cell>
                <Table.Cell>{item.details}</Table.Cell>
                <Table.Cell width={3}>
                  {item.notes.length > 30
                    ? `${item.notes.substr(0, 30)}...`
                    : item.notes}
                </Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  {isEditMode && (
                    <>
                      <Button
                        compact
                        icon
                        primary
                        size="small"
                        onClick={() => {
                          this.props.loadExpensesItem(index);
                          this.setState({
                            showNewExpenses: true,
                            editExpenses: true,
                          });
                        }}
                      >
                        <Icon name="edit" />
                      </Button>
                      <Button
                        compact
                        icon
                        negative
                        size="small"
                        onClick={() =>
                          this.setState({
                            openConfirm: true,
                            delTitle: `${moment(item.date).format(
                              'DD.MM.YYYY'
                            )} - ${item.details}`,
                            delIndex: item.id,
                          })
                        }
                      >
                        <Icon name="close" />
                      </Button>
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                {isEditMode && (
                  <Button
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="small"
                    onClick={() =>
                      this.setState({
                        showNewExpenses: true,
                        editExpenses: false,
                        formControls: expensesFormInitState,
                      })
                    }
                  >
                    <Icon name="user" /> Add
                  </Button>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </React.Fragment>
    );
  }

  _renderNewExpenses = () => {
    const { showNewExpenses, editExpenses } = this.state;
    const { details, date, amount, type, notes } = this.state.formControls;
    const { expensesLoading, expensesSaveData, selCurrency } = this.props;
    const numberMask = createNumberMask({
      prefix: currencyFormatter.findCurrency(selCurrency).symbol,
      suffix: '',
      allowDecimal: true,
    });
    if (!showNewExpenses) return null;

    return (
      <Modal
        isOpen={showNewExpenses}
        onRequestClose={() =>
          this.setState({ showNewExpenses: false, editExpenses: false })
        }
        shouldCloseOnOverlayClick
        overlayClassName="modalOverlay"
        className="modalExpenses"
      >
        <Card fluid>
          <div
            className={classNames('ui active centered', {
              loader: expensesLoading,
            })}
          />
          {expensesLoading && <div className="loader-overlay" />}
          <Card.Content extra className="card-header">
            <Card.Header>
              {editExpenses
                ? i18next.t('Edit expenses')
                : i18next.t('Add new expenses')}
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <Form className="attached fluid segment">
              <Form.Group widths="equal">
                <Form.Select
                  fluid
                  label={i18next.t('Expense type')}
                  name="type"
                  options={expenseType}
                  placeholder={i18next.t('Select expense type')}
                  value={type}
                  onChange={this.handleSelectChange}
                />
                <DateInput
                  name="date"
                  animation=""
                  clearable
                  clearIcon={<Icon name="remove" color="red" />}
                  autoComplete="off"
                  closable
                  hideMobileKeyboard
                  popupPosition="bottom right"
                  placeholder={i18next.t('Date')}
                  label={i18next.t('Date')}
                  value={date ? moment(date).format('MM.DD.YYYY') : ''}
                  onChange={this.handleCalendarChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label={i18next.t('Amount')}
                  children={
                    <MaskedInput
                      name="amount"
                      mask={numberMask}
                      placeholder={i18next.t('Amount')}
                      onChange={this.handleInputChange}
                      value={amount}
                    />
                  }
                />
                <Form.Input
                  label={i18next.t('Details')}
                  placeholder={i18next.t('Details')}
                  name="details"
                  value={details || ''}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.TextArea
                  label={i18next.t('Notes')}
                  name="notes"
                  placeholder={i18next.t('Notes')}
                  value={notes || ''}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            </Form>
          </Card.Content>
          <Card.Content extra>
            <div className="ui two buttons">
              <Button
                positive
                className={classNames({ loading: expensesSaveData })}
                onClick={() => {
                  this.props.saveExpensesItem(
                    this.state.formControls,
                    editExpenses
                  );
                  this.setState({ showNewExpenses: false });
                }}
              >
                {editExpenses ? i18next.t('Save') : i18next.t('Add')}
              </Button>
              <Button
                negative
                onClick={() => this.setState({ showNewExpenses: false })}
              >
                {i18next.t('Cancel')}
              </Button>
            </div>
          </Card.Content>
        </Card>
      </Modal>
    );
  };

  _renderConfirm = () => {
    const { openConfirm, delIndex, delTitle } = this.state;
    const content = `${delTitle}?`;
    const header = i18next.t('Delete item');
    return (
      <Confirm
        open={openConfirm}
        header={header}
        content={content}
        onCancel={() => {
          this.setState({ openConfirm: false });
        }}
        onConfirm={() => {
          this.props.deleteExpensesItem(delIndex);
          this.setState({ openConfirm: false, delIndex: -1, delTitle: '' });
        }}
        confirmButton={i18next.t('Yes')}
        cancelButton={i18next.t('No')}
      />
    );
  };
}

const mapStateToProps = (state) => {
  const { currency } = state.settings;
  const selCurrency = find(currencyList, (o) => o.value === currency);

  return {
    selCurrency: selCurrency.text,
  };
};

Expenses.defaultProps = {
  expenses: [],
};

Expenses.propTypes = {
  loadExpensesItem: PropTypes.func,
  expenses: PropTypes.array,
  editExpensesData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  expensesLoading: PropTypes.bool,
  expensesSaveData: PropTypes.bool,
  completeOperation: PropTypes.bool,
  saveExpensesItem: PropTypes.func,
  isEditMode: PropTypes.bool,
};

export default connect(mapStateToProps)(Expenses);
