import React, { useEffect, useState } from 'react';
import AppConfig from '../../../config';

function CountDownLabel(props) {
  const [timerLabel, setTimerLabel] = useState('00:00');
  const [timerValue, setTimerValue] = useState(AppConfig.agreementSignTimeout);

  useEffect(() => {
    startTimer();
  }, [timerValue]);

  const startTimer = () => {
    if (timerValue >= 0) handleTimer();
    else props.callback();
  };

  const handleTimer = () => {
    setTimeout(() => {
      let timer = timerValue;
      let minutes = parseInt(timer / 60, 10);
      let seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      setTimerLabel(`${minutes}:${seconds}`);
      setTimerValue(--timer);
    }, 1000);
  };
  return <div className="count-down-timer">{timerLabel}</div>;
}

export default CountDownLabel;
