import React, { useEffect, useRef, useState } from 'react';
import { Image, Popup, Table } from 'semantic-ui-react';
import i18next from 'i18next';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { carClass } from '../../../catalogs/constants';
import AppConfig from '../../../../config';
import ImageViewer from '../../shared/ImageViewer';

import styles from '../NewAgreement.module.scss';

function ReactTable(props) {
  return null;
}

ReactTable.propTypes = {
  className: PropTypes.string,
  defaultPageSize: PropTypes.number,
  style: PropTypes.shape({ height: PropTypes.string }),
  columns: PropTypes.any,
};

function SearchResultsTable(props) {
  const [isViewerVisible, setViewerVisible] = useState(false);
  const [galleryUrls, setGalleryUrls] = useState([]);
  const imageViewerRef = useRef(null);

  useEffect(() => {
    return () => {
      setViewerVisible(false);
    };
  }, []);

  const onTablePictureClick = ({ event, index }) => {
    console.log(props.vehicles[index]);
    if (props.vehicles[index].gallery.length) {
      const galleryUrlsArray = [];
      for (const image of props.vehicles[index].gallery)
        galleryUrlsArray.push(`${AppConfig.serverRoot}${image.file}`);
      setGalleryUrls(galleryUrlsArray);
      setViewerVisible(true);
    } else if (props.vehicles[index].images?.preview) {
      setViewerVisible(true);
    }
    event.preventDefault();
  };

  const _renderImage = (imgUrl) => {
    if (imgUrl !== AppConfig.defaultClientImage && imgUrl !== '')
      return (
        <Popup
          trigger={<Image src={AppConfig.serverRoot + imgUrl} size="mini" />}
        >
          <Image src={AppConfig.serverRoot + imgUrl} size="medium" />
        </Popup>
      );
    return <Image src={AppConfig.defaultClientImage} size="mini" />;
  };

  const renderImageViewer = () => {
    if (!isViewerVisible) return;

    const {
      images: { full },
    } = props.vehicles[props.activeVehicleId];
    const mainImage = `${AppConfig.serverRoot}${full}`;
    return (
      isViewerVisible && (
        <ImageViewer
          ref={imageViewerRef}
          image={mainImage}
          mainImage={mainImage}
          gallery={galleryUrls}
          onPreviewClose={() => setViewerVisible(false)}
        />
      )
    );
  };

  const _renderVehiclesList = () => {
    return props.vehicles.map((item, index) => (
      <Table.Row
        key={item.id}
        onClick={() =>
          props.onSetActiveVehicle(props.activeVehicleId !== index ? index : -1)
        }
        className={classNames('search-result-table-row', {
          active: index === props.activeVehicleId,
        })}
      >
        <Table.Cell width={1}>{index + 1}</Table.Cell>
        <Table.Cell onClick={(event) => onTablePictureClick({ event, index })}>
          {_renderImage(item.images?.preview ?? AppConfig.defaultClientImage)}
        </Table.Cell>
        <Table.Cell width={3}>{item.make}</Table.Cell>
        <Table.Cell>{item.model}</Table.Cell>
        <Table.Cell>{item.year}</Table.Cell>
        <Table.Cell>{item.color}</Table.Cell>
        <Table.Cell>{carClass[item.vclass]?.text}</Table.Cell>
        <Table.Cell>{item.rate_min}</Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <>
      {renderImageViewer()}
      <Table className={styles.searchResultsTable} fixed selectable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>#</Table.HeaderCell>
            <Table.HeaderCell> {i18next.t('Pic')}</Table.HeaderCell>
            <Table.HeaderCell width={3}> {i18next.t('Make')}</Table.HeaderCell>
            <Table.HeaderCell> {i18next.t('Model')}</Table.HeaderCell>
            <Table.HeaderCell> {i18next.t('Year')}</Table.HeaderCell>
            <Table.HeaderCell> {i18next.t('Color')}</Table.HeaderCell>
            <Table.HeaderCell> {i18next.t('Class')}</Table.HeaderCell>
            <Table.HeaderCell> {i18next.t('Cost')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
      </Table>
      <div className={styles.dataTableContainer}>
        <Table fixed selectable>
          <Table.Body>{_renderVehiclesList()}</Table.Body>
        </Table>
      </div>
    </>
  );
}

SearchResultsTable.defaultProps = {};

SearchResultsTable.propTypes = {
  vehicles: PropTypes.array.isRequired,
  onSetActiveVehicle: PropTypes.func.isRequired,
  activeVehicleId: PropTypes.number,
};

export default SearchResultsTable;
