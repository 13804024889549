import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Image, List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './SideBarList.module.scss';

function SideBarList(props) {
  const [listCount, setListCount] = useState(0);

  useEffect(() => {
    if (props.dataList?.length) setListCount(props.dataList.length);
  }, [props.dataList]);

  return (
    <div
      className={classNames(styles.SideBarList, {
        [styles.withScrollBars]: listCount > 10,
      })}
    >
      <List divided relaxed className="user-list">
        {props.dataList.map((item, index) => {
          return (
            <List.Item
              as="a"
              key={`vehicle-item-${index}`}
              active={props.activeIndex === index}
              onClick={() => props.listClick(item.id, index)}
            >
              {item.image ? (
                <Image src={item.image} size="mini" />
              ) : (
                <List.Icon
                  name={props.iconName}
                  size="large"
                  verticalAlign="middle"
                />
              )}
              <List.Content>
                <List.Header>{item[props.itemHeader]}</List.Header>
                <List.Description>{item[props.itemSubheader]}</List.Description>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
}

SideBarList.defaultProps = {
  dataList: [],
  listClick: () => {},
  activeIndex: -1,
  iconName: 'car',
  itemHeader: '',
  itemSubheader: '',
};

SideBarList.propTypes = {
  dataList: PropTypes.array,
  listClick: PropTypes.func,
  activeIndex: PropTypes.number,
  iconName: PropTypes.string,
  itemHeader: PropTypes.string,
  itemSubheader: PropTypes.string,
};

export default React.memo(SideBarList);
