import moment from 'moment';
import { Model } from './Model.js';

export class Driver extends Model {
  constructor(props) {
    super(props);
  }

  get defaults() {
    return {
      id: '',
      family_name: '',
      first_name: '',
      birth_date: '',
      home_address: '',
      zip_code: '',
      city: '',
      state: '',
      mobile_phone: '',
      driver_license_exp_date: '',
      driver_license: '',
    };
  }
}
