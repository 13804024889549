import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Button, Confirm, Grid, Header, Input } from 'semantic-ui-react';
import validator from 'validator';
import PropTypes from 'prop-types';
import CountDownLabel from '../../shared/CountDownLabel';

function SignAgreementByEmail(props) {
  const { uid } = props;
  const [email, setEmail] = useState(props.email ? props.email : '');
  const [isFormValid, setValidForm] = useState(false);
  const [isCountDown, setCountDown] = useState(false);
  const [isConfirmShow, setConfirmShow] = useState(false);
  const [additionalTime, setAdditionalTime] = useState(0);

  useEffect(() => {
    setValidForm(validator.isEmail(email));
  }, [email]);

  useEffect(() => {
    if ((additionalTime && isConfirmShow) > 0) handleAdditionalTime();
    else if (additionalTime === 0 && isConfirmShow) prolongateTime(false);
  }, [additionalTime]);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const checkAgreementStatus = () => {
    setTimeout(() => {
      props.checkAgreementStatus(uid).then((result) => {
        if (!result) checkAgreementStatus(uid);
        else {
          setCountDown(false);
          props.closeForm();
        }
      });
    }, 1000);
  };

  const handleEmailSend = () => {
    setCountDown(true);
    props
      .onSendSingEmail({ email, uid })
      .then(() => {
        checkAgreementStatus(uid);
      })
      .catch((e) => {
        setCountDown(false);
      });
  };

  const countDownCallback = () => {
    setCountDown(false);
    setConfirmShow(true);
    initAdditionalTime();
  };

  const handleAdditionalTime = () => {
    setTimeout(() => {
      if (additionalTime > 0 && isConfirmShow) {
        setAdditionalTime(additionalTime - 1);
      }
    }, 1000);
  };

  const initAdditionalTime = () => {
    setAdditionalTime(15);
    setTimeout(handleAdditionalTime, 1000);
  };

  const prolongateTime = (action) => {
    if (action) {
      setConfirmShow(false);
      setCountDown(true);
    } else {
      setConfirmShow(false);
      setCountDown(false);
      props.onSetAgreementStatusToCancel(uid);
      props.closeForm();
    }
  };

  return (
    <>
      <Confirm
        className="prolongate-time-confirm"
        open={isConfirmShow}
        onCancel={() => prolongateTime(false)}
        onConfirm={() => prolongateTime(true)}
        content={`${i18next.t(
          'Do you want prolonged waiting time?'
        )}(${additionalTime})`}
      />
      <Header size="small">{i18next.t('Sign by email ')}</Header>
      <Input
        className="sing-by-email-container"
        type="text"
        placeholder="Email"
        action
        disabled={isCountDown}
      >
        <input value={email} onChange={onEmailChange} />
        <Button
          type="submit"
          disabled={!isFormValid || isCountDown}
          onClick={handleEmailSend}
        >
          Send email
        </Button>
      </Input>

      {isCountDown && (
        <div>
          Email was sent to client. Wait for action.
          <CountDownLabel callback={countDownCallback} />
        </div>
      )}
    </>
  );
}

SignAgreementByEmail.propTypes = {
  uid: PropTypes.number,
  checkAgreementStatus: PropTypes.func,
  closeForm: PropTypes.func,
  onSendSingEmail: PropTypes.func,
  onSetAgreementStatusToCancel: PropTypes.func,
};

export default SignAgreementByEmail;
