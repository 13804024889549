import axios from 'axios';
import _ from 'lodash';
import i18next from 'i18next';
import AppConfig from '../../config';
import { tokenConfig } from '../user/auth';
import {
  LOAD_VEHICLE_FAIL,
  LOAD_VEHICLE_PREV_STATE,
  LOAD_VEHICLES,
  LOAD_VEHICLES_FAIL,
  SAVE_VEHICLE,
  SAVE_VEHICLE_FAIL,
  SHOW_STATUS_MESSAGE,
} from '../types';
import { SET_DATA_LOADING, showStatusMessage } from '../UiActions';
import {
  errorMessageStatus,
  statusMessages,
  successMessageStatus,
} from '../../common/catalogs/constants';
import { VehicleListItem } from '../../common/models/VehiclesListItem';
import { Vehicle } from '../../common/models/Vehicle';
import { sortExpenses } from '../../services/utils';

export const LOAD_VEHICLE = 'LOAD_VEHICLE';

export const loadVehicleList = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${AppConfig.apiUrl}/vehicles/getvehicleslist`,
      tokenConfig(getState)
    );
    dispatch({
      type: LOAD_VEHICLES,
      payload: res.data.map((item) => new VehicleListItem(item)),
    });
  } catch (err) {
    dispatch({
      type: LOAD_VEHICLES_FAIL,
    });
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
    dispatch({
      type: SHOW_STATUS_MESSAGE,
      payload: {
        messageType: errorMessageStatus,
        statusMessage: i18next.t(statusMessages.vehicleListLoadError),
      },
    });
  }
};

export const loadVehicle = (id) => async (dispatch, getState) => {
  const config = tokenConfig(getState);
  try {
    dispatch({
      type: SET_DATA_LOADING,
      payload: true,
    });
    const vehicle = await axios.get(
      `${AppConfig.apiUrl}/vehicles/getvehicle/${id}`,
      config
    );

    dispatch({
      type: LOAD_VEHICLE,
      payload: new Vehicle(vehicle.data),
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: LOAD_VEHICLE_FAIL,
    });
    dispatch({
      type: SHOW_STATUS_MESSAGE,
      payload: {
        messageType: errorMessageStatus,
        statusMessage: i18next.t(statusMessages.vehicleLoadError),
      },
    });
  }
  dispatch({
    type: SET_DATA_LOADING,
    payload: false,
  });
};

export const loadVehiclePrevState =
  (prevState) => async (dispatch, getState) => {
    dispatch({
      type: LOAD_VEHICLE_PREV_STATE,
      payload: new Vehicle(prevState),
    });
  };

export const saveVehicle =
  ({
    vehicle,
    isImageChange,
    isNewVehicle,
    isExpensesChange,
    isGalleryChange,
    galleryToUpload,
    deletedExpenses,
  }) =>
  async (dispatch, getState) => {
    let isNeedReloadList = false;
    const vehicleData = _.cloneDeep(vehicle);
    const files = _.cloneDeep(vehicleData.images);
    const expenses = _.cloneDeep(vehicleData.expenses);
    const { gallery } = vehicleData;
    const { id } = vehicleData;
    delete vehicleData.files;
    delete vehicleData.gallery;
    delete vehicleData.images;
    delete vehicleData.createdAt;
    delete vehicleData.updatedAt;
    delete vehicleData.expenses;

    if (isNewVehicle) {
      {
        try {
          const res = await axios.put(
            `${AppConfig.apiUrl}/vehicles/addvehicle`,
            vehicleData,
            tokenConfig(getState)
          );
          if (res && isImageChange && files.preview !== '') {
            const imageData = new FormData();
            imageData.append('id', res.data.id);
            imageData.append('preview', files.preview);
            imageData.append('full', files.full);
            await axios.post(
              `${AppConfig.apiUrl}/vehicles/uploadimage`,
              imageData,
              tokenConfig(getState)
            );
          }

          if (res && isExpensesChange) {
            const expensesObject = sortExpenses(expenses);
            await axios.post(
              `${AppConfig.apiUrl}/expenses/updateexpenses`,
              { ...expensesObject, vehicleId: res.data.id },
              tokenConfig(getState)
            );
          }

          if (res && isGalleryChange) {
            const imageData = new FormData();
            imageData.append('id', res.data.id);
            for (let i = 0; i < galleryToUpload.length; i++) {
              imageData.append('image', galleryToUpload[i]);
            }
            await axios.put(
              `${AppConfig.apiUrl}/vehicles/uploadtogallery`,
              imageData,
              tokenConfig(getState)
            );
          }

          dispatch(
            showStatusMessage(
              successMessageStatus,
              i18next.t(statusMessages.vehicleAddSuccess)
            )
          );

          // eslint-disable-next-line no-prototype-builtins
          if (res.data.hasOwnProperty('id')) {
            await dispatch(loadVehicleList()).then(async () => {
              await dispatch(loadVehicle(res.data.id));
            });
          }
        } catch (err) {
          dispatch({
            type: SAVE_VEHICLE_FAIL,
          });
          dispatch({
            type: SET_DATA_LOADING,
            payload: false,
          });
          dispatch(
            showStatusMessage(
              errorMessageStatus,
              i18next.t(statusMessages.vehicleAddError)
            )
          );
        }
      }
    } else {
      try {
        const res = await axios.patch(
          `${AppConfig.apiUrl}/vehicles/updatevehicle`,
          vehicleData,
          tokenConfig(getState)
        );
        if (res && isImageChange && files.preview !== '') {
          const imageData = new FormData();
          imageData.append('id', id);
          imageData.append('preview', files.preview);
          imageData.append('full', files.full);
          await axios.post(
            `${AppConfig.apiUrl}/vehicles/uploadimage`,
            imageData,
            tokenConfig(getState)
          );
          isNeedReloadList = true;
        } else if (res && isImageChange && files.preview === '') {
          await axios.get(
            `${AppConfig.apiUrl}/vehicles/deleteimage/${id}`,
            tokenConfig(getState)
          );
          isNeedReloadList = true;
        }

        if (res && isExpensesChange) {
          if (deletedExpenses.length > 0) {
            await axios.delete(`${AppConfig.apiUrl}/expenses/deleteexpenses`, {
              ...tokenConfig(getState),
              data: { removeIds: deletedExpenses },
            });
          }
          const expensesObject = sortExpenses(expenses);
          await axios.post(
            `${AppConfig.apiUrl}/expenses/updateexpenses`,
            { ...expensesObject, vehicleId: id },
            tokenConfig(getState)
          );
        }

        if (res && isGalleryChange) {
          const imageData = new FormData();
          imageData.append('id', id);
          for (let i = 0; i < galleryToUpload.length; i++) {
            imageData.append('image', galleryToUpload[i]);
          }
          await axios.put(
            `${AppConfig.apiUrl}/vehicles/uploadtogallery`,
            imageData,
            tokenConfig(getState)
          );
        }

        dispatch(
          showStatusMessage(
            successMessageStatus,
            i18next.t(statusMessages.vehicleUpdatedSuccess)
          )
        );

        if (res.data[0] > 0) await dispatch(loadVehicle(id));
        if (isNeedReloadList) {
          await dispatch(loadVehicleList());
        }
      } catch (err) {
        dispatch({
          type: SAVE_VEHICLE_FAIL,
        });
        dispatch({
          type: SET_DATA_LOADING,
          payload: false,
        });
        dispatch(
          showStatusMessage(
            errorMessageStatus,
            i18next.t(statusMessages.vehicleUpdatedError)
          )
        );
      }
    }
  };

export const deleteVehicle = (index) => async (dispatch, getState) => {
  try {
    await axios.delete(`${AppConfig.apiUrl}/vehicles/deletevehicle`, {
      ...tokenConfig(getState),
      data: { removeId: index },
    });
    dispatch(loadVehicleList());
    dispatch(
      showStatusMessage(
        successMessageStatus,
        i18next.t(statusMessages.vehicleDeleted)
      )
    );
  } catch (e) {
    console.log(e);
    dispatch(
      showStatusMessage(
        errorMessageStatus,
        i18next.t(statusMessages.vehicleNotDeleted)
      )
    );
  }
};

export const getVehicleMake = (make) => async (dispatch, getState) => {
  const config = tokenConfig(getState);
  try {
    const vehicleMake = await axios.get(
      `${AppConfig.apiUrl}/vehicles/getmake/${make}`,
      config
    );
    return vehicleMake;
  } catch (err) {
    console.log(err);
  }
};

export const getVehicleModel =
  ({ make, model }) =>
  async (dispatch, getState) => {
    const config = tokenConfig(getState);
    try {
      const vehicleModel = await axios.get(
        `${AppConfig.apiUrl}/vehicles/getmodel/${make}/${model}`,
        config
      );
      return vehicleModel;
    } catch (err) {
      console.log(err);
    }
  };

export const getVehicleSearch = (searchData) => async (dispatch, getState) => {
  const config = tokenConfig(getState);
  try {
    const vehicle = await axios.post(
      `${AppConfig.apiUrl}/vehicles/searchvehicle`,
      { data: searchData },
      config
    );
    return vehicle;
  } catch (err) {
    console.log(err);
  }
};
