import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import i18next from 'i18next';
import styles from './LoaderSpinner.module.scss';

const LoaderSpinner = (props) => {
  const { isLoading, loaderText } = props;
  return (
    <Dimmer.Dimmable
      blurring
      dimmed={isLoading}
      className={styles.LoaderSpinner}
    >
      <Dimmer active={isLoading} inverted>
        <Loader indeterminate>{i18next.t(loaderText)}</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

LoaderSpinner.defaultProps = {
  loaderText: 'Loading...',
};

LoaderSpinner.propTypes = {
  isLoading: PropTypes.bool,
  loaderText: PropTypes.string,
};

export default LoaderSpinner;
