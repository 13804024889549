import axios from 'axios';
import {
  IS_USER_AUTH,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_USER,
  AUTH_ERROR,
  USER_LOADED,
  RELEASE_LOADED,
  SET_AUTH_LOADING,
} from '../types';
import AppConfig from '../../config';
import { SET_DATA_LOADING } from '../UiActions';

export function setUserAuth(isUserAuth) {
  return {
    type: IS_USER_AUTH,
    payload: isUserAuth,
  };
}

export const login =
  ({ login, password, isRemember = true }) =>
  async (dispatch, getState) => {
    const trimmedLogin = login.replace(/\s/g, '');
    const body = JSON.stringify({ login: trimmedLogin, password });
    let response;

    try {
      response = await axios.post(
        `${AppConfig.apiUrl}/users/login`,
        body,
        noAuthConfig
      );
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.message,
      });
    } finally {
      dispatch({
        type: SET_DATA_LOADING,
        payload: false,
      });
    }

    dispatch({
      type: LOGIN_SUCCESS,
      isRemember,
      payload: response.data,
    });
  };

export const loadUser = () => async (dispatch, getState) => {
  if (!getState().auth.token) return;
  try {
    dispatch(authLoading(true));
    const res = await axios.get(
      `${AppConfig.apiUrl}/users/loaduser`,
      tokenConfig(getState)
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });

    if (
      process.env.NODE_ENV !== 'development' &&
      getState().auth.is_new_release
    ) {
      const newRelease = await axios.get(
        `${AppConfig.apiUrl}/users/getrelease`,
        tokenConfig(getState)
      );
      dispatch({
        type: RELEASE_LOADED,
        payload: newRelease.data.data,
      });
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  } finally {
    dispatch(authLoading(true));
    dispatch({
      type: SET_DATA_LOADING,
      payload: false,
    });
  }
};

export const setReleaseInfo = () => async (dispatch, getState) => {
  try {
    await axios.patch(
      `${AppConfig.apiUrl}/users/setrelease`,
      '{}',
      tokenConfig(getState)
    );
  } catch (err) {
    console.log(err);
  }
};

export const logoutUser = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${AppConfig.apiUrl}/users/logout`,
      tokenConfig(getState)
    );
    if (res)
      dispatch({
        type: LOGOUT_USER,
      });
  } catch (err) {
    console.log(err);
  }
};

export const loginFailure = (message) => async (dispatch) => {
  dispatch({
    type: LOGIN_FAIL,
    payload: message,
  });
};

export const authLoading =
  (loading = false) =>
  async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
      payload: loading,
    });
  };

export const noAuthConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const tokenConfig = (getState) => {
  const { token } = getState().auth;

  const config = noAuthConfig;

  if (token) {
    config.headers.Authorization = token;
  }

  return config;
};
