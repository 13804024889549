import Axios from 'axios';
import i18next from 'i18next';
import { LOGIN_FAIL } from '../actions/types';

const interceptor = (store) => {
  let errorMessage = '';
  Axios.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  Axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      if (error.message === 'Network Error' && !error.response)
        errorMessage = i18next.t(
          'Network error, check network and server availability'
        );

      if (error.response) {
        const { status } = error.response;

        if (status === 500) errorMessage = i18next.t('Internal server error');
      }

      store.dispatch({
        type: LOGIN_FAIL,
        payload: errorMessage,
      });

      return Promise.reject(error);
    }
  );
};
export default {
  interceptor,
};
