import React, { Component } from 'react';
import withDragDropContext from './rentplaner/withDnDContext';
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
} from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import moment from 'moment';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { Grid, Menu, Message, Segment } from 'semantic-ui-react';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadPlannerAgreementsList } from '../../actions/planner';
import { colors } from '../catalogs/constants';

class RentPlanner extends Component {
  constructor(props) {
    super(props);

    const schedulerData = new SchedulerData(
      moment().format('YYYY-MM-DD'),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: 1100,
        resourceName: 'Agreements',
      }
    );

    this.state = {
      viewModel: schedulerData,
    };
    this.plannerContentRef = React.createRef();
  }

  componentDidMount() {
    this.props.loadPlannerAgreementsList().then(() => {
      this.initPlannerData();
    });
  }

  initPlannerData() {
    const schedulerData = new SchedulerData(
      moment().format('YYYY-MM-DD'),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: this.plannerContentRef.current?.clientWidth - 28,
        resourceName: 'Agreements',
      }
    );

    schedulerData.localeMoment.locale('en');
    schedulerData.setResources(this.props.vehiclesList);
    schedulerData.setEvents(this.props.agreementsList);

    this.setState({ viewModel: schedulerData });
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.props.agreementsList);
    this.setState({
      viewModel: schedulerData,
    });
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.props.agreementsList);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(this.props.agreementsList);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.props.agreementsList);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventClicked = (schedulerData, event) => {
    alert(
      `You just clicked an event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  ops1 = (schedulerData, event) => {
    alert(
      `You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    // eslint-disable-next-line no-restricted-globals
    if (
      confirm(
        `Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`
      )
    ) {
      let newFreshId = 0;
      schedulerData.events.forEach((item) => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });

      const newEvent = {
        id: newFreshId,
        title: 'New event you just created',
        start,
        end,
        resourceId: slotId,
        bgColor: 'purple',
      };
      schedulerData.addEvent(newEvent);
      this.setState({
        viewModel: schedulerData,
      });
    }
  };

  updateEventStart = (schedulerData, event, newStart) => {
    // eslint-disable-next-line no-restricted-globals
    if (
      confirm(
        `Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`
      )
    ) {
      schedulerData.updateEventStart(event, newStart);
    }
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    // eslint-disable-next-line no-restricted-globals
    if (
      confirm(
        `Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`
      )
    ) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    this.setState({
      viewModel: schedulerData,
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    // eslint-disable-next-line no-restricted-globals
    if (
      confirm(
        `Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`
      )
    ) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      this.setState({
        viewModel: schedulerData,
      });
    }
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.props.agreementsList);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.props.agreementsList);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log('onScrollTop');
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    console.log('onScrollBottom');
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    return (
      <div style={{ width: '300px' }}>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div
              className="status-dot"
              style={{ backgroundColor: statusColor }}
            />
          </Col>
          <Col span={22} className="overflow-text">
            <span className="header2-text" title={title}>
              {title}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span style={{ color: statusColor }} className="header1-text">
              {start.format('HH:mm')} - {end.format('HH:mm')}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header2-text">
              Agreement#: {eventItem.agreementNumber}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header3-text">Clint: {eventItem.clientName}</span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header3-text">
              Start date: {start.format('MMM DD')}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header3-text">
              End date: {end.format('MMM DD')}
            </span>
          </Col>
        </Row>
      </div>
    );
  };

  eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    const borderWidth = isStart ? '4' : '0';
    let borderColor = 'rgba(0,139,236,1)';
    let backgroundColor = '#80C5F6';
    let color = '#135483';
    const titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event
    );
    borderColor = event.borderColor;
    backgroundColor = event.bgColor;
    color = event.color;
    // if(event.type){
    //     borderColor = event.type == 1 ? 'rgba(0,139,236,1)' : (event.type == 3 ? 'rgba(245,60,43,1)' : '#999');
    //     backgroundColor = event.type == 1 ? '#80C5F6' : (event.type == 3 ? '#FA9E95' : '#D9D9D9');
    // }
    let divStyle = {
      borderLeft: `${borderWidth}px solid ${borderColor}`,
      backgroundColor,
      color,
      height: mustBeHeight,
    };
    if (agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>
          {titleText}
        </span>
      </div>
    );
  };

  nonAgendaCellHeaderTemplateResolver = (
    schedulerData,
    item,
    formattedDateItems,
    style
  ) => {
    const datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;

    if (schedulerData.viewType === ViewTypes.Day) {
      isCurrentDate = datetime.isSame(new Date(), 'hour');
    } else {
      isCurrentDate = datetime.isSame(new Date(), 'day');
    }

    if (isCurrentDate) {
      style.backgroundColor = '#118dea';
      style.color = 'white';
    }

    return (
      <th key={item.time} className="header3-text" style={style}>
        {formattedDateItems.map((formattedItem, index) => (
          <div
            key={index}
            dangerouslySetInnerHTML={{
              __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>'),
            }}
          />
        ))}
      </th>
    );
  };

  _renderActionPanel() {
    const { showStatusMessage, statusMessage, messageType, viewModel } =
      this.state;

    return (
      <Menu className="tool-bar" stackable>
        <Menu.Item className="section-name" header>
          {i18next.t('Rent Planner')}
        </Menu.Item>
        <Menu.Item>
          <Scheduler
            className="planner-header"
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
          />
        </Menu.Item>

        <Menu.Item className="section-message" header>
          {showStatusMessage && (
            <Message color={colors[messageType]}>{statusMessage}</Message>
          )}
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const { viewModel } = this.state;
    return (
      <div ref={this.plannerContentRef} className="rent-planner">
        {this._renderActionPanel()}
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <Scheduler
                  schedulerData={viewModel}
                  prevClick={this.prevClick}
                  nextClick={this.nextClick}
                  onSelectDate={this.onSelectDate}
                  onViewChange={this.onViewChange}
                  // eventItemClick={this.eventClicked}
                  viewEventClick={this.ops1}
                  viewEventText="Ops 1"
                  viewEvent2Text="Ops 2"
                  viewEvent2Click={this.ops2}
                  updateEventStart={this.updateEventStart}
                  updateEventEnd={this.updateEventEnd}
                  moveEvent={this.moveEvent}
                  // newEvent={this.newEvent}
                  onScrollLeft={this.onScrollLeft}
                  onScrollRight={this.onScrollRight}
                  onScrollTop={this.onScrollTop}
                  onScrollBottom={this.onScrollBottom}
                  toggleExpandFunc={this.toggleExpandFunc}
                  eventItemTemplateResolver={this.eventItemTemplateResolver}
                  eventItemPopoverTemplateResolver={
                    this.eventItemPopoverTemplateResolver
                  }
                  nonAgendaCellHeaderTemplateResolver={
                    this.nonAgendaCellHeaderTemplateResolver
                  }
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { agreementsList, vehiclesList } = state.planner;

  return {
    agreementsList,
    vehiclesList,
  };
};

const mapDispatchToProps = {
  loadPlannerAgreementsList,
};

export default withRouter(
  withDragDropContext(connect(mapStateToProps, mapDispatchToProps)(RentPlanner))
);
