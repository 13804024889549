import moment from 'moment';
import { Model } from './Model.js';
import AppConfig from '../../config';

export class ClientListItem extends Model {
  constructor(props) {
    super(props);
    this.image = this.getImage();
  }

  get defaults() {
    return {
      id: undefined,
      first_name: undefined,
      family_name: undefined,
      driver_license: undefined,
      image: '',
    };
  }

  get title() {
    return `${this.family_name} ${this.first_name}`;
  }

  getImage() {
    const image = this.files
      ? `${AppConfig.serverRoot}${JSON.parse(this.files).preview}`
      : '';
    delete this.files;
    return image;
  }
}
