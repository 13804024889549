import { Model } from './Model.js';

export class Settings extends Model {
  constructor(props) {
    super(props);
  }

  get defaults() {
    return {
      id: undefined,
      company_name: '',
      owner_name: '',
      vat: '',
      country: '',
      city: '',
      state: '',
      zip: '',
      address1: '',
      address2: '',
      phone: '',
      fax: '',
      email: '',
      webpage: '',
      warnings: '',
      terms: '',
      media_url: '',
      currency: '',
      sys_country: '',
      sys_tz: '',
    };
  }
}
