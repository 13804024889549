// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';
import i18next from 'i18next';
import styles from './UserItem.module.scss';
import AppConfig from '../../../../config';
import { logoutUser } from '../../../../actions/user/auth';

const UserItem = (props) => {
  const dispatch = useDispatch();
  const {
    name,
    company: { name: companyName },
  } = useSelector((state) => state.auth);
  const [currentTime, setCurrentTime] = useState();

  useEffect(() => {
    setInterval(clock, 1000);
  }, []);

  const logOutUserClick = () => {
    dispatch(logoutUser());
    document.location.href = '/auth';
  };

  const clock = () => {
    const date = new Date();
    const hours =
      date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    const today = `${dd}/${mm}/${yyyy}`;
    setCurrentTime(`${today}, ${hours}:${minutes}`);
  };

  return (
    <div className={styles.UserItem}>
      <div className={styles.avatar}>
        <Image
          src={`${AppConfig.defaultClientImage}`}
          size="mini"
          verticalAlign="middle"
        />
      </div>
      <div className={styles.info}>
        <div>
          {name}(
          <a href="#logout" onClick={logOutUserClick}>
            {i18next.t('Logout')}
          </a>
          )
        </div>
        <div>{companyName}</div>
        <div>{currentTime}</div>
      </div>
    </div>
  );
};

UserItem.propTypes = {};

export default UserItem;
