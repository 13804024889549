import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import _ from 'lodash';
import AppConfig from '../config';
import { depositMethod } from '../common/catalogs/constants';
import { getBase64Image, ImageDimension } from './utils';

const greenHeaderColor = '#21A24D';
const greenSecondaryColor = '#C1EAB4';
const grayTableColor = '#F2F2F2';
const mainTextColor = '#333333';

const maxPhotoOnPage = 6;

const agreeText = [
  'I AGREE TO THE TERMS AND CONDITIONS OF THIS RENTAL AGREEMENT AND TO RETURN VEHICLE TO LESSOR ON OR BEFORE RENTAL EXPIRATION DATE AND AT PLACE SPECIFIED.',
];

const correctDataField = (data) => {
  if (_.isNull(data)) return '';

  if (typeof data !== 'string') return String(data);
  return data;
};

// eslint-disable-next-line consistent-return
const getDocField = (docFieldName, docContent, type = 'string') => {
  // eslint-disable-next-line no-prototype-builtins
  if (docContent.hasOwnProperty(docFieldName)) {
    if (type === 'date')
      return correctDataField(
        moment(docContent[docFieldName]).format('YYYY-MM-DD HH:mm')
      );
    if (type === 'string') return correctDataField(docContent[docFieldName]);
  } else return '';
};

const addCurrencyToCost = (value, currency) =>
  value ? `${value}${currency}` : '';
async function urlExists(url) {
  const http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  return http.status !== 404;
}

async function getLogoUrl(url) {
  if (url && (await urlExists(AppConfig.serverRoot + url)))
    return AppConfig.serverRoot + url;
  return '/assets/img/ui/default_logo.png';
}

const parseImages = ({ mainImage, galleryImages }) => {
  const images = [];

  if (mainImage.length) {
    const imageObject = JSON.parse(mainImage[0].files);
    images.push(imageObject.full);
  }

  if (galleryImages.length) {
    for (const image of galleryImages) images.push(image.file);
  }

  return images;
};

function preparingDate(data) {
  const {
    agreement_number,
    family_name,
    first_name,
    dt_start,
    dt_end,
    home_address,
    mobile_phone,
    city,
    state,
    zip_code,
    driver_license,
    driver_license_exp_date,
    birth_date,
    insurience_police_number,
    insurience_exp_date,
    add_driver_name,
    add_driver_home_address,
    add_driver_birthdate,
    add_phone_number,
    add_driver_city,
    add_driver_state,
    add_driver_zip,
    add_driver_lic_no,
    add_driver_ins,
    add_driver_ins_date,
    pick_location,
    pick_instruction,
    drop_location,
    drop_instruction,
    deposit_method,
    card_number,
    name_of_card,
    security_code,
    deposit_amount,
    full_payment,
    rent_days,
    rent_month,
    rent_ticket,
    rent_chargeable_mils,
    rent_full_cost,
    rent_days_total,
    rent_month_total,
    rent_ticket_cost,
    rent_chargeable_mils_total,
    fuel_out,
    vin,
    stock_number,
    make,
    model,
    year,
    signImageDataURL,
    vehicle_odometer_in,
    vehicle_free_miles,
    Vehicle,
  } = data;

  let vehicleImage = [];
  let vehicleGallery = [];

  try {
    vehicleImage = Object.values(Vehicle.Uploads);
    vehicleGallery = Object.values(Vehicle.VehicleGalleries);
  } catch (e) {
    console.log(e);
  }

  const imagesUrls = parseImages({
    mainImage: vehicleImage,
    galleryImages: vehicleGallery,
  });

  const isCashDeposit = depositMethod[deposit_method].key === 0;

  const docObject = {
    client_name: `${first_name} ${family_name}`,
    dt_start,
    dt_end,
    home_address,
    mobile_phone,
    city,
    state,
    zip_code,
    driver_license,
    driver_license_exp_date: moment(driver_license_exp_date).format(
      'YYYY-MM-DD'
    ),
    birth_date: moment(birth_date).format('YYYY-MM-DD'),
    insurience_police_number,
    insurience_exp_date: moment(insurience_exp_date).format('YYYY-MM-DD'),
    // Second driver
    add_driver_name: add_driver_name || '',
    add_driver_home_address: add_driver_home_address || '',
    add_driver_birthdate: add_driver_birthdate || '',
    add_phone_number: add_phone_number || '-',
    add_driver_city: add_driver_city || '',
    add_driver_state: add_driver_state || '',
    add_driver_zip: add_driver_zip || '',
    add_driver_lic_no: add_driver_lic_no || '',
    add_driver_ins: add_driver_ins || '',
    add_driver_ins_date: add_driver_ins_date || '',
    // Location
    pick_location: pick_location || '-',
    drop_location: drop_location || '-',
    pick_instruction: pick_instruction || '-',
    drop_instruction: drop_instruction || '-',
    // Payment info
    deposit_method: depositMethod[deposit_method].text || '-',
    card_number: !isCashDeposit ? card_number : '-',
    name_of_card: !isCashDeposit ? name_of_card : '-',
    security_code: !isCashDeposit ? security_code : '-',
    deposit: !isCashDeposit ? deposit_amount : '-',
    full_payment: !isCashDeposit ? full_payment : '-',
    // Vehicle
    vin,
    stock_number,
    make,
    model,
    year,
    vehicle_odometer_in,
    vehicle_free_miles,
    signImageDataURL,
    fuel_in: fuel_out || '',
    fuel_out: '',
    rent_days,
    rent_month,
    rent_ticket,
    rent_chargeable_mils,
    rent_full_cost,
    rent_days_total,
    rent_month_total,
    rent_ticket_cost,
    rent_chargeable_mils_total,
    agreement_number,
    imagesUrls,
  };

  return docObject;
}

async function getImage(url, maxWidth = 200) {
  const image = await getBase64Image(url);
  const imageMeta = await ImageDimension(image);
  const sizeRatio = 0.14;
  let { width } = imageMeta;
  let { height } = imageMeta;

  if (width > maxWidth) {
    const ratio = maxWidth / width;
    height = ratio * height * sizeRatio;
    width = ratio * width * sizeRatio;
  }
  return { image, width, height };
}

export async function generateAgreement({
  data,
  settings,
  agreementAction = 'open',
}) {
  // eslint-disable-next-line new-cap
  const agreement = new jsPDF({
    orientation: 'p',
    format: 'a4',
    compress: true,
  });

  try {
    const docContent = preparingDate(_.cloneDeep(data));
    const { media_url, company_name, currency } = settings;
    const logoUrl = await getLogoUrl(media_url);
    const imgLogo = await getImage(logoUrl);
    const imgSign = new Image();

    agreement.addImage(
      imgLogo.image,
      'JPEG',
      10,
      5,
      imgLogo.width,
      imgLogo.height
    );

    const imgCar = await getImage(
      `${AppConfig.serverRoot}upload/sys/vehicle_scheme.jpg`
    );
    agreement.addImage(imgCar.image, 'JPEG', 108, 213, 80, 49);
    imgSign.width = 30;
    imgSign.height = 30;
    imgSign.src = docContent.signImageDataURL;

    // Draw blank
    // First line
    agreement.setFillColor(greenHeaderColor);
    agreement.rect(10, 35, 87, 7, 'FD'); // renter

    agreement.setFillColor(greenSecondaryColor);
    agreement.rect(10, 42, 37, 7, 'FD'); // Name
    agreement.rect(10, 49, 37, 7, 'FD'); // Address
    agreement.rect(10, 56, 37, 7, 'FD'); // Birthday
    agreement.rect(10, 63, 37, 7, 'FD'); // City
    agreement.rect(10, 70, 37, 7, 'FD'); // Lic
    agreement.rect(10, 77, 37, 7, 'FD'); // Issue

    agreement.rect(47, 42, 50, 7); // Name
    agreement.rect(47, 49, 50, 7); // Address
    agreement.rect(47, 56, 50, 7); // Birthday
    agreement.rect(47, 63, 50, 7); // City
    agreement.rect(47, 70, 50, 7); // Lic
    agreement.rect(47, 77, 50, 7); // Issue

    // Add driver
    agreement.setFillColor(greenHeaderColor);
    agreement.rect(10, 84, 87, 7, 'FD'); // Add driver
    agreement.setFillColor(greenSecondaryColor);
    agreement.rect(10, 91, 37, 7, 'FD'); // Name
    agreement.rect(10, 98, 37, 7, 'FD'); // Address
    agreement.rect(10, 105, 37, 7, 'FD'); // Birthday
    agreement.rect(10, 112, 37, 7, 'FD'); // City
    agreement.rect(10, 119, 37, 7, 'FD'); // Lic
    agreement.rect(10, 126, 37, 7, 'FD'); // Issue

    agreement.rect(47, 91, 50, 7); // Name
    agreement.rect(47, 98, 50, 7); // Address
    agreement.rect(47, 105, 50, 7); // Birthday
    agreement.rect(47, 112, 50, 7); // City
    agreement.rect(47, 119, 50, 7); // Lic
    agreement.rect(47, 126, 50, 7); // Issue

    // Center columns------------------------------------
    agreement.setFillColor(greenHeaderColor);
    agreement.rect(97, 35, 100, 7, 'FD');
    agreement.rect(147, 25, 50, 10);
    agreement.setDrawColor(0);
    // green color
    agreement.setFillColor(greenSecondaryColor);
    agreement.rect(97, 42, 35, 7, 'FD'); // VIN
    agreement.rect(97, 49, 35, 7, 'FD'); // YR
    agreement.rect(97, 56, 35, 7, 'FD'); // LIC
    agreement.rect(97, 63, 35, 7, 'FD'); // DATE IN
    agreement.rect(97, 70, 35, 7, 'FD'); // DATE OUT
    agreement.rect(97, 77, 35, 7, 'FD'); // Fuel in
    agreement.rect(97, 84, 35, 7, 'FD'); // Fuel out
    agreement.rect(97, 91, 35, 7, 'FD'); // Odo in
    agreement.rect(97, 98, 35, 7, 'FD'); // Odo out
    agreement.rect(97, 105, 35, 7, 'FD'); // mmiles driven
    agreement.rect(97, 112, 35, 7, 'FD'); // free mil
    agreement.rect(97, 119, 35, 7, 'FD'); // chardge

    agreement.rect(132, 42, 65, 7); // VIN
    agreement.rect(132, 49, 65, 7); // YR
    agreement.rect(132, 56, 65, 7); // LIC
    agreement.rect(132, 63, 65, 7); // DATE IN
    agreement.rect(132, 70, 65, 7); // DATE OUT
    agreement.rect(132, 77, 65, 7); // Fuel in
    agreement.rect(132, 84, 65, 7); // Fuel out
    agreement.rect(132, 91, 65, 7); // Odo in
    agreement.rect(132, 98, 65, 7); // Odo out
    agreement.rect(132, 105, 65, 7); // mmiles driven
    agreement.rect(132, 112, 65, 7); // free mil
    agreement.rect(132, 119, 65, 7); // chardge

    // Warning
    agreement.setFillColor(greenHeaderColor);
    agreement.rect(97, 126, 100, 7, 'FD'); // chardge
    agreement.setFillColor(greenSecondaryColor);
    agreement.rect(97, 133, 100, 75); // Warning wrapper
    agreement.setFillColor(greenHeaderColor);
    agreement.rect(97, 208, 100, 58); // Car pic wrapper

    // Rent invoice
    agreement.rect(10, 133.11, 87, 7, 'FD'); // Invoice
    agreement.setFillColor(greenSecondaryColor);
    agreement.rect(10, 140, 29, 7, 'FD'); // name of pay
    agreement.rect(39, 140, 29, 7, 'FD'); // count
    agreement.rect(68, 140, 29, 7, 'FD'); // cost
    // -----
    agreement.setFillColor(grayTableColor);
    agreement.rect(10, 147, 29, 7, 'FD'); // name of pay
    agreement.rect(39, 147, 29, 7, 'FD'); // count
    agreement.rect(68, 147, 29, 7, 'FD'); // cost
    // -----
    agreement.rect(10, 154, 29, 7, 'FD'); // name of pay
    agreement.rect(39, 154, 29, 7, 'FD'); // count
    agreement.rect(68, 154, 29, 7, 'FD'); // cost
    // -----
    agreement.rect(10, 161, 29, 7, 'FD'); // name of pay
    agreement.rect(39, 161, 29, 7, 'FD'); // count
    agreement.rect(68, 161, 29, 7, 'FD'); // cost
    // -----
    agreement.rect(10, 168, 29, 7, 'FD'); // name of pay
    agreement.rect(39, 168, 29, 7, 'FD'); // count
    agreement.rect(68, 168, 29, 7, 'FD'); // cost
    // -----
    agreement.rect(10, 175, 29, 7, 'FD'); // name of pay
    agreement.rect(39, 175, 29, 7, 'FD'); // count
    agreement.rect(68, 175, 29, 7, 'FD'); // cost

    // Location
    agreement.setFillColor(greenHeaderColor);
    agreement.rect(10, 182, 87, 7, 'FD'); // Invoice

    agreement.setFillColor(greenSecondaryColor);
    agreement.rect(10, 189, 37, 7, 'FD'); // Pick location
    agreement.rect(10, 196, 37, 7, 'FD'); // Drop location
    agreement.rect(10, 203, 37, 7, 'FD'); // Pick instruction
    agreement.rect(10, 210, 37, 7, 'FD'); // Drop instruction

    agreement.rect(47, 189, 50, 7); // Pick location
    agreement.rect(47, 196, 50, 7); // Drop location
    agreement.rect(47, 203, 50, 7); // Pick instruction
    agreement.rect(47, 210, 50, 7); // Drop instruction

    // Payment
    agreement.setFillColor(greenHeaderColor);
    agreement.rect(10, 217, 87, 7, 'FD'); // Invoice

    agreement.setFillColor(greenSecondaryColor);
    agreement.rect(10, 224, 37, 7, 'FD'); // Card
    agreement.rect(10, 231, 37, 7, 'FD'); // Card number
    agreement.rect(10, 238, 37, 7, 'FD'); // Name of card
    agreement.rect(10, 245, 37, 7, 'FD'); // Security code
    agreement.rect(10, 252, 37, 7, 'FD'); // Deposit
    agreement.rect(10, 259, 37, 7, 'FD'); // Full payment

    agreement.rect(47, 224, 50, 7); // Card
    agreement.rect(47, 231, 50, 7); // Card number
    agreement.rect(47, 238, 50, 7); // Name of card
    agreement.rect(47, 245, 50, 7); // Security code
    agreement.rect(47, 252, 50, 7); // Deposit
    agreement.rect(47, 259, 50, 7); // Full payment

    // Bottom
    agreement.setFillColor(greenSecondaryColor);
    agreement.rect(10, 266, 187, 15, 'FD'); // Sign

    // Render text----------------------
    agreement.setFontSize(8);
    agreement.setFontStyle('bold');
    agreement.text('RENTAL AGREEMENT', 172, 11, { align: 'center' });
    agreement.setFontSize(10);
    agreement.text(company_name, 172, 15, { align: 'center' });
    agreement.setFontSize(13);
    agreement.setTextColor('Red');
    agreement.text(`N ${docContent.agreement_number}`, 172, 21, {
      align: 'center',
    });
    agreement.setFontStyle('normal');
    agreement.setTextColor(0);
    agreement.setFontSize(7);
    agreement.text('RENTER EXPIRES ON', 148, 28);
    // First column
    agreement.setTextColor(mainTextColor);
    agreement.setFontSize(8);
    agreement.text('THE FIRST  DRIVER', 55, 39.5, { align: 'center' });
    agreement.text('FIRST NAME / LAST NAME', 28.5, 46.5, { align: 'center' });
    agreement.text('LOCAL HOME ADDRESS', 28.5, 53, { align: 'center' });
    agreement.text('BIRTHDATE / PHONE', 28.5, 59, { align: 'center' });
    agreement.text('NUMBER', 28.5, 61.5, { align: 'center' });
    agreement.text('CITY / STATE / ZIP', 28.5, 67, { align: 'center' });
    agreement.text('LICENSE NO', 28.5, 74, { align: 'center' });
    agreement.text('STATE OF ISSUANCE /', 28.5, 80, { align: 'center' });
    agreement.text('VALIDITY', 28.5, 83, { align: 'center' });

    agreement.setFontStyle('bold');
    agreement.text(getDocField('client_name', docContent), 50, 46.5);
    agreement.text(getDocField('home_address', docContent), 50, 51.5, {
      maxWidth: 45,
      baseline: 'middle',
      lineHeightFactor: 0.8,
    });
    agreement.text(
      `${getDocField('birth_date', docContent)} / ${getDocField(
        'mobile_phone',
        docContent
      )}`,
      50,
      60
    );
    agreement.text(
      `${getDocField('city', docContent)} / ${getDocField(
        'state',
        docContent
      )} / ${getDocField('zip_code', docContent)}`,
      50,
      67
    );
    agreement.text(getDocField('driver_license', docContent), 50, 74.5);
    agreement.text(
      getDocField('driver_license_exp_date', docContent),
      50,
      81.5
    );

    // Add driver
    agreement.setFontStyle('normal');
    agreement.text('ADDITIONAL DRIVER', 53.48, 88.5, { align: 'center' });
    agreement.text('FIRST NAME / LAST NAME', 28.5, 95, { align: 'center' });
    agreement.text('LOCAL HOME ADDRESS', 28.5, 102.5, { align: 'center' });
    agreement.text('BIRTHDATE / PHONE', 28.5, 108, { align: 'center' });
    agreement.text('NUMBER', 28.5, 111, { align: 'center' });
    agreement.text('CITY / STATE / ZIP', 28.5, 116, { align: 'center' });
    agreement.text('LICENSE NO', 28.5, 123, { align: 'center' });
    agreement.text('STATE OF ISSUANCE /', 28.5, 129, { align: 'center' });
    agreement.text('VALIDITY', 28.5, 132, { align: 'center' });

    if (docContent.add_driver_name) {
      agreement.setFontStyle('bold');
      agreement.text(getDocField('add_driver_name', docContent), 50, 95);
      agreement.text(
        getDocField('add_driver_home_address', docContent),
        50,
        101,
        {
          maxWidth: 45,
          baseline: 'middle',
          lineHeightFactor: 0.8,
        }
      );
      agreement.text(
        `${getDocField('add_driver_birthdate', docContent)} / ${getDocField(
          'add_phone_number',
          docContent
        )}`,
        50,
        109.5
      );
      agreement.text(
        `${getDocField('add_driver_city', docContent)} / ${getDocField(
          'add_driver_state',
          docContent
        )} / ${getDocField('add_driver_zip', docContent)}`,
        50,
        116.5
      );
      agreement.text(getDocField('add_driver_lic_no', docContent), 50, 123);
      agreement.text(getDocField('add_driver_ins_date', docContent), 50, 130.5);
    } else {
      agreement.line(50, 95, 93, 95);
      agreement.line(50, 102, 93, 102);
      agreement.line(50, 109, 93, 109);
      agreement.line(50, 116, 93, 116);
      agreement.line(50, 123, 93, 123);
      agreement.line(50, 130, 93, 130);
    }

    // Warning
    agreement.setFontStyle('normal');
    agreement.setTextColor(0);
    agreement.setFontSize(7);

    const linesWarning = agreement.splitTextToSize(settings.warnings, 95);
    const dimWarning = agreement.getTextDimensions('Text');
    const lineHeightWarning = dimWarning.h;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < linesWarning.length; i++) {
      const lineTop = (lineHeightWarning + 0.6) * i;
      agreement.text(linesWarning[i], 99, 137 + lineTop);
    }

    // agreement.text(warningText, 99, 137, {maxWidth: 95});
    // agreement.setTextColor(0);
    // agreement.setFontSize(8);
    // agreement.text('IN FLORIDA', 148, 176, {align: 'center'});
    // agreement.setFontSize(7);
    // agreement.text(warInCity, 99, 182, {maxWidth: 95});
    // agreement.setFontSize(7);
    // agreement.text(failureText, 99, 195, {maxWidth: 95});
    // RENT INVOICE
    agreement.setFontSize(8);
    agreement.setTextColor(mainTextColor);
    agreement.text('RENT INVOICE', 53.48, 137.5, { align: 'center' });
    agreement.setTextColor(0);
    agreement.setFontSize(7);
    agreement.setTextColor(0, 176, 109);
    agreement.setFontSize(10);
    agreement.setFontStyle('bold');
    agreement.text(`${settings.running_title}`, 105, 290, { align: 'center' });
    agreement.setFontStyle('normal');
    // Location
    agreement.setFontSize(8);
    agreement.setTextColor(mainTextColor);
    agreement.text('LOCATION', 53.48, 186.5, { align: 'center' });
    agreement.text('PICK LOCATION', 28.5, 193.5, { align: 'center' });
    agreement.text('DROP LOCATION', 28.5, 200.5, { align: 'center' });
    agreement.text('PICK INSTRUCTION', 28.5, 207.5, { align: 'center' });
    agreement.text('DROP INSTRUCTION', 28.5, 214.5, { align: 'center' });

    agreement.setFontStyle('bold');
    agreement.text(getDocField('pick_location', docContent), 50, 193.5);
    agreement.text(getDocField('drop_location', docContent), 50, 200.5);
    agreement.text(getDocField('pick_instruction', docContent), 50, 207.5);
    agreement.text(getDocField('drop_instruction', docContent), 50, 214.5);

    // Payment
    agreement.setFontStyle('normal');
    agreement.text('PAYMENT INFO', 53.48, 221.5, { align: 'center' });
    agreement.text('PAYMENT TYPE', 28.5, 228.5, { align: 'center' });
    agreement.text('CARD NUMBER', 28.5, 235.5, { align: 'center' });
    agreement.text('NAME OF CARD', 28.5, 242.5, { align: 'center' });
    agreement.text('SECURITY CODE', 28.5, 249.5, { align: 'center' });
    agreement.text('DEPOSIT', 28.5, 256.5, { align: 'center' });
    agreement.text('FULL PAYMENT', 28.5, 263.5, { align: 'center' });

    agreement.setFontStyle('bold');
    agreement.text(getDocField('deposit_method', docContent), 50, 228.5);
    agreement.text(getDocField('card_number', docContent), 50, 235.5);
    agreement.text(getDocField('name_of_card', docContent), 50, 242.5);
    agreement.text(getDocField('security_code', docContent), 50, 249.5);
    agreement.text(getDocField('deposit', docContent), 50, 256.5);
    agreement.text(getDocField('full_payment', docContent), 50, 263.5);
    agreement.setFontStyle('normal');

    // Second part
    agreement.setFontSize(8);
    agreement.setTextColor(mainTextColor);
    agreement.text('VEHICLE', 148, 39.5, { align: 'center' });
    agreement.setTextColor(0);
    agreement.text('VIN / STK', 114, 46.5, { align: 'center' });
    agreement.text('YR/MAKE / MODEL', 114, 53.5, { align: 'center' });
    agreement.text('LICENSE / STATE', 114, 60.5, { align: 'center' });
    agreement.text('DATE / TIME IN', 114, 67.5, { align: 'center' });
    agreement.text('DATE / TIME OUT', 114, 74.5, { align: 'center' });
    agreement.text('FUEL IN', 114, 81.5, { align: 'center' });
    agreement.text('FUEL OUT', 114, 88.5, { align: 'center' });
    agreement.text('ODOMETER IN', 114, 95.5, { align: 'center' });
    agreement.text('ODOMETER OUT', 114, 102.5, { align: 'center' });
    agreement.text('MILES DRIVEN', 114, 109.5, { align: 'center' });
    agreement.text('FREE MILES', 114, 116, { align: 'center' });
    agreement.text('CHARGEABLE MILES', 114, 123.5, { align: 'center' });
    // Invoice
    agreement.text('NAME OF PAYMENT', 24, 144, { align: 'center' });
    agreement.text('COUNT', 53, 144, { align: 'center' });
    agreement.text('COST', 82, 144, { align: 'center' });

    agreement.text('DAYS', 24, 151, { align: 'center' });
    agreement.text('MONTH', 24, 158, { align: 'center' });
    agreement.text('TICKET', 24, 165, { align: 'center' });
    agreement.text('CHARGEABLE MILS', 24, 172, { align: 'center' });
    agreement.text('FULL COST', 24, 179, { align: 'center' });
    // Warning
    agreement.text('WARNING', 148, 130.5, { align: 'center' });
    // Bottom
    agreement.setFontSize(6.5);
    agreement.text('Manager', 11.5, 269, { maxWidth: 128 });

    // Field data
    agreement.setFontSize(10);
    agreement.setFontStyle('bold');
    agreement.text(getDocField('dt_end', docContent, 'date'), 148, 32);
    agreement.setFontSize(9);
    agreement.text(getDocField('rent_days', docContent), 53, 151, {
      align: 'center',
    });
    agreement.text(getDocField('rent_month', docContent), 53, 158, {
      align: 'center',
    });
    agreement.text(getDocField('rent_ticket', docContent), 53, 165, {
      align: 'center',
    });
    agreement.text(getDocField('rent_chargeable_mils', docContent), 53, 172, {
      align: 'center',
    });
    agreement.text(
      addCurrencyToCost(getDocField('rent_full_cost', docContent), currency),
      82,
      179,
      { align: 'center' }
    );

    agreement.text(
      addCurrencyToCost(getDocField('rent_days_total', docContent), currency),
      82,
      151,
      { align: 'center' }
    );
    agreement.text(
      addCurrencyToCost(getDocField('rent_month_total', docContent), currency),
      82,
      158,
      { align: 'center' }
    );
    agreement.text(
      addCurrencyToCost(getDocField('rent_ticket_cost', docContent), currency),
      82,
      165,
      { align: 'center' }
    );
    agreement.text(
      addCurrencyToCost(
        getDocField('rent_chargeable_mils_total', docContent),
        currency
      ),
      82,
      172,
      { align: 'center' }
    );

    agreement.setFontSize(9);
    agreement.text(
      `${getDocField('vin', docContent)} / ${getDocField(
        'stock_number',
        docContent
      )}`,
      135,
      46.5
    );
    agreement.text(
      `${getDocField('year', docContent)} / ${getDocField(
        'make',
        docContent
      )} / ${getDocField('model', docContent)}`,
      135,
      53.5
    );
    agreement.text(getDocField('dt_start', docContent, 'date'), 135, 68);
    agreement.text(getDocField('dt_end', docContent, 'date'), 135, 74.5);
    agreement.text(getDocField('fuel_in', docContent), 135, 81);
    agreement.text(getDocField('fuel_out', docContent), 135, 88);
    agreement.text(getDocField('vehicle_odometer_in', docContent), 135, 95.5);
    agreement.text(getDocField('vehicle_free_miles', docContent), 135, 116.5);

    agreement.setFontStyle('normal');
    try {
      // agreement.addImage(imgSign, 'PNG', 60, 270, 30, 30);
    } catch (e) {
      console.log(e);
    }
    // Page 2
    agreement.addPage();
    agreement.setFontSize(8);
    agreement.setFontStyle('bold');
    agreement.text('RENTAL AGREEMENT', 172, 11, { align: 'center' });
    agreement.setFontSize(10);
    agreement.text(company_name, 172, 15, { align: 'center' });
    agreement.setFontSize(13);
    agreement.setTextColor('Red');
    agreement.text(`N ${docContent.agreement_number}`, 172, 21, {
      align: 'center',
    });
    agreement.setTextColor(0);
    agreement.setFontSize(11);
    agreement.setFontStyle('normal');
    // agreement.text('Rental Agreement Terms and Conditions(page 2)', 105, 27, null, null, 'center');
    // agreement.text(`${settings.running_title}(page 2)`, 105, 290, {align: 'center'});
    agreement.setFontSize(7.8);

    const lines = agreement.splitTextToSize(settings.terms, 190);
    const dim = agreement.getTextDimensions('Text');
    const lineHeight = dim.h;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < lines.length; i++) {
      const lineTop = (lineHeight + 0.4) * i;
      agreement.text(lines[i], 10, 32 + lineTop);
    }
    // agreement.text(settings.terms, 10, 32, {maxWidth: 190});
    agreement.setFontSize(6.5);
    agreement.text(agreeText, 10, 282, { maxWidth: 128 });

    renderRunningTitle(agreement, settings);

    // Page 3
    if (docContent.imagesUrls.length) {
      agreement.addPage();
      agreement.setFontSize(8);
      agreement.setFontStyle('bold');
      agreement.text('RENTAL AGREEMENT', 172, 11, { align: 'center' });
      agreement.setFontSize(10);
      agreement.text(company_name, 172, 15, { align: 'center' });
      agreement.setFontSize(13);
      agreement.setTextColor('Red');
      agreement.text(`N ${docContent.agreement_number}`, 172, 21, {
        align: 'center',
      });
      agreement.setFontStyle('normal');
      renderRunningTitle(agreement, settings);

      let imageTop = 40;
      let imageLeft = 10;
      let photosOnPage = 0;
      const coundOfImages = docContent.imagesUrls.length;

      // eslint-disable-next-line no-restricted-syntax
      for (const [index, imageUrl] of docContent.imagesUrls.entries()) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const vehicleImage = await getImage(
            `${AppConfig.serverRoot}${imageUrl}`,
            500
          );
          agreement.addImage(
            vehicleImage.image,
            'JPEG',
            imageLeft,
            imageTop,
            vehicleImage.width,
            vehicleImage.height
          );
          imageTop += vehicleImage.height + 5;
          photosOnPage++;
        } catch (e) {
          console.log(e);
          const page = agreement.internal.getCurrentPageInfo().pageNumber;
          if (page > 2) {
            agreement.deletePage(page);
          }
        }

        if ((index + 1) % 3 === 0 && photosOnPage < maxPhotoOnPage) {
          imageTop = 40;
          imageLeft = 85;
        }

        if (photosOnPage === maxPhotoOnPage && index + 1 !== coundOfImages) {
          agreement.addPage();
          renderRunningTitle(agreement, settings);
          imageTop = 40;
          imageLeft = 10;
          photosOnPage = 0;
        }
      }

      renderRunningTitle(agreement, settings);
    }

    switch (agreementAction) {
      case 'open':
        window.open(agreement.output('bloburl'));
        // agreement.output('pdfjsnewwindow');
        break;
      case 'download':
        agreement.save(`${docContent.agreement_number}_RENTAL_AGREEMENT.pdf`);
        break;
      case 'print':
        agreement.autoPrint({ variant: 'non-conform' });
        agreement.output('dataurlnewwindow');
        break;
      default:
        break;
    }
  } catch (e) {
    console.log(e);
  }
}

function renderRunningTitle(agreement, settings) {
  agreement.setTextColor(0, 176, 109);
  agreement.setFontSize(10);
  agreement.setFontStyle('bold');
  agreement.text(`${settings.running_title}`, 105, 290, { align: 'center' });
  agreement.setFontStyle('normal');
}
