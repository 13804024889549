import { Button, Form, Grid, Popup, Radio } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import styles from './PopupFilter.module.scss';

function PopupFilter(props) {
  const [open, setOpen] = useState(false);
  const [filterState, setFilterState] = useState(cloneDeep(props.filterData));

  const onChangeToggleParam = ({ index, value }) => {
    const prevState = cloneDeep(filterState);
    prevState[index] = {
      ...prevState[index],
      toggle: value,
    };
    setFilterState(prevState);
  };
  const onChangeInputParam = (e, { index, valueIndex = 0 }) => {
    const prevState = cloneDeep(filterState);
    const { value } = e.target;
    const arrayValue = filterState[index].value;
    arrayValue[valueIndex] = value;

    prevState[index] = {
      ...prevState[index],
      value: arrayValue,
    };

    setFilterState(prevState);
  };

  useEffect(() => {
    setFilterState(cloneDeep(props.filterData));
  }, [props.filterData]);

  const onOpen = () => {};

  const onSubmit = () => {
    props.onFilterApply(filterState);
    setOpen(false);
  };

  const renderPopupContent = () => {
    return (
      <>
        <Grid>
          {props.filterData.map((item, index) => {
            if (Array.isArray(item.name))
              return (
                <Grid.Row key={`filterrow-${index}`} className={styles.row}>
                  <Grid.Column className={styles.twelve} width={11}>
                    <Form.Group className={styles.arrayWrapper}>
                      <Form.Input
                        className={styles.arrayInput}
                        name={String(item.name[0]).toLowerCase()}
                        placeholder={i18next.t(item.name[0])}
                        value={item.value[0]}
                        onChange={(e) =>
                          onChangeInputParam(e, { index, valueIndex: 0 })
                        }
                      />
                      <Form.Input
                        className={styles.arrayInput}
                        name={String(item.name[1]).toLowerCase()}
                        placeholder={i18next.t(item.name[1])}
                        value={item.value[1]}
                        onChange={(e) =>
                          onChangeInputParam(e, { index, valueIndex: 1 })
                        }
                      />
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Form.Field
                      control={Radio}
                      checked={filterState[index].toggle}
                      onChange={() =>
                        onChangeToggleParam({
                          index,
                          value: !filterState[index].toggle,
                        })
                      }
                      toggle
                    />
                  </Grid.Column>
                </Grid.Row>
              );
            return (
              <Grid.Row key={`filterrow-${index}`} className={styles.row}>
                <Grid.Column className={styles.twelve} width={11}>
                  <Form.Input
                    inline
                    name={String(item.name).toLowerCase()}
                    placeholder={i18next.t(item.name)}
                    value={item.value[0]}
                    onChange={(e) =>
                      onChangeInputParam(e, { index, valueIndex: 0 })
                    }
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Field
                    control={Radio}
                    checked={filterState[index].toggle}
                    onChange={() =>
                      onChangeToggleParam({
                        index,
                        value: !filterState[index].toggle,
                      })
                    }
                    toggle
                  />
                </Grid.Column>
              </Grid.Row>
            );
          })}
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.footer}>
              <Button negative onClick={() => setOpen(false)}>
                {i18next.t('Cancel')}
              </Button>
              <Button positive onClick={onSubmit}>
                {i18next.t('Ok')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  };

  return (
    <Popup
      className={styles.popup}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Button
          icon="filter"
          labelPosition="left"
          content={i18next.t('Filter')}
          primary
        />
      }
      content={renderPopupContent()}
      on="click"
    />
  );
}

PopupFilter.defaultProps = {
  onFilterApply: () => {},
};

PopupFilter.propTypes = {
  onFilterApply: PropTypes.func,
  filterData: PropTypes.array,
};

export default React.memo(PopupFilter);
