import { Button, Icon, Table } from 'semantic-ui-react';
import moment from 'moment';
import { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import i18next from 'i18next';
import styles from '../AllAgreements/AllAgreements.module.scss';
import { agreementStatues } from '../../catalogs/constants';

function tableReducer(state, action) {
  console.log(state);
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      };
    case 'LOAD_TABLE': {
      return {
        ...state,
        data: action.data,
      };
    }
    default:
      throw new Error();
  }
}

function AgreementsTable(props) {
  const [state, dispatch] = useReducer(tableReducer, {
    column: null,
    data: [],
    direction: null,
  });
  const { column, data, direction } = state;

  useEffect(() => {
    dispatch({ type: 'LOAD_TABLE', data: props.allAgreementsList });
  }, [props.allAgreementsList]);

  return (
    <Table className={styles.agreementTable} sortable celled fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            width={1}
            textAlign="center"
            sorted={column === 'number' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'number' })}
          >
            #
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'name' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'name' })}
          >
            Renter
          </Table.HeaderCell>
          <Table.HeaderCell>Vehicle</Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'dt_start' ? direction : null}
            onClick={() =>
              dispatch({ type: 'CHANGE_SORT', column: 'dt_start' })
            }
          >
            Up
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'dt_end' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dt_end' })}
          >
            To
          </Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'amount' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'amount' })}
          >
            Full cost
          </Table.HeaderCell>
          <Table.HeaderCell width={2} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((item, index) => (
          <Table.Row key={`vehicle-row-${index}`}>
            <Table.Cell width={1} textAlign="center">
              {item.agreement_number}
            </Table.Cell>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{`${item.make} ${item.model}`}</Table.Cell>
            <Table.Cell>
              {moment(item.dt_start).format('YYYY-MM-DD HH:mm') || ''}
            </Table.Cell>
            <Table.Cell>
              {moment(item.dt_end).format('YYYY-MM-DD HH:mm') || ''}
            </Table.Cell>
            <Table.Cell>{agreementStatues[item.status]}</Table.Cell>
            <Table.Cell>{item.rent_full_cost}</Table.Cell>
            <Table.Cell style={{ display: 'flex' }}>
              <Button
                style={{ width: '30px' }}
                compact
                icon
                primary
                size="small"
                onClick={() => props.onPdfOpenClick(item.id)}
              >
                <Icon name="file pdf" />
              </Button>
              {item.status < 2 && (
                <Button
                  title={i18next.t('Close agreement')}
                  style={{ width: '30px' }}
                  compact
                  icon
                  primary
                  size="small"
                  onClick={() => props.onFinishClick(item.id)}
                >
                  <Icon name="flag checkered" />
                </Button>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

AgreementsTable.propTypes = {
  allAgreementsList: PropTypes.array,
  onPdfOpenClick: PropTypes.func,
  onFinishClick: PropTypes.func,
};

export default AgreementsTable;
