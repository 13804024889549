import React, { Component } from 'react';

class Fines extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>Fines</div>;
  }
}

export default Fines;
