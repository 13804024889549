import React, { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import NewApplication from '../common/components/agreement/NewAgreement';
import MainMenu from '../common/components/MainMenu/MainMenu';
import AuthRoute from '../classes/AuthRoute';
import MainPage from '../common/components/MainPage';
import Auth from '../common/components/Auth';
import Agreement from '../common/components/Agreement';
import AllAgreements from '../common/components/AllAgreements/AllAgreements';
import Lookup from '../common/components/Lookup';
import history from '../history';

import Invoices from '../common/components/Invoices';
import Clients from '../common/components/Clients';
import Fines from '../common/components/Fines';
import Reports from '../common/components/Reports';
import Settings from '../common/components/Settings/Settings';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../i18n';
import RentPlanner from '../common/components/RentPanner';
import { Vehicles } from '../common/components/vehicles';
import SignAgreement from '../common/components/agreement/SignAgreement/SignAgreement';

try {
  require('../common/styles/styles.scss');
} catch (e) {
  console.log(e);
}

export default function MainLayout(props) {
  const isDataLoading = useSelector((state) => state.ui.isDataLoading);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <NewApplication>
      <Container>
        <Router>
          <Container>
            <ToastContainer />
            <MainMenu />
            <AuthRoute path="/" exact component={MainPage} />
            <div className="main-container">
              <div
                className={classNames('ui active centered', {
                  loader: isDataLoading,
                })}
              />
              <Route
                path="/signagreement/:uid"
                exact
                component={SignAgreement}
              />
              <Route path="/auth" exact component={Auth} />
              {isDataLoading && <div className="loader-overlay" />}
              <AuthRoute path="/agreement" exact component={Agreement} />
              <AuthRoute
                path="/allagreements"
                exact
                component={AllAgreements}
              />
              <AuthRoute path="/lookup" exact component={Lookup} />
              <AuthRoute path="/planner" exact component={RentPlanner} />
              <AuthRoute path="/vehicles" exact component={Vehicles} />
              <AuthRoute path="/invoices" exact component={Invoices} />
              <AuthRoute path="/clients" exact component={Clients} />
              <AuthRoute path="/fines" exact component={Fines} />
              <AuthRoute path="/reports" exact component={Reports} />
              <AuthRoute path="/settings" exact component={Settings} />
            </div>
          </Container>
        </Router>
        {props.children}
      </Container>
    </NewApplication>
  );
}
