import React, { Component } from 'react';
import { Header, Menu, Segment } from 'semantic-ui-react';
import i18next from 'i18next';

class Agreement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Menu className="tool-bar" stackable>
          <Menu.Item className="section-name" header>
            {i18next.t('Agreement')}
          </Menu.Item>
          <Menu.Item />
        </Menu>
        <Segment>
          <Header>Report data</Header>
        </Segment>
      </React.Fragment>
    );
  }
}

export default Agreement;
