import {
  LOAD_CLIENT,
  LOAD_CLIENT_PREV_STATE,
  LOAD_CLIENTS_LIST,
  LOAD_CLIENTS_LIST_FAIL,
} from '../actions/types';
import { Client } from '../common/models/Client';

const initState = {
  clientsList: [],
  currentClient: undefined,
  clientForm: new Client(),
};

export default function clients(state = initState, action) {
  switch (action.type) {
    case LOAD_CLIENTS_LIST: {
      return { ...state, clientsList: action.payload };
    }
    case LOAD_CLIENTS_LIST_FAIL: {
      return { ...state, clientsList: [] };
    }
    case LOAD_CLIENT:
    case LOAD_CLIENT_PREV_STATE: {
      return { ...state, clientForm: action.payload };
    }

    default:
      return state;
  }
}
