import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { store } from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import App from './App';

// Sentry.init({
//     dsn: "https://a3c3af6006184bd59120ab45ce131b40@o542035.ingest.sentry.io/5661109",
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
