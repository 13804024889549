import React from 'react';
import { Table } from 'semantic-ui-react';
import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

function RentalHistory(props) {
  const { rentalHistory } = props;
  const mapData = () => {
    //  const editExpensesData = this.props.editExpensesData;
    // this.setState({formControls: editExpensesData});
  };

  return (
    <React.Fragment>
      <Table celled selectable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>{i18next.t('Date')}</Table.HeaderCell>
            <Table.HeaderCell>{i18next.t('Vehicle')}</Table.HeaderCell>
            <Table.HeaderCell>{i18next.t('Amount')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rentalHistory.map((item, index) => (
            <Table.Row key={index}>
              <Table.Cell>{index}</Table.Cell>
              <Table.Cell>
                {moment(item.date_created).format('DD.MM.YYYY hh:mm')}
              </Table.Cell>
              <Table.Cell>{`${item.make}`}</Table.Cell>
              <Table.Cell />
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
}

RentalHistory.defaultProps = {
  rentalHistory: [],
};

RentalHistory.propTypes = {
  rentalHistory: PropTypes.array,
};

export default RentalHistory;
