import React, { useState, useEffect, Component } from 'react';
import { Image, Segment, TransitionablePortal, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import AppConfig from '../../../config';

export default class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      activeImage: '',
      activeItemIndex: -1,
      isImageVisible: false,
      gallery: [],
    };
  }

  componentDidMount() {
    const gallery = this.props.gallery ? [...this.props.gallery] : [];
    const mainImage =
      this.props.mainImage instanceof Blob
        ? URL.createObjectURL(this.props.mainImage)
        : this.props.mainImage;
    const image =
      this.props.image instanceof Blob
        ? URL.createObjectURL(this.props.image)
        : this.props.image;

    if (gallery.length && this.props.mainImage !== AppConfig.defaultClientImage)
      gallery.unshift(mainImage);

    const activeItemIndex = gallery.findIndex(
      (item) => item === this.props.image
    );
    this.setState({
      isShow: true,
      activeImage: image,
      activeItemIndex,
      gallery,
    });
  }

  componentWillUnmount() {
    this.setState({
      isShow: false,
      activeImage: '',
      activeItemIndex: -1,
      gallery: [],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isShow !== this.state.isShow && this.state.isShow)
      document.addEventListener('keydown', (event) =>
        this.onArrowTap(event, this)
      );
    if (prevState.isShow !== this.state.isShow && !this.state.isShow)
      document.removeEventListener('keydown', this.onArrowTap);
  }

  hidePreview = () => {
    this.setState({ isShow: false, activeImage: '', gallery: [] });
    this.props.onPreviewClose();
  };

  decIndex = () => {
    const activeIndex = this.state.activeItemIndex;
    if (activeIndex > 0) return activeIndex - 1;
    return activeIndex;
  };

  incIndex = () => {
    const activeIndex = this.state.activeItemIndex;

    if (activeIndex === -1) return 0;
    if (activeIndex < this.state.gallery.length - 1) return activeIndex + 1;
    return activeIndex;
  };

  onPrevImage = () => {
    const activeItemIndex = this.decIndex();
    this.setState({
      activeItemIndex,
      activeImage: this.state.gallery[activeItemIndex],
      isImageVisible: !this.state.isImageVisible,
    });
  };

  onNextImage = () => {
    const activeItemIndex = this.incIndex();
    this.setState({
      activeItemIndex,
      activeImage: this.state.gallery[activeItemIndex],
      isImageVisible: !this.state.isImageVisible,
    });
  };

  onArrowTap(event, self) {
    if (event.key === 'ArrowLeft') self.onPrevImage();
    if (event.key === 'ArrowRight') self.onNextImage();
  }

  renderSingleImage = () => {
    const { activeImage, gallery, isImageVisible } = this.state;
    const isGalleryMode = gallery.length > 0;

    return (
      <div className="ImageViewer image-viewer-wrapper">
        <div className="viewerbackground" onClick={this.hidePreview} />
        <Segment
          style={{
            left: '50%',
            position: 'absolute',
            top: '50%',
            zIndex: 1000,
            transform: 'translate(-50%, -50%)',
          }}
        >
          {isGalleryMode && (
            <Icon
              className="nav-button left-button"
              name="caret left"
              onClick={this.onPrevImage}
            />
          )}
          <Transition animation="pulse" duration={500} visible={isImageVisible}>
            <Image src={activeImage} size="big" />
          </Transition>
          {isGalleryMode && (
            <Icon
              className="nav-button right-button"
              name="caret right"
              onClick={this.onNextImage}
            />
          )}
        </Segment>
        {isGalleryMode && this.renderMultiImage()}
      </div>
    );
  };

  renderMultiImage = () => {
    const { gallery } = this.state;

    return (
      <div className="gallery-wrapper">
        {gallery.map((img, index) => (
          <Image
            key={index}
            src={img}
            onClick={() => this.setState({ activeImage: img })}
            size="tiny"
          />
        ))}
      </div>
    );
  };

  render() {
    const { isShow } = this.state;

    if (!isShow || AppConfig.defaultClientImage === this.props.image)
      return null;

    return (
      <TransitionablePortal
        className="image-viewer-container"
        transition={{ animation: 'fade', duration: 200 }}
        onClose={this.hidePreview}
        open={isShow}
      >
        {this.renderSingleImage()}
      </TransitionablePortal>
    );
  }
}

ImageViewer.defaultProps = {
  image: '',
  mainImage: '',
  gallery: [],
};

ImageViewer.propTypes = {
  image: PropTypes.string.isRequired,
  mainImage: PropTypes.string.isRequired,
  gallery: PropTypes.array,
  onPreviewClose: PropTypes.func.isRequired,
};
