import React, { Component } from 'react';
import {
  Grid,
  Table,
  Header,
  Form,
  Message,
  Menu,
  Segment,
  Button,
  Icon,
} from 'semantic-ui-react';
import ReactApexChart from 'react-apexcharts';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import i18next from 'i18next';
import moment from 'moment';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  getRentOccupation,
  getToadyReservation,
} from '../../services/quicklookup';

const Options = [
  { key: 'Agreement', value: 'Agreement', text: i18next.t('Agreement #') },
  {
    key: 'Reservation',
    value: 'Reservation',
    text: i18next.t('Reservation #'),
  },
  { key: 'Customer', value: 'Customer', text: i18next.t('Customer name') },
  { key: 'License', value: 'License', text: i18next.t('License Plate #') },
];

class Lookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          stacked: true,
          stackType: '100%',
          animations: {
            enabled: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ['#00ab62', '#E91E63'],
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [
            i18next.t('Compact'),
            i18next.t('Convertible'),
            i18next.t('Economy'),
            i18next.t('Fullsize'),
            i18next.t('Midsize'),
            i18next.t('Pickup'),
            i18next.t('SUV'),
          ],
        },
        tooltip: {
          y: {
            formatter(val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
      series: [
        {
          name: 'Available',
          data: [],
        },
        {
          name: 'On rent',
          data: [],
        },
      ],
      rentOccupationData: [],
      AvailableVehicles: {
        Compact: 0,
        Convertible: 0,
        Economy: 0,
        Fullsize: 0,
        Midsize: 0,
        Pickup: 0,
        SUV: 0,
      },
      RentedVehicles: {
        Compact: 0,
        Convertible: 0,
        Economy: 0,
        Fullsize: 0,
        Midsize: 0,
        Pickup: 0,
        SUV: 0,
      },
      todayReservation: [],
    };
  }

  componentDidMount() {
    getRentOccupation({
      QueryDateTime: moment(new Date(), 'YYYY-MM-DD HH:mm'),
    }).then((result) => {
      this.setState(
        { rentOccupationData: result.data.data },
        this.prepareOccupationData
      );
    });
    this.loadTodayReservation();
  }

  loadTodayReservation = () => {
    getToadyReservation().then((results) => {
      this.setState({ todayReservation: results.data.data });
    });
  };

  prepareOccupationData = () => {
    const { rentOccupationData } = this.state;
  };

  render() {
    return (
      <React.Fragment>
        <Menu className="tool-bar" stackable>
          <Menu.Item className="section-name" header>
            {i18next.t('Quick Lookup')}
          </Menu.Item>
          <Menu.Item />
        </Menu>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Segment>
                <div>
                  <Message
                    attached
                    icon="inbox"
                    size="small"
                    header={i18next.t('Reservation/Agreement search')}
                  />
                  <Form className="attached fluid segment">
                    <Form.Group widths="equal">
                      <Form.Select
                        fluid
                        placeholder={i18next.t('Select option')}
                        options={Options}
                      />
                      <Form.Input
                        fluid
                        placeholder={i18next.t('Enter text')}
                        type="text"
                      />
                    </Form.Group>

                    <Button
                      icon="search"
                      labelPosition="left"
                      content="Search"
                      primary
                    />
                  </Form>
                  <br />
                </div>

                <div>
                  <Message
                    attached
                    icon="car"
                    size="small"
                    header={i18next.t('Vehicle search')}
                  />
                  <Form className="attached fluid segment">
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        label={i18next.t('License Plate #')}
                        placeholder={i18next.t('Enter license Plate #')}
                        type="text"
                      />
                      <Form.Select
                        fluid
                        label={i18next.t('Vehicle class')}
                        options={Options}
                        placeholder={i18next.t('Select vehicle class')}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <DatesRangeInput
                        value=""
                        onChange={console.log}
                        animation=""
                        clearable
                        clearIcon={<Icon name="remove" color="red" />}
                        autoComplete="off"
                        closable
                        hideMobileKeyboard
                        popupPosition="bottom right"
                        placeholder={i18next.t('Select date from to')}
                        label={i18next.t('Date From - To')}
                      />
                    </Form.Group>

                    <Button
                      icon="search"
                      labelPosition="left"
                      content="Search"
                      primary
                    />
                  </Form>
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Header>{i18next.t('Now rent occupation')}</Header>
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="bar"
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment vertical>
          <Tabs>
            <TabList>
              <Tab> {i18next.t("Today's reservations")}</Tab>
              <Tab> {i18next.t('Quick lookup')}</Tab>
            </TabList>
            <TabPanel>{this._renderTodayReservation()}</TabPanel>
            <TabPanel>
              <Segment />
            </TabPanel>
          </Tabs>
        </Segment>
      </React.Fragment>
    );
  }

  _renderTodayReservation() {
    const { todayReservation } = this.state;
    return (
      <Segment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Renter</Table.HeaderCell>
              <Table.HeaderCell>Vehicle</Table.HeaderCell>
              <Table.HeaderCell>Up</Table.HeaderCell>
              <Table.HeaderCell>To</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {todayReservation.map((item, index) => (
              <Table.Row key={index}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{item.client}</Table.Cell>
                <Table.Cell>{item.vehicle}</Table.Cell>
                <Table.Cell>
                  {moment(item.dt_start).format('YYYY-MM-DD hh:mm a') || ''}
                </Table.Cell>
                <Table.Cell>
                  {moment(item.dt_end).format('YYYY-MM-DD hh:mm a') || ''}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default Lookup;
