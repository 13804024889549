import axios from 'axios';
import i18next from 'i18next';
import {
  ALL_AGREEMENTS_SET_TAB_INDEX,
  LOAD_ALL_AGREEMENTS_LIST,
  SHOW_STATUS_MESSAGE,
} from './types';
import AppConfig from '../config';
import { tokenConfig } from './user/auth';
import { localDataLoader, setDataLoading } from './UiActions';
import {
  agreementSorting,
  errorMessageStatus,
  statusMessages,
} from '../common/catalogs/constants';

export const loadAllAgreementsList =
  (agreementOrder = 0) =>
  async (dispatch, getState) => {
    try {
      dispatch(localDataLoader());
      const res = await axios.get(
        `${AppConfig.apiUrl}/agreements/getagreementslist?status=${agreementSorting[agreementOrder]}`,
        tokenConfig(getState)
      );

      if (res.data) {
        dispatch({
          type: LOAD_ALL_AGREEMENTS_LIST,
          payload: res.data,
        });
      }
      dispatch(localDataLoader(false));
    } catch (err) {
      dispatch({
        type: SHOW_STATUS_MESSAGE,
        payload: {
          messageType: errorMessageStatus,
          statusMessage: i18next.t(statusMessages.allAgreementsListLoadError),
        },
      });
      dispatch({
        type: LOAD_ALL_AGREEMENTS_LIST,
        payload: [],
      });
      dispatch(localDataLoader(false));
      console.log(err);
    }
  };

export const setActiveTabIndex = (tabIndex) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_AGREEMENTS_SET_TAB_INDEX,
      payload: tabIndex,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setAgreementStatusAsFinish =
  (agreementIndex) => async (dispatch, getState) => {
    try {
      const tabIndex = getState().allAgreements.activeTabIndex;
      dispatch(setDataLoading(true));
      const res = await axios.get(
        `${AppConfig.apiUrl}/agreements/setagreementstatusfinish?uid=${agreementIndex}`,
        tokenConfig(getState)
      );

      if (res.data) dispatch(loadAllAgreementsList());
    } catch (err) {
      dispatch({
        type: SHOW_STATUS_MESSAGE,
        payload: {
          messageType: errorMessageStatus,
          statusMessage: i18next.t(statusMessages.allAgreementsListLoadError),
        },
      });
      console.log(err);
    } finally {
      dispatch(setDataLoading(false));
    }
  };

export const getAgreementByNumber =
  (agreementNumber) => async (dispatch, getState) => {
    const config = tokenConfig(getState);
    try {
      const { data: agreement } = await axios.get(
        `${AppConfig.apiUrl}/agreements/searchAgreementByNumber`,
        {
          ...config,
          params: {
            agreementNumber,
          },
        }
      );
      return agreement;
    } catch (err) {
      console.log(err);
    }
  };
