import React, { Component } from 'react';
import './App.scss';
import 'semantic-ui-css/semantic.css';
import 'react-tabs/style/react-tabs.css';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import * as queryString from 'querystring';
import listenToDispatch from './listenToDispatch';
import history from './history';
import { loadUser } from './actions/user/auth';
import { setDataLoading } from './actions/UiActions';
import { getSettingsData } from './actions/settings';
import MainLayout from './layout/MainLayout';

history.listen((location) => listenToDispatch(location.pathname, 'history'));

class App extends Component {
  constructor(props) {
    super(props);
    this.init();
    i18next.changeLanguage('en', () => {});
  }

  getRedirectUrl = () => {
    const parsed = queryString.parse(this.props.location.search);
  };

  init() {
    if (this.props.token && this.props.token !== undefined) {
      this.props.setDataLoading(true);
      this.props.loadUser();
      this.props.getSettingsData();
      this.getRedirectUrl();
    }
  }

  render() {
    return (
      <>
        <MainLayout />;
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { token } = state.auth;
  return {
    token,
  };
};

const mapDispatchToProps = {
  loadUser,
  setDataLoading,
  getSettingsData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
