import React, { Component } from 'react';
import {
  Grid,
  Menu,
  Button,
  Icon,
  Segment,
  Form,
  Message,
  Confirm
} from 'semantic-ui-react';
import i18next from 'i18next';
import { connect } from 'react-redux/es/alternate-renderers';
import classNames from 'classnames';
import { Tab, TabList, Tabs } from 'react-tabs';
import { colors } from '../../catalogs/constants';
import {
  loadAgreementInfo,
  showNewAgreementForm,
} from '../../../actions/agreement';
import {
  getAgreementByNumber,
  loadAllAgreementsList,
  setActiveTabIndex,
  setAgreementStatusAsFinish,
} from '../../../actions/allAgreements';
import AgreementsTable from '../shared/AgreementsTable';
import { generateAgreement } from '../../../services/generatePDF';
import { setDataLoading } from '../../../actions/UiActions';

import SearchField from '../shared/SearchField/SearchField';

import styles from './AllAgreements.module.scss';


class AllAgreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
      completeId: -1,
      searchResults: [],
      activeItemIndex: 0,
      messageType: 'error',
      statusMessage: '',
      editMode: false,
      clientsList: [],
    };
  }

  componentDidMount() {
    this.props.loadAllAgreementsList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isNeedUpdate !== this.props.isNeedUpdate &&
      this.props.isNeedUpdate
    )
      this.props.loadAllAgreementsList();
  }

  clearSearchResults = () => {
    this.setState({ searchResults: [] });
  };

  onSearch = async (searchValue) => {
    const searchResults = await this.props.getAgreementByNumber(searchValue);
    console.log(searchResults);
    this.setState({ searchResults });
  };

  onPdfOpenClick = (id) => {
    this.props.setDataLoading(true);
    this.props.loadAgreementInfo(id).then((data) => {
      generateAgreement({ data, settings: this.props.settings }).then(() => {
        this.props.setDataLoading(false);
      });
    });
  };

  confirmAction = (isConfirm = false) => {
    if (isConfirm) this.props.setAgreementStatusAsFinish(this.state.completeId);
    this.setState({ confirmOpen: false });
  };

  onFinishClick = (completeId) => {
    this.setState({ confirmOpen: true, completeId });
  };

  setActiveTab = (index) => {
    this.props.setActiveTabIndex(index);
    this.props.loadAllAgreementsList(index);
  };

  _renderActionPanel() {
    const {
      activeItemIndex,
      showStatusMessage,
      statusMessage,
      messageType,
      editMode,
    } = this.state;
    return (
      <Menu className="tool-bar" stackable>
        <Menu.Item className="section-name" header>
          {i18next.t('Agreements')}
        </Menu.Item>
        <Menu.Item className={classNames({ 'form-group-disable': editMode })}>
          <Button
            icon
            primary
            labelPosition="left"
            onClick={() => this.props.showNewAgreementForm(true)}
          >
            <Icon name="plus" />
            {i18next.t('New Agreement')}
          </Button>
        </Menu.Item>
        <Menu.Item className={classNames({ 'form-group-disable': editMode })}>
          <SearchField
            searchResults={this.state.searchResults}
            onClearSearch={this.clearSearchResults}
            handleOnSearch={this.onSearch}
            placeholder="Search agreement"
          />
        </Menu.Item>

        {this.state.clientsList[activeItemIndex] !== undefined && (
          <Menu.Item className="section-message" header>
            {showStatusMessage && (
              <Message color={colors[messageType]}>{statusMessage}</Message>
            )}
          </Menu.Item>
        )}
      </Menu>
    );
  }

  _renderAgreementTable() {
    const { allAgreementsList } = this.props;
    const { searchResults } = this.state;
    const agreementsList =
      searchResults.length > 0 ? searchResults : allAgreementsList;

    return (
      <AgreementsTable
        allAgreementsList={agreementsList}
        onPdfOpenClick={this.onPdfOpenClick}
        onFinishClick={this.onFinishClick}
      />
    );
  }

  _renderForm() {
    return (
      <React.Fragment>
        <Segment>
          <Form>
            <Tabs
              selectedIndex={this.props.activeTabIndex}
              onSelect={this.setActiveTab}
            >
              <TabList>
                <Tab>{i18next.t('To pay')}</Tab>
                <Tab>{i18next.t('Active')}</Tab>
                <Tab>{i18next.t('Archived')}</Tab>
              </TabList>
              <h3>{i18next.t('')}</h3>
              {this._renderAgreementTable()}
            </Tabs>
          </Form>
        </Segment>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className={styles.AllAgreements}>
        <Confirm
          content={i18next.t('Do you want complete this agreement?')}
          open={this.state.confirmOpen}
          onCancel={() => this.confirmAction()}
          onConfirm={() => this.confirmAction(true)}
        />
        {this._renderActionPanel()}
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>{this._renderForm()}</Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = {
  showNewAgreementForm,
  loadAllAgreementsList,
  loadAgreementInfo,
  setDataLoading,
  setActiveTabIndex,
  setAgreementStatusAsFinish,
  getAgreementByNumber,
};
const mapStateToProps = (state) => {
  const {
    ui: { isDataLoading },
    allAgreements: { allAgreementsList, isNeedUpdate, activeTabIndex },
    settings,
  } = state;

  return {
    isDataLoading,
    allAgreementsList,
    isNeedUpdate,
    settings,
    activeTabIndex,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllAgreements);
